import {Roles} from './Roles';
import {
  FaAppleAlt,
  FaBuilding,
  FaCalendarCheck,
  FaFile, FaHome,
  FaImages, FaPoll,
  FaQrcode,
  FaUser,
  FaUsers,
  FaUserTie
} from 'react-icons/fa/index';
import Home from '../routes/Home';
import FoodPlanner from '../routes/FoodPlanner';
import QrManager from '../routes/QrManager';
import ReservationManager from '../routes/ReservationManager';
import FileManager from '../routes/FileManager';
import WorkspaceManager from '../routes/WorkspaceManager';
import OrganisationManager from '../routes/OrganisationManager';
import Profile from '../routes/Profile';
import UserManager from '../routes/UserManager';
import WorkspaceReserve from '../routes/WorkspaceReserve';
import OrganisationReserve from '../routes/OrganisationReserve';
import FeedbackManager from '../routes/FeedbackManager';

export const modules: Module[] = [
  // {
  //   name: 'contact-manager',
  //   title: 'Contact beheer',
  //   route: '/contacts',
  //   permissions: [Roles.Moderator]
  // },
  {
    name: 'home',
    title: 'Klantenomgeving',
    route: '/',
    icon: FaHome,
    component: Home,
    exact: true,
    permissions: [Roles.Moderator, Roles.User]
  },
  {
    name: 'users',
    title: 'Gebruikers',
    route: '/admin/users',
    icon: FaUsers,
    component: UserManager,
    permissions: [Roles.Moderator]
  }, {
    name: 'profile',
    title: 'Profiel',
    route: '/admin/profile',
    permissions: [Roles.Moderator, Roles.User],
    icon: FaUser,
    component: Profile,
    navbar: false,
    enabled: false
  }, {
    name: 'organisations',
    title: 'Organisaties',
    route: '/admin/organisations',
    icon: FaUserTie,
    component: OrganisationManager,
    permissions: [Roles.Admin]
  }, {
    name: 'workspaces',
    title: 'Werkruimtes',
    route: '/admin/workspaces',
    icon: FaBuilding,
    component: WorkspaceManager,
    permissions: [Roles.Moderator]
  }, {
    name: 'files',
    title: 'Bestanden',
    route: '/admin/files',
    icon: FaImages,
    component: FileManager,
    permissions: [Roles.Moderator]
  }, {
    name: 'reservations',
    title: 'Reserveringen',
    route: '/admin/reservations',
    icon: FaCalendarCheck,
    component: ReservationManager,
    permissions: [Roles.Moderator]
  }, {
    name: 'feedback',
    title: 'Feedback',
    route: '/admin/feedback',
    icon: FaPoll,
    component: FeedbackManager,
    permissions: [Roles.Moderator]
  }, {
    name: 'qr',
    title: 'QR',
    route: '/admin/qr',
    icon: FaQrcode,
    component: QrManager,
    permissions: [Roles.Moderator]
  }, {
    name: 'food-planner',
    title: 'Food planner',
    route: '/admin/food-planner',
    navbar: false,
    icon: FaAppleAlt,
    component: FoodPlanner,
    permissions: [Roles.Admin]
  },
  // {
  //   name: 'accept-invitation',
  //   title: 'Invitation',
  //   route: '/accept-invitation',
  //   permissions: [Roles.Moderator, Roles.User, Roles.Guest]
  // }
  {
    name: 'organisation-reserve',
    title: 'Reserveren',
    route: '/reserveren/:organisation',
    component: OrganisationReserve,
    permissions: [Roles.Moderator, Roles.User],
    navbar: false
  }, {
    name: 'organisation-reserve-workspace',
    title: 'Reserveren',
    route: '/reserveren/:organisation/:workspace',
    permissions: [Roles.Moderator, Roles.User],
    component: WorkspaceReserve,
    navbar: false
  }
];

export const getModuleByName = (name: string) => {
  return modules.find(m => m.name == name);
}

export interface Module {
  enabled?: boolean;
  icon?: any;
  name: string;
  navbar?: boolean;
  permissions: Roles[];
  component: any;
  route: string;
  title: string;
  exact?: boolean;
}

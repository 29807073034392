import React, {useEffect, useRef, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import FormInput from '../forms/FormInput';
import {InputOption} from '../forms/FormInputSelect';
import {User} from '../../models/User';
import {Workspace, WorkspaceCollection} from '../../models/Workspace';

const useStyles = makeStyles(theme => ({
  root: {}
}));

export const WorkspaceSelector = (props: WorkspaceSelectorProps) => {
  const classes = useStyles(props);

  const me = useRef({
    workspaces: [],
    workspacesById: {}
  })
  const getMe = () => me.current;

  const [workspace, setWorkspace] = useState<Workspace>()
  const [workspaces, setWorkspaces] = useState<InputOption[]>([]);

  const handleChange = (value: any) => {
    const me = getMe();
    setWorkspace(value);

    if (props.onChange) {
      props.onChange(me.workspacesById[value]);
    }
  }

  const handleLoad = () => {
    if (props.onLoad) {
      props.onLoad();
    }
  }

  useEffect(() => {
    const me = getMe();
    WorkspaceCollection.findAllWithCount({
      organisationId: props.organisationId
    }).then(res => {
      const workspaces = res.rows;

      me.workspaces = workspaces;
      me.workspacesById = res.rowsById;

      let workspace;

      if (props.value) {
        workspace = me.workspacesById[props.value] || workspaces[0];
      } else {
        workspace = workspaces[0];
      }

      setWorkspace(workspace);
      setWorkspaces(workspaces.map(org => ({
        value: org.id,
        label: props.showWorkplaceCount ? org.nameWithWorkplaceCount : org.name
      })));

      handleLoad();

      if (workspaces.length > 0) {
        handleChange(workspace.id);
      }
    });
  }, [props.user, props.organisationId]);

  return (
    <FormInput
      id={'workspace'}
      type={'select'}
      label={'Werkruimte'}
      defaultValue={workspace?.id}
      onChange={handleChange}
      options={workspaces}
    />
  );
}

export default WorkspaceSelector;

interface WorkspaceSelectorProps {
  user: User;
  organisationId: string;
  showWorkplaceCount?: boolean;
  value?: string;
  onChange?: (workspace: Workspace) => void;
  onLoad?: () => void;
}

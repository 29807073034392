import Model from './Model';
import {getApiUrl} from './ApiUrl';
import {
  apiDelete,
  apiDeleteMany,
  apiGet,
  apiGetAllWithCount,
  apiGetMany,
  apiPost,
  apiPostMany,
  apiPut,
  apiPutMany,
  cachedApiAction, FindWithCountResult
} from './ApiCalls';


abstract class Collection<D, M extends Model<D, Collection<D, M>>> implements CollectionModelCalls<D, M>{

  /**
   * This should be set overridden by children.
   * @type {string}
   * @example '/products'
   */
  public abstract route: string;

  /**
   * This could be overridden by children to make the find*() methods use authentication.
   * @type {boolean}
   */
  public privateFinds = false;

  /**
   * This could be overridden by children to make the find*() methods use caching.
   * @type {boolean}
   */
  public cachedFinds = false;

  protected model: new () => M;
  protected _url: string;

  constructor(documentModelClass: new () => M, url?: string) {
    this.model = documentModelClass;
    this._url = url;
    this.createModel = this.createModel.bind(this); // otherwise all functions in ApiCalls.ts will overwrite this.
  }

  /** Modifier */
  set url(apiUrl: string) {
    this._url = apiUrl;
  }

  get url() {
    if (!this._url) {
      this._url = getApiUrl();
    }
    return this._url;
  }

  /** Custom constructor */
  createModel(data: D): M {
    const constructor = this.model.prototype.constructor;
    return new constructor(data, this.url);
  }

  /** Default implementations */


  find(id: string | number, refreshCache = false, useCache?: boolean): Promise<M> {
    const apiAction = () => apiGet(`${this.url + this.route}/${id}`, this.createModel, this.privateFinds);

    return useCache || this.cachedFinds ? cachedApiAction(`find${this.model.name}-${id}`, apiAction, refreshCache) : apiAction();
  }

  findAll(params: any = {}, refreshCache = false, useCache?: boolean): Promise<M[]> {
    const apiAction = () => apiGetMany(this.url + this.route, this.createModel, this.privateFinds, params);

    return useCache || this.cachedFinds ? cachedApiAction(`findAll${this.model.name}${JSON.stringify(params)}`, apiAction, refreshCache) : apiAction();
  }

  findAllWithCount(params: any = {}, refreshCache = false, useCache?: boolean): Promise<FindWithCountResult<M>> {
    if (params.withCount) delete params.withCount;

    const apiAction = () => {
      params.withCount = true;
      return apiGetAllWithCount(`${this.url + this.route}`, this.createModel, this.privateFinds, params);
    }

    return useCache || this.cachedFinds ? cachedApiAction(`findAllWithCount${this.model.name}${JSON.stringify(params)}`, apiAction, refreshCache) : apiAction();
  }

  findChildrenByIds(ids: string[]): Promise<any> {


    return Promise.resolve({})
  }

  insert(data: D): Promise<string> {
    return apiPost(this.url + this.route, data, true);
  }

  insertMany(data: D[]): Promise<string[]> {
    return apiPostMany(this.url + this.route, data, true);
  }

  put(input: D | M): Promise<M> {
    const data = (input instanceof Model) ? input.data : input;
    return apiPut(this.url + this.route, data, this.createModel, true);
  }

  updateMany(data: D[]): Promise<M[]> {
    return apiPutMany(this.url + this.route, data, this.createModel, true)
  }

  deleteMany(ids: string[]) {
    return apiDeleteMany(this.url + this.route, ids, true);
  }

  delete(id: string) {
    return apiDelete(this.url + this.route + '/' + id, true);
  }
}

export default Collection;

export interface CollectionModelCalls<D, M> {
  find: (id: string | number, refreshCache: boolean) => Promise<M>;
  findAll: (params: any, refreshCache: boolean) => Promise<M[]>;
  findAllWithCount: (params: any, refreshCache: boolean) => Promise<FindWithCountResult<M>>;
  insert: (data: D) => Promise<string>;
  insertMany: (data: D[]) => Promise<string[]>;
  put: (input: D | M) => Promise<M>;
  updateMany: (data: D[]) => Promise<M[]>;
  deleteMany: (ids: string[]) => Promise<boolean>;
}

import Collection from './Collection';
import {apiDelete, apiPost, apiPostAny, apiPut} from './ApiCalls';

abstract class Model<D, C extends Collection<D, Model<D, C>>> {

  public abstract collection: C;

  constructor(data: D = null, url?: string) {
    this._data = data;
    this._url = url;
    this._toModel = this._toModel.bind(this); // otherwise all functions in ApiCalls.ts will overwrite this.
  }

  protected _data: D;

  get data(): D {
    return this._data;
  }

  protected _url: string;

  get url() {
    if (!this._url) {
      this._url = this.collection.url;
    }
    return this._url;
  }

  /** modifier */
  set url(apiUrl: string) {
    this._url = apiUrl;
  }

  get route() {
    return this.collection.route;
  }

  get id(): string {
    return this.data['id'] || this.data['_id'];
  }

  set id(value: string) {
    this.data['id'] = value;
  }

  get createdAt(): Date {
    return new Date(this.data['createdAt']);
  }

  get updatedAt(): Date {
    return new Date(this.data['updatedAt']);
  }

  get createdBy(): string {
    return this.data['createdBy'];
  }

  /** Default implementations */

  delete(): Promise<boolean> {
    return apiDelete(`${this.url + this.route}/${this.id}`, true);
  }

  update(): Promise<this> {
    return apiPut<this>(`${this.url + this.route}`, this.data, this._toModel, true);
  }

  create(): Promise<this> {
    return apiPostAny(`${this.url + this.route}`, this.data, true).then(res => {
      return this._toModel(res);
    });
  }

  save(): Promise<this> {
    return this.id ? this.update() : this.create();
  }

  /** custom constructor */
  protected _toModel(data: D): this {
    const constructor: any = this.constructor;
    return new constructor(data, this.url);
  }
}

export default Model

import React, {useContext, useEffect, useRef, useState} from 'react';
import {Button, Link, makeStyles, Typography, withStyles} from '@material-ui/core';
import {AppContext} from '../contexts/AppContext';
import {useHistory} from 'react-router-dom';
import DubwebCard from '../components/panels/DubwebCard';
import FormPanel from '../components/forms/FormPanel';
import {FormInputProps} from '../components/forms/FormInput';
import {theme} from '../assets/themes/main-theme';
import Logo from '../components/snippets/Logo';
import {UserCollection} from '../models/User';
import {useQuery} from '../utils';
import LoaderButton from '../components/snippets/LoaderButton';

const LoginButton = withStyles({
  disabled: {
    color: 'rgba(0,0,0,0.5) !important',
    backgroundColor: '#efbe2c30 !important',
  }
})(LoaderButton);

const useStyles = makeStyles(theme => ({
  root: {
    position: 'fixed',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.common.black,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around'
  },
  card: {
    position: 'absolute',
    display: 'block',
    minWidth: 300,
    maxWidth: 500,
    width: 'auto',
    color: theme.palette.common.white,
    margin: theme.spacing(2),
    zIndex: 1000
  },
  actions: {
    marginTop: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
  },
  input: {
    color: theme.palette.common.white
  },
  errorMessage: {
    color: theme.palette.error.main,
    maxWidth: 400
  },
  logoBottomCenter: {
    bottom: 20
  },
  logoBottomLeft: {
    bottom: 20,
    left: 20
  },
  logoTopCenter: {
    top: 20,
    cursor: 'pointer',
  },
  button: {},
  link: {
    opacity: 0.7,
    cursor: 'pointer',
  }
}));

export const ResetPassword = (props: ResetPasswordProps) => {
  const classes = useStyles(props);

  const [error, setError] = useState(null);
  const [valid, setValid] = useState(false);
  const [loading, setLoading] = useState(false);

  const history = useHistory();
  const query = useQuery();
  const token = query.get('token');
  const email = query.get('email') || '';

  const me = useRef({
    newPassword: '',
    newPasswordCopy: ''
  });

  const getMe = () => {
    return me.current;
  };

  const handleSave = async () => {
    const me = getMe();

    if (!valid) {
      return;
    }

    setError(null);
    setLoading(true);

    UserCollection.resetPassword(email, me.newPassword, token).then(res => {
      history.push('/login')
    }).catch(error => {
      handleError(error?.response?.status);
    }).finally(() => {
      setLoading(false);
    })

  }

  const handleError = (statusCode) => {
    switch (statusCode) {
      case 406:
        setError('Velden zijn incorrect.')
        break;
      case 404:
        setError('Gebruiker niet gevonden.')
        break;
      case 401:
        setError('Token is verlopen.');
        break;
      default:
        setError('Foutieve gegevens.');
    }
  }

  const passwordValidator = (value) => {
    return value.length >= 8;
  }

  const inputs: FormInputProps[] = [
    {
      id: 'newPassword',
      label: 'Nieuw wachtwoord',
      required: true,
      type: 'password',
      validator: passwordValidator
    }, {
      id: 'newPasswordCopy',
      label: 'Nieuw wachtwoord check',
      required: true,
      type: 'password',
      validator: passwordValidator
    }
  ]

  const handleEnter = () => {
    if (valid) {
      handleSave();
    }
  }

  const handleValidated = (values) => {
    const me = getMe();

    me.newPassword = values.newPassword;
    me.newPasswordCopy = values.newPasswordCopy;

    if (me.newPassword !== me.newPasswordCopy) {
      setError('Nieuwe wachtwoorden zijn niet gelijk.');
      return;
    } else {
      setError('')
    }

    setValid(true);
  }

  const handleInvalidated = () => {
    setValid(false);
  }

  const handleLogoClick = () => {
    history.push('/')
  }

  useEffect(() => {
    if (!token || !email) {
      setError('')
    }
  }, [token, email]);

  return (
    <div className={classes.root}>
      <Logo src={'/images/servicepunt.png'} className={classes.logoTopCenter} size={'5rem'} onClick={handleLogoClick}/>

      <DubwebCard
        className={classes.card}
        title={'Wachtwoord resetten'}
        elevation={5}
        backgroundColor={'transparent'}
        topSpacing={3}

      >
        {error && <Typography className={classes.errorMessage} gutterBottom>{error}</Typography>}

        <FormPanel
          active={true}
          inputs={inputs}
          textColor={theme.palette.common.white}
          onFormValidated={handleValidated}
          onFormInvalidated={handleInvalidated}
          onEnter={handleEnter}
          timeTilValidate={50}
        />

        <div className={classes.actions}>
          <LoginButton
            className={classes.button}
            type={'submit'}
            variant={'contained'}
            color={'primary'}
            onClick={handleSave}
            onSubmit={handleSave}
            disabled={!valid}
            loading={loading}
          >Opslaan</LoginButton>
        </div>

      </DubwebCard>

      <Logo src={'/images/inctrl_white.png'} className={classes.logoBottomCenter} size={'3rem'}/>
      <Logo src={'/images/rand_logo.png'} className={classes.logoBottomLeft} size={'2.7rem'}/>
    </div>
  );
};

export default ResetPassword;

interface ResetPasswordProps {

}

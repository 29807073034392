import React, {useContext} from 'react';
import './app.css';
import moment from 'moment';
import 'moment/locale/nl';
import RouterPanel from './components/core/RouterPanel';
import ConfirmationModal from './components/modals/ConfirmationModal';
import {AppContext} from './contexts/AppContext';
import PreviewBackdrop from './components/modals/PreviewBackdrop';

moment.locale('nl')


function App() {

  const {confirmationModalOpen, confirmationModalProps, hideConfirmationModal} = useContext(AppContext);
  const {previewBackdropOpen, previewBackdropProps, hidePreviewBackdrop} = useContext(AppContext);

  return (
    <div className={'root'}>
      <RouterPanel />

      <ConfirmationModal
        {...confirmationModalProps}
        onClose={hideConfirmationModal}
        open={confirmationModalOpen}
      />

      <PreviewBackdrop
        {...previewBackdropProps}
        onClose={hidePreviewBackdrop}
        open={previewBackdropOpen}
      />

    </div>
  );
}

export default App;

import React, {useContext, useRef, useState} from 'react';
import {makeStyles, Typography} from '@material-ui/core';
import OrganisationTable from '../components/tables/OrganisationTable';
import AddOrganisationModal from '../components/modals/AddOrganisationModal';
import {RowAction} from '../components/table/BaseTable';
import {Organisation} from '../models/Organisation';
import {FaCheckSquare, FaPen, FaRegSquare} from 'react-icons/all';
import {AppContext} from '../contexts/AppContext';

const useStyles = makeStyles(theme => ({
  root: {}
}));

export const OrganisationManager = (props: OrganisationManagerProps) => {
  const classes = useStyles(props);

  const [selectedOrganisation, setSelectedOrganisation] = useState(null);
  const [addOrgOpen, setAddOrgOpen] = useState(false);

  const {showConfirmationModal} = useContext(AppContext);

  const handleAddButtonClick = () => {
    setAddOrgOpen(true);
  }

  const handleAddButtonClose = () => {
    setAddOrgOpen(false);
    setSelectedOrganisation(null);
  }

  const handleRefresh = () => {
    getTable().refresh();
  }

  const table = useRef(null);
  const getTable = function () {
    return table.current;
  };

  const handleLoad = () => {
  }

  const handleEditClick = (org: Organisation) => {
    setSelectedOrganisation(org);
    setAddOrgOpen(true);
  }

  const handleActivateSwitchClick = (org: Organisation) => {
    const switchActivation = async () => {
      org.data.active = !org.data.active;
      return org.save();
    }
    showConfirmationModal({
      title: org.data.active ? 'Organisatie deactiveren' : 'Organisatie activeren',
      callback: switchActivation,
      content: (<Typography>{org.name} en alle bijhorende werknemers {org.data.active ? 'deactiveren' : 'activeren'}?</Typography>),
      confirmButtonLabel: 'Bevestigen',
      maxWidth: 'sm'
    })
  }

  const rowActions: RowAction<Organisation>[] = [
    {
      label: 'Aanpassen',
      icon: <FaPen/>,
      onClick: (row) => handleEditClick(row),
      // disabled: (row) => row.canceled,
    }, {
      label: (row) => row.data.active ? 'Deactiveren' : 'Activeren',
      icon: (row) => !row.data.active ? <FaRegSquare/> : <FaCheckSquare/>,
      // disabled: (row) => row.canceled,
      onClick: (row) => handleActivateSwitchClick(row),
    }
  ];


  return (
    <div className={classes.root}>
      <OrganisationTable
        onAddBtnClick={handleAddButtonClick}
        tableRef={table}
        onRefresh={handleRefresh}
        onLoad={handleLoad}
        rowActions={rowActions}
      />

      <AddOrganisationModal
        editingOrganisation={selectedOrganisation}
        open={addOrgOpen}
        onClose={handleAddButtonClose}
        onAdded={handleRefresh}
        title={selectedOrganisation ? 'Organisatie aanpassen' : 'Organisatie toevoegen'}
      />

    </div>
  );
};

export default OrganisationManager;

interface OrganisationManagerProps {

}

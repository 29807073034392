import React from 'react';
import {Button, Color, Dialog, IconButton, makeStyles, Typography} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Loader from '../panels/Loader';
import {FaTimes} from 'react-icons/fa';
import LoaderButton from '../snippets/LoaderButton';

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: 250,
    minWidth: 300,
    borderBottom: `4px solid ${theme.palette.primary.main}`,
    overflow: 'hidden'
  },
  header: {
    height: 60,
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    padding: theme.spacing(4),
    borderBottom: `3px solid ${theme.palette.primary.main}`,
  },
  container: {

  },
  content: (props: ModalProps) => ({
    padding: props.noContentPadding ? 0 : theme.spacing(4, 4, props.buttons ? 12.5 : 1, 4),
    maxWidth: '100%',
    overflow: 'hidden'
  }),
  footer: {
    position: 'absolute',
    padding: theme.spacing(0, 4),
    width: '100%',
    bottom: 0,
    height: 50
  },
  actions: {
    float: 'right',
    '& > *': {
      marginLeft: theme.spacing(2)
    }
  },
  info: {
    float: 'left',
    '& > *': {
      lineHeight: '50px',
      marginRight: theme.spacing(2)
    }
  },
  closeButton: {
    color: '#fff',
    position: 'absolute',
    right: 8,
    top: 8,
  },
}));

export const Modal = (props: ModalProps) => {
  const classes = useStyles(props);

  const buttonsLoading = props.buttons?.map(btn => btn.loading).reduce((prev, curr) => curr, false);

  const handleClose = () => {
    if (props.closeConfirmationMessage) {
      const confirmed = window.confirm(props.closeConfirmationMessage)
      if (confirmed) {
        props.onClose();
      }
    } else {
      props.onClose();
    }
  }

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      maxWidth={props.maxWidth || 'sm'}
      fullWidth
      className={props.className}
    >
      <div className={classes.root}>
        <div className={classes.header}>
          <Typography variant={'h6'}>{props.title}</Typography>

          {props.closeButton && (
            <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          )}
        </div>
        <div className={classes.container}>
          <div className={classes.content}>
            {props.children}
          </div>

          {(props.messages || props.buttons) && (
            <div className={classes.footer}>
              <div className={classes.info}>
                {props.messages && props.messages.map(msg => {
                  return <Typography key={msg} variant={'subtitle2'}>{msg}</Typography>
                })}
              </div>
              <div className={classes.actions}>
                {props.buttons && props.buttons.map(btn => (
                  <LoaderButton
                    key={'modal-btn-' + btn.title}
                    variant={btn.variant || 'contained'}
                    color={btn.color}
                    onClick={btn.onClick}
                    disabled={btn.disabled || buttonsLoading}
                    loading={btn.loading}
                  >{btn.title}</LoaderButton>
                ))}
              </div>
            </div>
          )}

          <Loader loading={props.loading} />
        </div>


      </div>
    </Dialog>
  );
};

export default Modal;

export interface BaseModalProps {
  onClose: () => void;
  open: boolean;
  title: string;
}

export interface ModalProps extends BaseModalProps{
  buttons?: ModalButton[];
  className?: string;
  closeButton?: boolean;
  closeConfirmationMessage?: string;
  loading?: boolean;
  maxWidth?: false | 'md' | 'xs' | 'sm' | 'lg' | 'xl';
  messages?: string[];
  noContentPadding?: boolean;
  children: any;
}

export interface ModalButton {
  onClick: () => void;
  title: string;
  disabled?: boolean;
  variant?: 'text' | 'outlined' | 'contained';
  color?: 'default' | 'primary' | 'secondary';
  loading?: boolean;
}

Modal.defaultProps = {
  closeButton: true
}

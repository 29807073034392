import {BaseObject} from './core/BaseObject';
import Collection from './core/Collection';
import Model from './core/Model';
import {Workplace} from './Workplace';
import {apiGet, apiGetAllWithCount, apiGetAny, cachedApiAction, FindWithCountResult} from './core/ApiCalls';
import {Reservation} from './Reservation';
import {Organisation} from './Organisation';

interface _Workspace extends BaseObject {
  backgroundFileId: string
  data: any;
  name: string;
  organisationId?: string;
  workplaces?: Workplace[];
  organisationName?: string;
  reservations?: any[];
}

export class Workspace extends Model<_Workspace, WorkspaceCollectionClass> {
  public collection = WorkspaceCollection;

  get name(): string {
    return this.data.name;
  }

  set name(value: string) {
    this.data.name = value;
  }

  get nameWithWorkplaceCount(): string {
    return `${this.name} (${this.workplacesCount})`;
  }

  get urlName(): string {
    return this.name?.toLowerCase();
    // return this.name.toLowerCase().replace(/( +)/g, '-');
  }

  get urlOrganisation(): string {
    return this.organisation?.toLowerCase();
    // return this.organisation.toLowerCase().replace(/( +)/g, '-');
  }

  get reservationPath(): string {
    return `/reserveren/${this.urlOrganisation}/${this.urlName}`
  }

  get reservations(): Reservation[] {
    return this.data.reservations?.map(res => {
      const model = new Reservation(res);
      model.data.workspaceId = this.id;
      return model;
    });
  }

  /** Causes maximum stack amount error */
  // get data(): any {
  //   return this.data.data;
  // }

  get backgroundFileId(): string {
    return this.data.backgroundFileId;
  }

  set backgroundFileId(value: string) {
    this.data.backgroundFileId = value;
  }

  get organisationId(): string {
    return this.data.organisationId;
  }

  set organisationId(value: string) {
    this.data.organisationId = value;
  }

  get workplacesCount(): number {
    return this.data?.data?.objects?.length;
  }

  get workplaces(): Workplace[] {
    if (!this.data.workplaces) {
      this.data.workplaces = this.data?.data?.objects.map(obj => {
        return new Workplace({
          name: obj.name,
          number: obj.number,
          prefix: obj.prefix,
          flex: false,
          id: obj.id,
          notes: obj.notes
        })
      })
    }
    return this.data.workplaces;
  }

  get organisation(): string {
    return this.data.organisationName;
  }

  getWorkplaceByName(name: string): Workplace {
    const normalize = (str: string) => str.replace(/[\s-]/g, '').toLowerCase();
    return this.workplaces.find(w => normalize(w.fullName).includes(normalize(name)));
  }

  getWorkplaceById(id: string): Workplace {
    return this.workplaces.find(w => w.id === id);
  }
}

export class WorkspaceCollectionClass extends Collection<_Workspace, Workspace> {
  public route = '/workspaces';

  findOrganisationWorkspaces(organisation: string, refreshCache = false): Promise<FindWithCountResult<Workspace>> {
    const apiAction = () => {
      return apiGetAllWithCount(`${this.url + this.route}/${organisation}`, this.createModel, this.privateFinds);
    }
    return this.cachedFinds ? cachedApiAction(`findOrganisationWorkspaces-${organisation}`, apiAction, refreshCache) : apiAction();
  }

  findWorkspaceByNameAndOrganisation(name: string, organisation: string, withReservations = false, refreshCache = false): Promise<Workspace> {
    const apiAction = () => {
      return apiGetAny(`${this.url + this.route}/${organisation}/${name}`, this.privateFinds, {withReservations});
    }
    return this.cachedFinds ? cachedApiAction(`findWorkspaceByNameAndOrganisation-${organisation}-${name}`, apiAction, refreshCache) : apiAction();
  }
}

export const WorkspaceCollection = new WorkspaceCollectionClass(Workspace);


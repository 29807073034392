import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Chip, Typography} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: 4,
    backgroundColor: '#d1dbdb',

    transition: '0s',
    '&:hover': (props: CodeTagProps) => ({
      backgroundColor: '#FFF',
    })
  }
}));

export const CodeTag = (props: CodeTagProps) => {
  const classes = useStyles(props);

  const handleClick = () => {
    if (props.onClick && !props.disabled) {
      props.onClick();
    }
  }

  return (
    <Chip
      className={`${classes.root} ${props.className}`}
      onClick={handleClick}
      size={props.size}
      disabled={props.disabled}
      label={
        <Typography>{props.text}</Typography>
      }
    />
  );
}

export default CodeTag;

interface CodeTagProps {
  text: string;
  size?: any;
  className?: string;
  onClick?: () => void;
  disabled?: boolean;
}

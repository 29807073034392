import React, {useEffect, useState} from 'react';
import {Chip, makeStyles, Typography} from '@material-ui/core';
import ContentLoader from './ContentLoader';
import LoaderButton from '../snippets/LoaderButton';
import {ModalButton} from '../modals/Modal';
import {RowAction} from '../table/BaseTable';
import ActionsOverlay from '../snippets/ActionsOverlay';
import {Code} from '@material-ui/icons';
import CodeTag from '../snippets/CodeTag';

const useStyles = makeStyles(theme => ({
  root: (props: DubwebCardProps) => ({
    position: 'relative',
    display: 'block',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    boxShadow: props.elevation ? `${theme.spacing(props.elevation)}px ${theme.spacing(props.elevation)}px ${theme.spacing(props.elevation * 2)}px ${theme.spacing(props.elevation)}px rgba(0,0,0,0.2)` : 'unset',
    backgroundColor: props.backgroundColor || props.inverted ? theme.palette.common.black : theme.palette.common.white,
    border: props.border ? `${props.border}px solid ${theme.palette.primary.main}` : '',
    transition: '100ms all',
    '&:hover': props.hoverable && {
      transform: 'scale(1.05, 1.05)',
      cursor: 'pointer',
    }
  }),
  header: (props: DubwebCardProps) => ({
    height: 60,
    backgroundColor: props.inverted ? theme.palette.common.black : theme.palette.primary.main,
    color: props.inverted ? theme.palette.common.white : theme.palette.secondary.main,
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    borderTop: `4px solid ${props.borderColor || theme.palette.primary.main}`,
    borderTopLeftRadius: props.flatBorderTop ? 4 : 8,
    borderTopRightRadius: props.flatBorderTop ? 4 : 8,
    display: 'flex',
    position: 'relative'
  }),
  title: {
    fontWeight: 'bold',
    fontStyle: 'italic',
    lineHeight: '60px'
  },
  info: {
    paddingLeft: theme.spacing(4)
  },
  titleTag: {
    margin: 'auto 0 auto 8px',
    backgroundColor: theme.palette.primary.main
  },
  footer: (props: DubwebCardProps) => ({}),
  content: (props: DubwebCardProps) => ({
    // paddingLeft: theme.spacing(props.spacing ?? 4),
    // paddingRight: theme.spacing(props.spacing ?? 4),
    padding: theme.spacing(props.topSpacing ?? 0, props.spacing ?? 4, 4, props.spacing ?? 4)
  }),
  '@keyframes shake': {
    '0%, 100%': {
      transform: 'translateX(0)'
    },
    '10%, 30%, 50%, 70%, 90%': {
      transform: 'translateX(-10px)'
    },
    '20%, 40%, 60%, 80%': {
      transform: 'translateX(10px)'
    }
  },
  shaker: {
    animationName: '$shake',
    animationDuration: '1s',
    animationFillMode: 'both'
  },
  actions: (props: DubwebCardProps) => ({
    paddingLeft: theme.spacing(props.spacing ?? 4),
    paddingRight: theme.spacing(props.spacing ?? 4),
    paddingBottom: theme.spacing(props.spacing ?? 4),
    display: 'flex',
    justifyContent: 'flex-end',
    '& > *': {
      marginLeft: theme.spacing(2)
    }
  }),
}));

export const DubwebCard = (props: DubwebCardProps) => {
  const classes = useStyles(props);

  const buttonsLoading = props.buttons?.map(btn => btn.loading).reduce((prev, curr) => curr, false);

  const [errorAnimation, setErrorAnimation] = useState(false);

  useEffect(() => {
    if (props.error) {
      setErrorAnimation(true);
      setTimeout(() => {
        setErrorAnimation(false);
      }, 1000);
    }
  }, [props.error]);

  return (
    <div className={`${classes.root} ${props.className} ${errorAnimation && classes.shaker}`} onClick={props.onClick}>
      {props.title && (
        <div className={classes.header}>
          <Typography variant={'h3'} className={classes.title}>{props.title}</Typography>
          {props.tagVisible && (
            <CodeTag className={classes.titleTag} text={props.tag} size={'small'} />
          )}
        </div>
      )}

      <ActionsOverlay rowActions={props.rowActions} inverted={props.inverted} row={props.row} padding={20} />

      <div className={classes.content}>
        {props.children}
      </div>

      {(props.footerMessages || props.buttons) && (
        <div className={classes.footer}>
          <div className={classes.info}>
            {props.footerMessages?.map(msg => {
              return <Typography color={'primary'} key={msg} variant={'subtitle2'}>{msg}</Typography>
            })}
          </div>
          <div className={classes.actions}>
            {props.buttons && props.buttons.map(btn => (
              <LoaderButton
                key={'modal-btn-' + btn.title}
                variant={btn.variant || 'contained'}
                color={btn.color}
                onClick={btn.onClick}
                disabled={btn.disabled || buttonsLoading}
                loading={btn.loading}
              >{btn.title}</LoaderButton>
            ))}
          </div>
        </div>
      )}

      {props.loading !== undefined && <ContentLoader loading={props.loading} loader={'inctrl'} />}
    </div>
  )
};

export default DubwebCard;

export interface DubwebCardProps {
  backgroundColor?: string;
  border?: number;
  borderColor?: string;
  buttons?: ModalButton[];
  children: any;
  className?: string;
  elevation?: number;
  error?: string;
  flatBorderTop?: boolean;
  footerMessages?: string[];
  hoverable?: boolean;
  inverted?: boolean;
  loading?: boolean;
  onClick?: () => void;
  row?: any;
  rowActions?: RowAction<any>[];
  spacing?: number;
  topSpacing?: number;
  tag?: string;
  tagVisible?: boolean;
  title?: string;
}

import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {FileCollection} from '../../models/File';
import Img from './Img';
import {Grid, Paper, Typography} from '@material-ui/core';
import Loader from '../panels/Loader';
import Link from '../text/Link';

const useStyles = makeStyles(theme => ({
  root: {
    // display: 'flex',
    // flexDirection: 'column',
    maxWidth: '100%',
    height: `calc(100% + ${theme.spacing(10)}px)`,
    // overflow: 'auto',
    padding: theme.spacing(5)
  },
  imgWrapper: {
    transition: '200ms all',
    width: 'auto',
    '&:hover': {
      cursor: 'pointer',
    }
  },
  img: {
    maxWidth: '100%',
    maxHeight: 200
  },
  selected: {
    border: `3px solid ${theme.palette.primary.main}`
  },
  noResultsText: {
    padding: theme.spacing(2),
    display: 'flex',
  },
  link: {
    paddingLeft: theme.spacing(1),
    color: theme.palette.common.black
  }
}));

export const FilePicker = (props: FilePickerProps) => {
  const classes = useStyles(props);

  const [loading, setLoading] = useState(true);
  const [files, setFiles] = useState([])
  const [selectedFileId, setSelectedFileId] = useState(null);

  useEffect(() => {
    setLoading(true);
    FileCollection.findAllWithCount({organisationId: props.organisationId}).then(res => {
      setFiles(res.rows);
      setLoading(false);
    })
  }, [props.organisationId]);

  const handleItemClick = (file: any) => {
    setSelectedFileId(file.id);
    props.onSelect(file);
  }

  return (
    <Grid container spacing={3} className={`${classes.root} ${props.className}`}>
      {files.map(file => (
        <Grid key={file.id} item xs={4} className={classes.imgWrapper} onClick={() => handleItemClick(file)}>
          <Img
            fileName={file.id}
            className={`${classes.img} ${file.id === selectedFileId && classes.selected}`}
            type={'thumbnail'}
          />
        </Grid>
      ))}

      {loading === false && files.length === 0 && (
        <div className={classes.noResultsText}>
          <Typography>Je hebt nog geen bestanden geupload voor deze organisatie. </Typography>
          <Link to={'/admin/files'} className={classes.link}>Klik hier om naar bestanden te gaan.</Link>
        </div>
      )}

      <Loader loading={loading} />
    </Grid>
  );
}

export default FilePicker;

interface FilePickerProps {
  className?: string;
  organisationId?: string;
  onSelect: (item: any) => void;
}

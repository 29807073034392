import React, {useContext, useRef, useState} from 'react';
import {Button, makeStyles, Typography} from '@material-ui/core';
import DubwebCard from '../components/panels/DubwebCard';
import {useHistory, useLocation} from 'react-router-dom';
import {UserCollection} from '../models/User';
import {AppContext} from '../contexts/AppContext';
import {FormInputProps} from '../components/forms/FormInput';
import FormPanel from '../components/forms/FormPanel';
import {useQuery} from '../utils';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'fixed',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.common.black,
    display: 'flex',
    justifyContent: 'space-around'
  },
  card: {
    position: 'absolute',
    width: 'auto',
    minWidth: 400,
    maxWidth: 500,
    minHeight: 150,
    margin: 'auto',
    top: '20%',
    color: theme.palette.common.black,

  },
  actions: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column-reverse',
  }
}));

export const AcceptInvitation = (props: AcceptInvitationProps) => {
  const classes = useStyles(props);

  const {login} = useContext(AppContext);

  const [formValid, setFormValid] = useState(false);
  const history = useHistory();
  const query = useQuery();
  const validInvitation = Boolean(query.get('invitation'));
  const email = query.get('email') || '';

  const me = useRef({
    email: '',
    password: '',
    firstName: '',
    lastName: ''
  });

  const getMe = () => {
    return me.current;
  };

  const inputs: FormInputProps[] = [
    {
      id: 'firstName',
      label: 'Voornaam',
      required: true
    },
    {
      id: 'lastName',
      label: 'Achternaam',
      required: true
    },
    {
      id: 'email',
      label: 'Email',
      defaultValue: email,
      required: true,
      disabled: true,
    },
    {
      id: 'password',
      label: 'Wachtwoord',
      type: 'password',
      required: true,
      validator: (value) => value.length >= 8
    }
  ];

  const acceptInvitation = async () => {
    const me = getMe();

    const {firstName, lastName, email, password} = me;
    const invitation = query.get('invitation');

    try {
      await UserCollection.acceptInvitation(email, firstName, lastName, password, invitation);
      await login(email, password)
      history.push('/');
    } catch {
      console.log('Could not log in')
    }
  }


  const handleValidated = (values) => {
    const me = getMe();

    me.email = values.email;
    me.password = values.password;
    me.firstName = values.firstName;
    me.lastName = values.lastName;
    setFormValid(true);
  }

  const handleInvalidated = () => {
    setFormValid(false);
  }

  return (
    <div className={classes.root}>
      <DubwebCard className={classes.card} title={'Je bent uitgenodigd!'}>

        {validInvitation ? (
          <>
            <Typography variant={'body1'} style={{marginTop: 12}}>
              Vul hier jouw gegevens in en klik vervolgens op "Accepteer uitnodiging". Het wachtwoord moet uit minimaal
              8
              tekens bestaan.
            </Typography>


            <FormPanel
              active={true}
              inputs={inputs}
              onFormValidated={handleValidated}
              onFormInvalidated={handleInvalidated}
              timeTilValidate={200}
            />

            <div className={classes.actions}>
              <Button
                type={'submit'}
                variant={'contained'}
                color={'primary'}
                onClick={acceptInvitation}
                onSubmit={acceptInvitation}
                disabled={!formValid}
              >Accepteer uitnodiging</Button>
            </div>

          </>
        ) : (
          <Typography  style={{marginTop: 12}}>Maar, er is iets mis gegaan met de uitnodiging. Neem contact op met de persoon die je heeft
                      uitgenodigd.</Typography>
        )}

      </DubwebCard>
    </div>
  );
};

export default AcceptInvitation;

interface AcceptInvitationProps {

}

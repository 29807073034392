import React from 'react';
import {makeStyles} from '@material-ui/core';
import {TableColumn} from '../table/TableHeader';
import {RowAction} from '../table/BaseTable';
import DynamicTable from '../table/DynamicTable';
import {Feedback, FeedbackCollection} from '../../models/Feedback';

const useStyles = makeStyles(theme => ({
  root: {}
}));

export const FeedbackTable = (props: FeedbackTableProps) => {
  const classes = useStyles(props);

  const columns: TableColumn[] = [
    {id: 'question', label: 'Vraag'},
    {id: 'answersCount', label: 'Antwoorden'},
    // {id: 'user', label: 'Gebruiker'},

    {id: 'startTime', label: 'Van'},
    {id: 'endTime', label: 'Tot'},
    {id: 'startDate', label: 'Start', type: 'date'},
    {id: 'endDate', label: 'Eind', type: 'date'},
    {id: 'repeat', label: 'Herhaal', format: (row) => row.repeat ? 'Ja' : 'Nee'},

    // {id: 'createdAt', label: 'Aangemaakt', type: 'dateTime'}
  ];

  return (
    <div className={classes.root}>
      <DynamicTable
        onAddBtnClick={props.onAddBtnClick}
        addBtnLabel={'Nieuwe vraag toevoegen'}
        columns={columns}
        collection={FeedbackCollection}
        title={'Feedback'}
        ref={props.tableRef}
        rowActions={props.rowActions}
        onLoad={props.onLoad}
        disablePadding
      />
    </div>
  );
};

export default FeedbackTable;

interface FeedbackTableProps {
  onAddBtnClick?: () => void;
  rowActions?: RowAction<Feedback>[];
  onLoad?: () => void;
  onRefresh?: () => void;
  tableRef?: any;
}

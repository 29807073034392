import React, {useContext, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import DubwebCard from '../panels/DubwebCard';
import {Typography} from '@material-ui/core';
import {Reservation} from '../../models/Reservation';
import {RowAction} from '../table/BaseTable';
import {FaTrash} from 'react-icons/fa';
import {AppContext} from '../../contexts/AppContext';

const useStyles = makeStyles(theme => ({
  root: {}
}));

export const ReservationCard = (props: ReservationCardProps) => {
  const classes = useStyles(props);

  const {showConfirmationModal} = useContext(AppContext);

  const [loading, setLoading] = useState(false);

  const handleCancelReservation = (row: Reservation) => {
    setLoading(true);
    return row.cancel().then(() => {
      setLoading(false);
      props.onCancel && props.onCancel(row);
    });
  }

  const handleCancelClick = (row: Reservation) => {
    showConfirmationModal({
      callback: () => handleCancelReservation(row),
      confirmButtonLabel: 'Bevestigen',
      content: <Typography>Weet je zeker dat je de reservering
                           van <strong>{row.dateCalendar} {row.fromTimeString}</strong> wilt annuleren?</Typography>,
      title: 'Reservering annuleren',
      loader: loading,
      maxWidth: 'sm'
    })
  }

  const rowActions: RowAction<Reservation>[] = [
    {
      label: 'Annuleren',
      icon: <FaTrash />,
      onClick: handleCancelClick,
      hidden: (row) => row?.from < new Date() || row.canceled,
    }
  ]

  const getTagLabel = () => {
    if (props.activeId === props.reservation.id) {
      return 'nieuw';
    }

    if (props.reservation.canceled) {
      return 'geannuleerd'
    }

    return null;
  }

  const getTagVisible = () => {
    return (props.activeId === props.reservation.id) || props.reservation.canceled;
  }

  return (
    <DubwebCard
      elevation={5}
      title={props.title || 'Reservering'}
      tag={getTagLabel()}
      tagVisible={getTagVisible()}
      inverted
      flatBorderTop
      rowActions={rowActions}
      row={props.reservation}
      footerMessages={[props.reservation.createdAgo]}
    >
      <Typography color={'textSecondary'}>{props.reservation.dateCalendar}</Typography>
      <Typography color={'textSecondary'}>{props.reservation.fromTimeString} - {props.reservation.tillTimeString}</Typography>
      <Typography color={'textSecondary'}>{props.reservation.notes}</Typography>
      <Typography color={'textSecondary'}>{props.reservation.workspace}</Typography>
      <Typography color={'textSecondary'}>{props.reservation.workplace}</Typography>

      {/*<Typography color={'textSecondary'}>{props.reservation.createdAgo} geplaatst</Typography>*/}
    </DubwebCard>
  );
}

export default ReservationCard;

interface ReservationCardProps {
  activeId?: string;
  reservation: Reservation;
  onCancel?: (row: Reservation) => void;
  title?: string;
}

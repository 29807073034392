import React, {useState} from 'react';
import {Button, Checkbox, FormControlLabel, makeStyles, TextField, Typography} from '@material-ui/core';
import DubwebCard from '../components/panels/DubwebCard';
import {ContactInfoCollection} from '../models/ContactInfo';

import terrace_background from '../assets/images/terrace_background.jpg';
import {FaCheckCircle} from 'react-icons/all';
import {theme} from '../assets/themes/main-theme';

const useStyles = makeStyles(theme => ({
  background: {
    backgroundImage: `url(${terrace_background})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    position: 'absolute',
    filter: 'blur(4px)',
    webkitFilter: 'blur(4px)',
    top: -8,
    left: -8,
    width: 'calc(100% + 16px)',
    height: 'calc(100% + 16px)',
    zIndex: -1
  },
  wrapper: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'space-around',
    flexDirection: 'column',
    transition: '100ms all'
  },
  root: {
    margin: 'auto',
    backgroundColor: 'white',
    width: 400,
    paddingTop: theme.spacing(4),
    '& > *': {
      marginBottom: theme.spacing(4)
    }
  },
  actions: {
    // marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column-reverse',
  },
  checkbox: {
    fontSize: 12
  },
  completed: {
    padding: theme.spacing(10, 0),
    '& > *': {
      textAlign: 'center',
    }
  },
  checked: {
    fontSize: 80,
    color: theme.palette.success.main
  }
}));

export const CovidContact = (props: CovidContactProps) => {
  const classes = useStyles(props);

  const [name, setName] = useState('');
  const [mobile, setMobile] = useState('');
  const [email, setEmail] = useState('');
  const [covidQuestionAsked, setCovidQuestionAsked] = useState(false);
  const [userPermission, setUserPermission] = useState(false);
  const [loading, setLoading] = useState(false);
  const [finished, setFinished] = useState(false);

  const handleButtonClick = () => {
    setLoading(true);
    ContactInfoCollection.insert({email, name, mobile}).then(res => {
      setLoading(false);
      setFinished(true);
    });
  }

  const getContent = () => {
    return !finished ? (<>
        <Typography variant={'h6'}><strong>Contactgegevens gast</strong></Typography>
        <Typography variant={'body2'}>Jouw gegevens worden enkel gebruikt bij een noodgeval. Na 14 dagen worden jouw
                                      gegevens automatisch verwijdert.</Typography>
        <FormControlLabel
          label={'Ja, vragen van de Corona-gezondheidscheck zijn gesteld.'}
          control={<Checkbox
            checked={covidQuestionAsked}
            onChange={(e) => setCovidQuestionAsked(e.target.checked)}
          />}
        />
        <TextField
          label={'Naam*'}
          fullWidth
          value={name}
          onChange={e => setName(e.target.value)}
        />
        <TextField
          label={'Telefoonnummer*'}
          fullWidth
          value={mobile}
          onChange={e => setMobile(e.target.value)}
        />
        <TextField
          label={'Email*'}
          fullWidth
          value={email}
          onChange={e => setEmail(e.target.value)}
          type={'email'}
        />

        <FormControlLabel
          label={'Ik geef toestemming. De gegevens worden na 14 dagen verwijdert.'}
          className={classes.checkbox}
          control={<Checkbox
            checked={userPermission}
            onChange={(e) => setUserPermission(e.target.checked)}
          />}
        />

        <div className={classes.actions}>
          <Button
            type={'submit'}
            onClick={() => handleButtonClick()}
            variant={'contained'}
            color={'secondary'}
            disabled={!contactValid}
          >Verstuur</Button>
        </div>
      </>) : (<div className={classes.completed}>
          <div className={classes.checked}>
            <FaCheckCircle />
          </div>
          <Typography variant={'h6'}>We hebben jouw gegevens ontvangen.</Typography>
          <Typography variant={'h6'}>Bedankt voor je medewerking!</Typography>
        </div>);
  }

  const contactValid = !!name && !!mobile && !!email && userPermission;

  return (
    <>
      <div className={classes.background} />
      <div className={classes.wrapper}>
        <DubwebCard
          className={classes.root}
          borderColor={finished && theme.palette.success.main}
          loading={loading}
          elevation={1}
          spacing={4}
        >
          {getContent()}
        </DubwebCard>
      </div>
    </>
  );
};

export default CovidContact;

interface CovidContactProps {

}
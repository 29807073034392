import React, {useEffect, useLayoutEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Checkbox, FormControl, FormControlLabel, InputProps, Switch, TextField, Typography} from '@material-ui/core';
import FormInputSelect, {InputOption} from './FormInputSelect';
import {RowAction} from '../table/BaseTable';
import {TwitterPicker} from 'react-color';
import {theme} from '../../assets/themes/main-theme';
import moment, {isMoment} from 'moment';
import {DatePicker, KeyboardDatePicker} from '@material-ui/pickers';

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 100,
    width: '100%',
  },
  optionRowActions: {},
  datePicker: {
    fontSize: 13
  }
}));

export const FormInput = (props: FormInputProps) => {
  const classes = useStyles(props);

  const requiredErrorLabel = 'Dit veld is verplicht.';

  const [value, setValue] = useState<boolean | number | string | string[]>(getDefaultValue());
  const [error, setError] = useState('');

  function getDefaultValue() {
    switch (props.type) {
      default:
      case 'color':
      case 'text':
        return props.defaultValue || '';
      case 'number':
        return props.defaultValue || 0;
      case 'radio':
      case 'select':
        if (props.multiple) {
          return [props.defaultValue || props.options && !props.disableAutoSelectFirstOption && props.options[0]?.value || ''];
        }

        return props.defaultValue || props.options && !props.disableAutoSelectFirstOption && props.options[0]?.value || '';
      case 'switch':
      case 'checkbox':
        return props.defaultValue || false;
      case 'date':
        return props.defaultValue || moment().locale('nl').toDate();
      case 'time':
        if (props.defaultValue) {
          return props.defaultValue;
        }
        let time = moment();
        const remainder = 15 - (time.minute() % 15);
        time = time.add(remainder, 'minutes');

        if (props.addValue) {
          time.add(props.addValue, 'seconds');
        }

        return time.format('HH:mm');

    }
  }

  const handleValidation = () => {
    let isValid = false;

    switch (props.type) {
      default:
      case 'radio':
      case 'select':
      case 'color':
      case 'number':
      case 'text':
        if (props.validator) {
          isValid = props.validator(value);
        } else if (props.required) {
          isValid = `${value}`.trim().length > 0;
        } else {
          isValid = true;
        }
        break;
      case 'switch':
      case 'checkbox':
        if (props.validator) {
          isValid = props.validator(value);
        } else if (props.required) {
          isValid = value === true;
        } else {
          isValid = true;
        }
        break;
      case 'time':
      case 'date':
        isValid = true;
        break;
    }

    if (!isValid) {
      if (props.invalidMessage && !error) {
        setError(props.invalidMessage);
      } else if (props.required && !error) {
        setError(requiredErrorLabel)
      }
    } else {
      setError('');
    }
  }

  const handleChange = (event) => {
    if (!event || (event.target?.value === undefined && event.target?.checked === undefined && event.hex === undefined && !isMoment(event))) {
      return;
    }

    switch (props.type) {
      default:
      case 'radio':
      case 'select':
      case 'password':
      case 'time':
      case 'text':
        setValue(event.target.value);
        props.onChange && props.onChange(event.target.value);
        break;
      case 'number':
        setValue(Number.parseInt(event.target.value));
        props.onChange && props.onChange(Number.parseInt(event.target.value));
        break;
      case 'date':
        setValue(event.toDate());
        props.onChange && props.onChange(event.toDate());
        break;
      case 'checkbox':
      case 'switch':
        setValue(event.target.checked);
        props.onChange && props.onChange(event.target.checked);
        break;
      case 'color':
        setValue(event.hex);
        props.onChange && props.onChange(event.hex);
        break;
    }
  }

  const handleLoseFocus = (event) => {
    handleValidation();
  }

  const getHelperText = () => {
    if (error && props.invalidMessage) {
      return props.invalidMessage;
    } else if (error && props.required) {
      return requiredErrorLabel;
    } else if (props.required && props.fillErrorSpacing) {
      /** This will make space for the helper text, so that the entire view doesn't jump around */
      return ' ';
    } else {
      return null;
    }
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && props.onEnter) {
      props.onEnter();
    }
  }

  const getComponent = (props: FormInputProps) => {
    const inputProps: any = {
      value,
      onChange: handleChange,
      label: props.label,
      disabled: props.disabled,
      className: props.className,
      required: props.required,
      color: props.color || 'primary',
      inputProps: props.inputProps || {}
    };
    const innerInputProps: any = {}

    if (props.step) {
      inputProps.inputProps.step = props.step;
    }

    switch (props.type) {
      default:
      case 'password':
      case 'number':
      case 'time':
      // eslint-disable-next-line no-fallthrough
      case 'text':
        return (
          <FormControl className={classes.root} key={props.id}>
            <TextField
              {...inputProps}
              fullWidth={props.fullWidth}
              helperText={getHelperText()}
              onError={console.error}
              onBlur={handleLoseFocus}
              onKeyPress={handleKeyPress}
              placeholder={props.placeholder}
              type={props.type}
              error={!!error}
              InputLabelProps={{style: {color: props.textColor || theme.palette.common.black, shrink: true}}}
              InputProps={{style: {color: props.textColor || theme.palette.common.black}, ...props.InputProps}}
            />
          </FormControl>
        );
      case 'date':
        innerInputProps.disablePast = inputProps.inputProps.disablePast ?? true;
        delete inputProps.inputProps?.disablePast;
        delete inputProps.color;
        return (
          <FormControl className={classes.root} key={props.id}>
            <KeyboardDatePicker
              {...inputProps}
              {...innerInputProps}
              format={'DD/MM/yyyy'}
              autoOk
              // disablePast={inputProps?.inputProps?.disablePast ?? true}
              disabled={props.disabled}
              className={classes.datePicker}
              minDateMessage={'Datum mag niet eerder zijn dan vandaag'}
              invalidDateMessage={'Datum is ongeldig'}
            />
          </FormControl>
        )
      case 'select':
        if (!props.options) {
          console.log('WARNING! No options supplied to FormInput-select')
          return null;
        }
        return (
          <FormControl className={classes.root} key={props.id}>
            <FormInputSelect
              id={props.id}
              multiple={props.multiple}
              inputProps={inputProps}
              options={props.options}
              disabled={props.disabled}
              rowActions={props.optionRowActions}
            />
          </FormControl>
        );
      case 'checkbox':
        inputProps.checked = inputProps.value;
        delete inputProps.value;
        return (
          <FormControlLabel
            className={classes.root} key={props.id}
            color={props.textColor}
            label={props.label}
            control={<Checkbox {...inputProps} />}
            labelPlacement={props.labelPlacement}
          />
        )
      case 'switch':
        inputProps.checked = inputProps.value;
        delete inputProps.value;
        return (
          <FormControlLabel
            className={`${classes.root} ${props.className}`} key={props.id}
            label={props.label}
            color={'primary'}
            control={<Switch {...inputProps} />}
            labelPlacement={props.labelPlacement}
          />
        )
      case 'color':
        return (
          <div className={classes.root} key={props.id}>
            <TwitterPicker
              color={value as string}
              className={props.className}
              onChange={handleChange}
              // onChangeComplete={handleChange}
              colors={props.presetColors}
              triangle={'hide'}
            />

            {/*<BlockPicker*/}
            {/*  color={value as string}*/}
            {/*  className={props.className}*/}
            {/*  onChange={handleChange}*/}
            {/*  // onChangeComplete={handleChange}*/}
            {/*  colors={[]}*/}
            {/*  triangle={'hide'}*/}
            {/*/>*/}
          </div>
        )
      case 'info':
        return (
          <div className={classes.root} key={props.id}>
            <Typography>{props.label}</Typography>
          </div>
        )
    }
  }

  useLayoutEffect(() => {
    if (props.invalidMessage) {
      handleValidation();
    }
  })

  useEffect(() => {
    props.onLoad && props.onLoad(getDefaultValue());
  }, []);

  useEffect(() => {
    if (props.defaultValue !== undefined || props.active === false) {
      const defaultValue = getDefaultValue();

      setValue(defaultValue);
      setError('');

      props.onLoad && props.onLoad(defaultValue);
    }
  }, [props.defaultValue, props.active]);

  useEffect(() => {
    if (props.initialValidate) {
      handleValidation();
    }
  }, [props.initialValidate]);

  return getComponent(props);
}

export default FormInput;

export interface FormInputProps {
  InputProps?: InputProps;
  active?: boolean;
  addValue?: any;
  className?: string;
  color?: 'primary' | 'secondary';
  defaultValue?: any;
  disableAutoSelectFirstOption?: boolean;
  disabled?: boolean;
  fillErrorSpacing?: boolean;
  fullWidth?: boolean;
  gridWidth?: boolean | 'auto' | 2 | 1 | 4 | 3 | 12 | 5 | 7 | 6 | 8 | 9 | 10 | 11;
  id: string;
  placeholder?: string;
  initialValidate?: boolean;
  invalidMessage?: string;
  isValid?: (value) => boolean;
  inputProps?: any;
  label?: string;
  labelPlacement?: 'bottom' | 'end' | 'top' | 'start';
  multiple?: boolean;
  onChange?: (value) => void;
  onEnter?: () => void;
  onLoad?: (value) => void;
  optionRowActions?: RowAction<any> [];
  options?: InputOption[];
  presetColors?: string[];
  required?: boolean;
  step?: number;
  shrink?: boolean;
  textColor?: string;
  type?: FormInputType;
  validator?: (value) => boolean;
}

export type FormInputType =
  'password'
  | 'number'
  | 'text'
  | 'select'
  | 'checkbox'
  | 'radio'
  | 'switch'
  | 'color'
  | 'date'
  | 'time'
  | 'info';

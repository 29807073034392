import React, {useContext} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {useHistory} from 'react-router-dom';
import {AppContext} from '../contexts/AppContext';
import Loader from '../components/panels/Loader';

const useStyles = makeStyles(theme => ({
  root: {}
}));

export const Redirector = (props: RedirectorProps) => {
  const classes = useStyles(props);

  const {user, getFirstPermittedModule} = useContext(AppContext);
  const history = useHistory();

  if (!user) {
    return <Loader loading={true}/>
  }

  const module = getFirstPermittedModule();

  if (module) {
    history.push(module.route);
  }

  return null;
}

export default Redirector;

interface RedirectorProps {

}

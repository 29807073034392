import React, {useEffect, useRef, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {InputOption} from '../forms/FormInputSelect';
import {User, UserCollection} from '../../models/User';
import FormInput from '../forms/FormInput';

const useStyles = makeStyles(theme => ({
  root: {}
}));

export const UserSelector = (props: UserSelectorProps) => {
  const classes = useStyles(props);

  const me = useRef({
    users: [],
    usersById: {}
  })
  const getMe = () => me.current;

  const [user, setUser] = useState<User>()
  const [users, setUsers] = useState<InputOption[]>([]);

  const handleChange = (value: any) => {
    const me = getMe();

    setUser(value);

    if (props.onChange) {
      props.onChange(me.usersById[value]);
    }
  }

  const handleLoad = () => {
    if (props.onLoad) {
      props.onLoad();
    }

  }

  useEffect(() => {
    const me = getMe();

    UserCollection.findAllWithCount({organisationId: props.organisationId}).then(res => {
      const users = res.rows;

      me.users = users;
      me.usersById = res.rowsById;

      if (!users || users.length === 0) {
        handleLoad();
        return;
      }

      setUser(users[0]);
      setUsers(users.map(user => ({
        value: user.id,
        label: user.displayName
      })));

      if (!props.disabled) {
        handleLoad();
        handleChange(users[0].id);
      }
    });
  }, [props.organisationId]);

  return (
    <FormInput
      id={'user'}
      type={'select'}
      label={'Gebruiker'}
      disabled={props.disabled}
      defaultValue={user?.id}
      onChange={handleChange}
      options={users}
    />
  );
}

export default UserSelector;

interface UserSelectorProps {
  organisationId?: string;
  value?: string;
  disabled?: boolean;
  onChange?: (user: User) => void;
  onLoad?: () => void;
}

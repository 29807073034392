import Model from './core/Model';
import Collection from './core/Collection';
import {BaseObject} from './core/BaseObject';
import {apiGetAny, apiPostAny} from './core/ApiCalls';
import {Workspace} from './Workspace';
import {WorkplaceType} from './WorkplaceType';

interface _Workplace extends BaseObject {
  name: string;
  prefix?: string;
  number: number;
  flex: boolean;
  notes: string;
  workspaceName?: string;
  workplaceTypeTitle?: string;
  organisationName?: string;
}

export class Workplace extends Model<_Workplace, WorkplaceCollectionClass> {
  public collection = WorkplaceCollection;

  get name(): string {
    return this.data.workplaceTypeTitle || this.data.name;
  }

  get organisation(): string {
    return this.data.organisationName;
  }

  get workspace(): string {
    return this.data.workspaceName;
  }

  get prefix(): string {
    return this.data.prefix;
  }

  set prefix(value: string) {
    this.data.prefix = value;
  }

  get number(): number {
    return this.data.number;
  }

  get flex(): boolean {
    return this.data.flex;
  }

  get flexLabel(): string {
    return this.flex === true ? 'Ja' : 'Nee';
  }

  get type(): string {
    return this.flex === true ? 'Flexplek' : '';
  }

  get fullName(): string {
    return `${this.name} ${this.prefix || ''}${this.number}`
  }

  get urlName(): string {
    return `${this.name}-${this.prefix || ''}${this.number}`
  }

  get notes(): string {
    return this.data.notes;
  }

  get reserveUrl(): string {
    const baseUrl = 'https://randinctrl.nl';

    if (!this.organisation || !this.workspace || !this.name) {
      throw 'Organisation, workspace or workplace is missing.'
    }

    return `${baseUrl}/reserveren/${this.organisation}/${this.workspace}/${this.urlName}`;
  }

}

export class WorkplaceCollectionClass extends Collection<_Workplace, Workplace> {
  public route = '/workplaces';

  getWorkspaceByWorkplaceId(workplaceId: string, withReservations = false): Promise<Workspace> {
    return apiGetAny(`${this.url}${this.route}/${workplaceId}/workspace`, this.privateFinds, {withReservations});
  }

  getQrCodesForWorkplaces(workplaceIds: string[]): Promise<any> {
    return apiPostAny(`${this.url}${this.route}/qr`, workplaceIds);
  }

}

export const WorkplaceCollection = new WorkplaceCollectionClass(Workplace);


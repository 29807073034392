import React, {useContext, useRef, useState} from 'react';
import {Button, Link, makeStyles, Typography, withStyles} from '@material-ui/core';
import {AppContext} from '../contexts/AppContext';
import {useHistory} from 'react-router-dom';
import DubwebCard from '../components/panels/DubwebCard';
import FormPanel from '../components/forms/FormPanel';
import {FormInputProps} from '../components/forms/FormInput';
import {theme} from '../assets/themes/main-theme';
import Logo from '../components/snippets/Logo';
import {UserCollection} from '../models/User';
import {validateEmail} from '../utils/validateEmail';
import Loader from '../components/panels/Loader';

const LoginButton = withStyles({
  disabled: {
    color: 'rgba(0,0,0,0.5) !important',
    backgroundColor: '#efbe2c30 !important',
  }
})(Button);

const useStyles = makeStyles(theme => ({
  root: {
    position: 'fixed',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.common.black,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around'
  },
  card: {
    position: 'absolute',
    display: 'block',
    minWidth: 300,
    width: 'auto',
    color: theme.palette.common.white,
    margin: theme.spacing(2),
    zIndex: 1000
  },
  actions: {
    marginTop: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
  },
  input: {
    color: theme.palette.common.white
  },
  errorMessage: {
    color: theme.palette.error.main,
    maxWidth: 400
  },
  logoBottomCenter: {
    bottom: 20
  },
  logoBottomLeft: {
    bottom: 20,
    left: 20
  },
  logoTopCenter: {
    top: 20
  },
  button: {},
  link: {
    opacity: 0.7,
    cursor: 'pointer',
  }
}));

export const Login = (props: LoginProps) => {
  const classes = useStyles(props);

  const [error, setError] = useState(null);
  const [valid, setValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disableResetPassword, setDisableResetPassword] = useState(false);

  const {login} = useContext(AppContext);
  const history = useHistory();
  const me = useRef({
    email: '',
    password: '',
    disableResetPassword: false
  });

  const getMe = () => {
    return me.current;
  };

  const handleLogin = async () => {
    const me = getMe();

    if (!valid) {
      return;
    }

    setError(null);
    setLoading(true);

    login(me.email, me.password).then(user => {
      setError(null);
      const prevUrl = localStorage.getItem('prevUrl');

      if (prevUrl) {
        window.location.href = prevUrl;
        localStorage.removeItem('prevUrl');
      } else {
        history.push('/redirect');
      }
    }).catch(error => {
      handleError(error?.response?.status);
      setLoading(false);
    })
  }

  const handleError = (statusCode) => {
    switch (statusCode) {
      case 403:
        setError('Dit account is inactief.')
        break;
      case 404:
        setError('We konden geen account vinden met dit e-mail adres.');
        break;
      default:
        setError('Foutieve gegevens.');
    }
  }

  const handleEmailChange = (value) => {
    const me = getMe();
    me.email = value;
  }

  const inputs: FormInputProps[] = [
    {
      id: 'email',
      label: 'Email adres',
      onChange: (value) => handleEmailChange(value),
      required: true,
    }, {
      id: 'password',
      label: 'Wachtwoord',
      required: true,
      type: 'password'
    }
  ]

  const handleEnter = () => {
    if (valid) {
      handleLogin();
    }
  }

  const handleValidated = (values) => {
    const me = getMe();

    me.email = values.email;
    me.password = values.password;
    setValid(true);
  }

  const handleInvalidated = () => {
    setValid(false);
  }

  const handlePasswordResetClick = async () => {
    const me = getMe();

    if (me.disableResetPassword) {
      return;
    }

    if (!me.email) {
      setError('Vul hieronder je emailadres in.');
      return;
    } else if (!validateEmail(me.email)) {
      setError('Het emailadres dat je hebt ingevoerd is ongeldig.');
      return;
    }

    setLoading(true);

    UserCollection.passwordRequest(me.email).then(res => {
      setError(`We hebben een email gestuurd naar ${me.email} om je wachtwoord opnieuw in te stellen. Het kan even duren voordat de mail binnen is.`);
    }).catch(error => {
      switch (error?.response?.status) {
        case 404:
          setError('Dit emailadres is niet bekend bij ons.');
          break;
        case 400:
          setError('We hebben je al een mail gestuurd. Bekijk ook je spam folder.');
          break;
        default:
          setError('We kunnen geen email naar je sturen om je wachtwoord opnieuw in te stellen. Probeer het later nog eens.');
      }
    }).finally(() => {
      setLoading(false);
      setValid(false);
      me.disableResetPassword = true;
    });
  }

  return (
    <div className={classes.root}>
      <Logo src={'/images/servicepunt.png'} className={classes.logoTopCenter} size={'5rem'}/>

      <DubwebCard
        className={classes.card}
        error={!disableResetPassword && error}
        title={'Aanmelden bij INCTRL'}
        elevation={5}
        backgroundColor={'transparent'}
        flatBorderTop={false}
        topSpacing={4}
      >
        {error && <Typography className={classes.errorMessage} gutterBottom>{error}</Typography>}

        <FormPanel
          active={true}
          inputs={inputs}
          textColor={theme.palette.common.white}
          onFormValidated={handleValidated}
          onFormInvalidated={handleInvalidated}
          onEnter={handleEnter}
          timeTilValidate={50}
        />

        <div className={classes.actions}>
          {!disableResetPassword && (
            <Link gutterBottom className={classes.link} onClick={handlePasswordResetClick}>Wachtwoord vergeten?</Link>
          )}
          <LoginButton
            className={classes.button}
            type={'submit'}
            variant={'contained'}
            color={'primary'}
            onClick={handleLogin}
            onSubmit={handleLogin}
            disabled={!valid}
          >Aanmelden</LoginButton>
        </div>

        <Loader loading={loading} transparent={false} backgroundColor={'#323232'}/>

      </DubwebCard>

      <Logo src={'/images/inctrl_white.png'} className={classes.logoBottomCenter} size={'3rem'}/>
      <Logo src={'/images/rand_logo.png'} className={classes.logoBottomLeft} size={'2.7rem'}/>
    </div>
  );
};

export default Login;

interface LoginProps {

}

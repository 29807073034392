import React, {useEffect, useReducer, useRef} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import FormInput, {FormInputProps, FormInputType} from './FormInput';
import {Grid, GridSpacing} from '@material-ui/core';
import {useDebouncedFunction} from '../../utils/hooks';
import {Dict, mapToObj} from '../../utils';

const useStyles = makeStyles(theme => ({
  root: {}
}));

export const FormPanel = (props: FormPanelProps) => {
  const classes = useStyles(props);

  const [ignored, forceUpdate] = useReducer(x => x + 1, 0);

  const me = useRef({
    initialized: false,
    fieldsWithValidation: [],
    fields: {},
    validatedFields: {},
    formValid: false,
  });

  const getMe = () => {
    return me.current;
  };

  const handleChange = (input, value) => {
    const me = getMe();

    // if (me.fields[input.id] !== undefined) {
    me.fields[input.id] = value;
    // }

    if (input.onChange) {
      input.onChange(value);
    }

    debouncedHandleValidation();
  }

  const requiredValidator = (val) => {
    const valid = val !== undefined && !!val === true;
    if (typeof val == 'string') {
      return valid && !!val.trim() === true;
    }
    return valid;
  };

  const handleValidation = () => {
    const me = getMe();

    for (const input of props.inputs) {
      const validator = input.validator || requiredValidator;
      if (me.fieldsWithValidation.includes(input.id)) {
        me.validatedFields[input.id] = validator(me.fields[input.id]);
      }
    }

    const invalidFields = Object.values(me.validatedFields).filter(x => !x).length;

    if (invalidFields === 0) {
      me.formValid = true;
      props.onFormValidated(me.fields);
    } else if (me.formValid) {
      me.formValid = false;
      props.onFormInvalidated(me.fields);
    }

    // console.log({invalidFields, formValid: me.formValid, fields: me.fields})
  }

  /**
   * There is an edge-case bug with this function where it will use an old version of me.fields to handle validation.
   * This bug will result in a false formValid.
   * TODO: Fix handleValidation old reference
   */
  const debouncedHandleValidation = useDebouncedFunction(handleValidation, props.timeTilValidate || 400);

  const propsInputComparator = props.inputs.map(i => i.defaultValue).join('');

  const loadData = () => {
    const me = getMe();

    me.initialized = true;
    me.fieldsWithValidation = props.inputs.filter(input => input.validator || input.required).map(input => input.id);
    me.fields = mapToObj(props.inputs.map(input => input.id), (item) => [item, '']);

    for (const [i, key] of Object.keys(me.fields).entries()) me.fields[key] = props.inputs[i].defaultValue || (props.inputs[i].options && props.inputs[i].options[0].value) || '';
    for (const [i, key] of Object.keys(me.fields).entries()) me.validatedFields[key] = (!(props.inputs[i].validator || props.inputs[i].required));

  }

  useEffect(() => {
    const me = getMe();

    if (props.active === false) {
      me.initialized = false;
      me.fields = {};
      me.validatedFields = {};
      me.fieldsWithValidation = [];
      me.formValid = false;
      forceUpdate();
    }

    if (props.active === true && !me.initialized) {
      loadData();
    }
  }, [props.active]);

  useEffect(() => {
    if (props.active && propsInputComparator) {
      loadData();
    }
  }, [propsInputComparator]);

  return (
    <Grid container className={classes.root} spacing={props.spacing || 3}>
      {props.inputs.map(inputProps => (
        <Grid key={inputProps.id} item xs={inputProps.gridWidth || 12}>
          <FormInput
            {...inputProps}
            onEnter={props.onEnter}
            textColor={props.textColor}
            active={props.active}
            onChange={(val) => handleChange(inputProps, val)}
            fillErrorSpacing={props.fillErrorSpacing}
          />
        </Grid>
      ))}
    </Grid>
  );
}

export default FormPanel;

interface FormPanelProps {
  active: boolean;
  textColor?: string;
  inputs: FormInputProps[];
  onFormValidated: (values: ValidatedValues) => void;
  onFormInvalidated?: (values: ValidatedValues) => void;
  onEnter?: () => void;
  spacing?: GridSpacing;
  fillErrorSpacing?: boolean;
  timeTilValidate?: number;
}

export type ValidatedValues = Dict<string>

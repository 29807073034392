import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {titleCase} from '../../utils';
import {FaBackspace, FaHandPointUp, FaMousePointer} from 'react-icons/fa/index';
import {IconType} from 'react-icons';
import CodeTag from './CodeTag';

const useStyles = makeStyles(theme => ({
  root: {
    // fontFamily: 'Courier New',
    paddingLeft: 4,
    display: 'flex',
    fontSize: '14px',
  },
  tag: {
    margin: theme.spacing(0, 1),
    height: '80%'
  },
  icon: {
    paddingTop: 3,
    paddingLeft: 8,
    paddingRight: 8
  }
}));

export const KeyBinding = (props: KeyBindingProps) => {
  const classes = useStyles(props);

  const {modifiers, keys, component} = props;

  const getIconKey = (iconProps: { label: string; icon: IconType }) => {
    return (
      <div className={classes.icon} aria-label={iconProps.label} key={iconProps.label}>
        <iconProps.icon />
      </div>
    )
  }

  const getComponent = (text: string) => {
    switch (props.component) {
      case 'tag':
        return <CodeTag className={classes.tag} text={text} size={'small'} key={text} />
      default:
        return text;
    }
  }

  const getModifiers = () => {
    const _modifiers = [];
    for (const m of modifiers) {
      switch (m) {
        case 'ctrl':
          _modifiers.push(m);
          break;
        case 'alt':
          _modifiers.push(m.toUpperCase());
          break;
        case 'shift':
          _modifiers.push(titleCase(m));
          break;
      }
    }

    if (_modifiers?.length === 0) {
      return null;
    }

    const result = [];

    for (const [index, modifier] of _modifiers.entries()) {
      if (component) {
        result.push(getComponent(modifier));
      } else {
        result.push(modifier);
      }
      if (index < result.length - 1) {
        result.push(' + ');
      }
    }

    return <>{result}</>
  }

  const getKeys = () => {
    const _keys = [];
    for (const k of keys) {
      switch (k) {
        case 'backspace':
          _keys.push(getIconKey({label: k, icon: FaBackspace}));
          break;
        case 'mouse':
          _keys.push(getIconKey({label: k, icon: FaMousePointer}));
          break;
        case 'drag':
          _keys.push(getIconKey({label: k, icon: FaHandPointUp}));
          break;
        case 'enter':
        default:
          _keys.push(k);
          break;
      }
    }
    return (
      <>{_keys.map(k => k)}</>
    )
  }

  return (
    <div className={classes.root}>
      ({getModifiers()}{modifiers.length > 0 ? ' + ' : ''}{getKeys()})
    </div>
  );
}

export default KeyBinding;

export type KeyModifier = 'ctrl' | 'alt' | 'shift';
export type KeyType = 'backspace' | 'enter' | 'mouse' | 'drag' | string;

interface KeyBindingProps {
  modifiers: KeyModifier[];
  keys: KeyType[];
  component?: 'tag' | undefined;
}

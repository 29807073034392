import Redirector from '../routes/Redirector';
import Home from '../routes/Home';
import ResetPassword from '../routes/ResetPassword';
import Login from '../routes/Login';
import AcceptInvitation from '../routes/AcceptInvitation';
import CovidContact from '../routes/CovidContact';
import Reserve from '../routes/Reserve';
import OrganisationReserve from '../routes/OrganisationReserve';
import WorkspaceReserve from '../routes/WorkspaceReserve';
import {getModuleByName, Module} from './Modules';
import Content from '../components/core/Content';
import PageNotFound from '../routes/PageNotFound';
import Logout from '../routes/Logout';

interface Route {
  id: string;
  path: string | string[];
  component: any;
  private?: boolean;
  exact?: boolean;
  module?: Module,
}

export const routes: Route[] = [
  {
    id: 'home',
    path: '/',
    component: Home,
    exact: true
  }, {
    id: 'redirect',
    path: '/redirect',
    component: Redirector,
  }, {
    id: 'covid-contact',
    path: '/covid-contact',
    component: CovidContact,
  }, {
    id: 'accept-invitation',
    path: '/accept-invitation',
    component: AcceptInvitation,
  }, {
    id: 'login',
    path: '/login',
    component: Login,
  }, {
    id: 'logout',
    path: '/logout',
    component: Logout,
  }, {
    id: 'password-reset',
    path: '/password-reset',
    component: ResetPassword,
  }, {
    id: 'reserve',
    path: '/reserve',
    component: Reserve,
  }, {
    id: 'reserve-organisation',
    path: '/reserveren/:organisation',
    component: OrganisationReserve,
    module: getModuleByName('organisation-reserve'),
    private: true,
    exact: true
  }, {
    id: 'reserve-organisation-workplace',
    path: ['/reserveren/:organisation/:workspace/:workplace', '/reserveren/:organisation/:workspace'],
    component: WorkspaceReserve,
    module: getModuleByName('organisation-reserve-workspace'),
    private: true,
    exact: true
  }, {
    id: 'admin-*',
    path: '/admin/*',
    component: Content,
    private: true,
  }, {
    id: '*',
    path: '*',
    component: PageNotFound,
    private: true
  }
]
import {BaseObject} from './core/BaseObject';
import Collection from './core/Collection';
import Model from './core/Model';

interface _ContactInfo extends BaseObject {
  name: string;
  email: string;
  mobile: string;
  organisationId?: number;
}

export class ContactInfo extends Model<_ContactInfo, ContactInfoCollectionClass>{
  public collection = ContactInfoCollection;

  get email(): string {
    return this.data.email;
  }

  set email(value: string) {
    this.data.email = value;
  }

  get mobile(): string {
    return this.data.mobile;
  }

  set mobile(value: string) {
    this.data.mobile = value;
  }

  get name(): string {
    return this.data.name;
  }

  set name(value: string) {
    this.data.name = value;
  }

  get organisationId(): number {
    return this.data.organisationId;
  }

  set organisationId(value: number) {
    this.data.organisationId = value;
  }


}


export class ContactInfoCollectionClass extends Collection<_ContactInfo, ContactInfo> {
  public route = '/contact-info';

}

export const ContactInfoCollection = new ContactInfoCollectionClass(ContactInfo);


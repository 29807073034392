import React, {useContext, useEffect, useRef, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Modal, {ModalButton} from './Modal';
import {Grid} from '@material-ui/core';
import FormInput from '../forms/FormInput';
import {Reservation, ReservationCollection} from '../../models/Reservation';
import {Workspace} from '../../models/Workspace';
import WorkspaceSelector from '../snippets/WorkspaceSelector';
import {AppContext} from '../../contexts/AppContext';
import OrganisationSelector from '../snippets/OrganisationSelector';
import {Organisation} from '../../models/Organisation';
import {InputOption} from '../forms/FormInputSelect';
import moment, {Moment} from 'moment';
import DateTimePicker from '../panels/DateTimePicker';
import PlaceReservationPanel from '../panels/PlaceReservationPanel';
import Title from '../snippets/Title';
import UserSelector from '../snippets/UserSelector';
import {User} from '../../models/User';
import {asyncSleep} from '../../utils';

const useStyles = makeStyles(theme => ({
  content: {
    minHeight: 450,
    maxHeight: 800,
    overflow: 'auto',
    padding: theme.spacing(4),
  }
}));

export const ReservationModal = (props: ReservationModalProps) => {
  const classes = useStyles(props);

  const {user} = useContext(AppContext);

  const [workspace, setWorkspace] = useState<Workspace>(null);
  const [workspaceId, setWorkspaceId] = useState<string>(null);
  const [workplaceId, setWorkplaceId] = useState<string>(null);
  const [organisation, setOrganisation] = useState<Organisation>(null);
  const [organisationId, setOrganisationId] = useState<string>(props.reservation?.data?.organisationId);
  const [reservationUser, setReservationUser] = useState<User>(null);
  const [reservationUserId, setReservationUserId] = useState<string>(null);
  const [error, setError] = useState('');

  const me = useRef({
    organisationId: null,
    workspaceId: null,
    workplaceId: null,
    user: null,
    userId: null,
    from: moment().seconds(0),
    till: moment().seconds(0),
  })
  const getMe = () => me.current;

  const clearSettings = () => {
    const me = getMe();

    me.from = moment().seconds(0);
    me.till = moment().seconds(0);
    me.user = null;
    me.userId = null;
    me.workplaceId = null;
    me.workspaceId = null;

    setWorkspace(null);
    setWorkspaceId(null);
    setWorkplaceId(null);
    setOrganisation(null);
    setOrganisationId(null);
    setReservationUser(null);
    setReservationUserId(null);
  }

  const handleClose = () => {
    props.onClose();
    props.onRefresh();

    clearSettings();
  }

  const handleUserChange = (user: User) => {

    const me = getMe();
    me.user = user;
    setReservationUser(user);
    setReservationUserId(user.id);
  }

  const handleOrganisationChange = (organisation: Organisation) => {
    const me = getMe();

    if (organisation) {
      me.organisationId = organisation.id
      setOrganisationId(organisation.id);
      setOrganisation(organisation);
      setWorkspace(null);
      setWorkspaceId(props.reservation?.data?.workspaceId);
      setReservationUserId(props.reservation?.createdBy);
      setError('');
      me.user = null;
      me.workspaceId = null;
      me.workplaceId = null;
    }
  }

  const handleWorkspaceChange = async (workspace: Workspace) => {
    const me = getMe();

    setWorkspace(null);
    setWorkplaceId(null)

    await asyncSleep(1);

    me.workspaceId = workspace.id;
    setWorkspace(workspace);
    setWorkspaceId(workspace.id);

    let workplace;

    if (props.reservation?.workplaceId) {
      workplace = workspace.workplaces.find(w => w.id === props.reservation?.workplaceId) || workspace.workplaces[0];
    } else {
      workplace = workspace.workplaces[0];
    }

    if (workplace) {
      handleWorkplaceChange(workplace.id);
    }
  }

  const handleWorkplaceChange = (workplaceId: string) => {
    const me = getMe();
    me.workplaceId = workplaceId;
    setWorkplaceId(me.workplaceId);
  }

  const handleReservationPlaced = () => {
    clearSettings();
    props.onClose();
    props.onRefresh();
  }

  useEffect(() => {
    const me = getMe();

    if (props.reservation) {
      me.organisationId = props.reservation.data.organisationId;
      me.userId = props.reservation.createdBy;
      me.workspaceId = props.reservation.data.workspaceId;
      me.workplaceId = props.reservation.workplaceId;

      setOrganisationId(me.organisationId);
      setReservationUserId(me.userId);
      setWorkspaceId(me.workspaceId);
      setWorkplaceId(me.workplaceId);
    }
  }, [props.reservation]);

  return (
    <Modal maxWidth={'md'} onClose={handleClose} open={props.open} title={props.title} noContentPadding>
      <div className={classes.content}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <OrganisationSelector user={user} onChange={handleOrganisationChange} value={organisationId}/>
          </Grid>
          {organisation && (
            <Grid item xs={12}>
              <UserSelector organisationId={organisationId} onChange={handleUserChange} value={reservationUserId}/>
            </Grid>
          )}
          {organisation && (
            <Grid item xs={12}>
              <WorkspaceSelector
                user={user}
                onChange={handleWorkspaceChange}
                organisationId={organisationId}
                showWorkplaceCount
                value={workspaceId}
              />
            </Grid>
          )}


          {error && (
            <Grid item xs={12}>
              <Title color={'error'}>{error}</Title>
            </Grid>
          )}

          {/*{workplaceId && (*/}
          {/*  <DateTimePicker onFromChange={handleTimeFromChange} onTillChange={handleTimeTillChange} />*/}
          {/*)}*/}

          {workspace && (
            <Grid item xs={12}>
              <PlaceReservationPanel
                reservation={props.reservation}
                userOverride={reservationUser}
                workspaceName={workspace.name}
                organisationName={organisation.name}
                workplaceId={workplaceId}
                onReservationPlaced={handleReservationPlaced}
                onError={(msg) => setError(msg)}
              />
            </Grid>
          )}
        </Grid>
      </div>
    </Modal>
  );
}

export default ReservationModal;

interface ReservationModalProps {
  open: boolean;
  reservation?: Reservation;
  onClose: () => void;
  onRefresh: () => void;
  title: string;
}

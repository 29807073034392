import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Backdrop, Typography} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    zIndex: 1004
  },
  wrapper: {
    maxWidth: '90%',
    maxHeight: '90%',
    position: 'relative'
  },
  title: {
    position: 'absolute',
    left: theme.spacing(1)
  }
}));

export const PreviewBackdrop = (props: PreviewBackdropProps) => {
  const classes = useStyles(props);

  return (
    <Backdrop className={classes.root} open={props.open} onClick={props.onClose}>
      <div className={classes.wrapper}>
        {props.title && (
          <Typography className={classes.title} variant={'h6'}>{props.title}</Typography>
        )}
        {props.children}
      </div>
    </Backdrop>
  );
}

export default PreviewBackdrop;

export interface PreviewBackdropProps {
  children: any;
  title?: string;
  open?: boolean;
  onClose?: () => void;
}

import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Backdrop, ClickAwayListener} from '@material-ui/core';
import {ModalButton} from './Modal';
import DubwebCard, {DubwebCardProps} from '../panels/DubwebCard';
import {theme} from '../../assets/themes/main-theme';

const useStyles = makeStyles(theme => ({
  root: {
    zIndex: 1004
  }
}));

export const DubwebModal = (props: DubwebModalProps) => {
  const classes = useStyles(props);

  return (
    <Backdrop className={classes.root} open={props.open}>
      <DubwebCard
        backgroundColor={theme.palette.common.black}
        border={props.border}
        borderColor={props.borderColor}
        buttons={props.buttons}
        className={props.cardClassName}
        flatBorderTop
        inverted
        loading={props.loading}
        spacing={props.spacing}
        title={props.title}
      >
        {props.children}
      </DubwebCard>
    </Backdrop>
  );
}

export default DubwebModal;

interface DubwebModalProps extends DubwebCardProps {
  buttons?: ModalButton[];
  cardClassName?: string;
  loading?: boolean;
  onClose?: () => void;
  open?: boolean;
}
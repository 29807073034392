import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {FileCollection} from '../../models/File';

const useStyles = makeStyles(theme => ({
  root: {}
}));

export const Img = (props: ImgProps) => {
  const classes = useStyles(props);

  const imgRef = useRef(null);

  const innerProps = {...props};
  delete innerProps.fileName;
  delete innerProps.onLoad;

  useEffect(() => {
    if (props.type === 'thumbnail') {
      FileCollection.fetchThumb(props.fileName, props.format).then(res => {
        if (!imgRef?.current) {
          return;
        }

        imgRef.current.src = res;
        props.onLoad && props.onLoad();
      })
    } else {
      FileCollection.fetch(props.fileName).then(res => {
        if (!imgRef?.current) {
          return;
        }
        imgRef.current.src = res;
        props.onLoad && props.onLoad();
      })
    }
  }, [props.fileName]);

  return <img src={''} alt={'Laden...'} {...innerProps} ref={imgRef}/>;
}

export default Img;

interface ImgProps extends React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
  fileName: string;
  format?: 'jpeg' | 'png';
  type?: 'thumbnail' | 'image';
  onLoad?: () => void;
}

import React, {useContext, useRef, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Typography} from '@material-ui/core';
import {FormInputProps} from '../forms/FormInput';
import Modal, {BaseModalProps, ModalButton} from './Modal';
import FormPanel from '../forms/FormPanel';
import {Feedback} from '../../models/Feedback';
import {Dict} from '../../utils';
import {AppContext} from '../../contexts/AppContext';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  root: {}
}));

export const FeedbackModal = (props: FeedbackModalProps) => {
  const classes = useStyles(props);

  const {user, showNotification} = useContext(AppContext)

  const [loading, setLoading] = useState(false);
  const [formValid, setFormValid] = useState(false);

  const isEditing = props.editingFeedback && props.editingFeedback.id;

  const me = useRef({values: {} as any});
  const getMe = () => me.current;

  /**
   * Handlers
   */
  const handleSaveClick = () => {
    const me = getMe();

    const newFeedback = new Feedback({
      id: props.editingFeedback?.id,
      amountOfDays: me.values.amountOfDays,
      endDate: moment(me.values.startDate).add(me.values.amountOfDays, 'days').toDate(),
      endTime: me.values.endTime,
      organisationId: user.organisationId,
      question: me.values.question,
      repeat: !!me.values.repeat,
      startDate: me.values.startDate,
      startTime: me.values.startTime,
    })

    newFeedback.save().then(res => {
      if (props.onSave) {
        props.onSave();
      }
    }).catch((error) => {
      showNotification('We konden je aanpassingen niet opslaan, probeer het later nog eens.', 'error')
    }).finally(() => {
      handleClose();
    });
  }

  const handleClose = () => {
    const me = getMe();

    me.values = {};

    setFormValid(false);
    setLoading(false);

    if (props.onClose) {
      props.onClose();
    }
  }

  const handleFormValidated = (values: Dict<string>) => {
    const me = getMe();
    me.values = values;

    setFormValid(true);
  }

  const handleFormInvalidated = (values) => {
    const me = getMe();

    setFormValid(false);
  }

  const buttons: ModalButton[] = [
    {
      title: 'Opslaan',
      onClick: handleSaveClick,
      color: 'primary',
      disabled: !formValid || loading,
      loading: loading
    }
  ];
  const inputs: FormInputProps[] = [
    {
      id: 'question',
      label: 'Welke vraag wil je stellen aan de gebruiker?',
      type: 'text',
      required: true,
      defaultValue: props.editingFeedback?.question
    }, {
      id: 'dateSpanInfo',
      label: 'Vanaf wanneer en hoe lang wil je de vraag activeren?',
      type: 'info'
    }, {
      id: 'startDate',
      label: 'Start datum',
      type: 'date',
      required: true,
      gridWidth: 6,
      defaultValue: props.editingFeedback?.startDate || new Date(),
      disabled: !!props.editingFeedback,
      inputProps: {
        disablePast: !props.editingFeedback
      }
    }, {
      id: 'amountOfDays',
      label: 'Aantal dagen',
      type: 'number',
      defaultValue: props.editingFeedback?.amountOfDays || 7,
      required: true,
      gridWidth: 6,
      inputProps: {
        min: props.editingFeedback?.amountOfDays
      },
      validator: (value) => {
        if (props.editingFeedback) {
          return value >= props.editingFeedback.amountOfDays
        }
        return value > 0;
      },
      invalidMessage: !!props.editingFeedback && `Dit veld moet tenminste ${props.editingFeedback.amountOfDays} zijn`
    }, {
      id: 'timeSpanInfo',
      label: 'Tussen welke tijden wil je dat de vraag gesteld wordt?',
      type: 'info'
    }, {
      id: 'startTime',
      label: 'Begintijd',
      type: 'time',
      required: true,
      gridWidth: 6,
      defaultValue: props.editingFeedback?.startTime || '08:00'
    }, {
      id: 'endTime',
      label: 'Eindtijd',
      type: 'time',
      required: true,
      gridWidth: 6,
      defaultValue: props.editingFeedback?.endTime || '18:00'
    }, {
      id: 'otherOptionsInfo',
      label: 'Overige opties',
      type: 'info'
    }, {
      id: 'repeat',
      label: 'Herhaal de vraag dagelijks',
      type: 'checkbox',
      inputProps: {
        size: 'small'
      },
      defaultValue: props.editingFeedback?.repeat
    }
  ];

  return (
    <Modal open={props.open} onClose={handleClose} title={props.title} buttons={buttons} loading={loading}>
      <div className={classes.root}>
        <Typography gutterBottom variant={'body2'}>Kies een bestaande vraag of vul een nieuwe in. Kies vervolgens een
          datum en een
          tijdsblok wanneer je
          wilt dat de vraag gesteld wordt.</Typography>

        <FormPanel
          active={props.open}
          inputs={inputs}
          onFormValidated={handleFormValidated}
          onFormInvalidated={handleFormInvalidated}
          spacing={2}
          timeTilValidate={1}
          fillErrorSpacing/>
      </div>
    </Modal>
  );
}

export default FeedbackModal;

interface FeedbackModalProps extends BaseModalProps {
  editingFeedback?: Feedback;
  onSave?: () => void;
}
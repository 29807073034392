import {useLocation} from 'react-router-dom';

export function isFunction(functionToCheck) {
  return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
}

export function mapToObj(arr: any[], mapFn: (item: any) => any) {
  return arr.reduce(function (result, item) {
    const [key, value] = mapFn(item);
    result[key] = value;
    return result;
  }, {});
}

export type Dict<T> = { [key: string]: T };

/**
 * Compare two arrays and check if they are identical.
 * @param {any[]} arr1
 * @param {any[]} arr2
 * @returns {boolean}
 */
export function arrEquals(arr1: any[], arr2: any[]): boolean {
  if (!arr1 || !arr2 || arr1.length !== arr2.length) {
    return false;
  }
  let equalsResult = true;
  for (const [idx, val] of arr1.entries()) {
    if (val !== arr2[idx]) {
      equalsResult = false;
    }
  }
  return equalsResult;
}

export const setObjRecursive = (object: any, path: string, value: string | boolean | number) => {
  const pathList = path.split('.');
  const pathListLength = pathList.length
  for (let i = 0; i < pathListLength - 1; i++) {
    const subObject = pathList[i];
    if (!object[subObject]) {
      object[subObject] = {};
    }
    object = object[subObject];
  }
  object[pathList[pathListLength - 1]] = value;
}

export const isMobileDevice = (): boolean => {
  if (/android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(navigator.userAgent)) {
    return true;
  }
}

export const titleCase = (text: string): string => {
  try {
    return text[0].toUpperCase() + text.slice(1);
  } catch {
    return text;
  }
}

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export async function asyncSleep(milliseconds: number) {
  return new Promise(r => setTimeout(r, milliseconds));
}

/**
 * Generate an objectId with length of 24 characters
 * @returns {string}
 */
export function newObjectId() {
  const hexCharacters = 'abcdef0123456789';
  let value = '';

  for (let i = 0; i < 24; i++) {
    value += hexCharacters[Math.floor(Math.random() * hexCharacters.length)]
  }

  return value;
}
import React from 'react';
import {Button, makeStyles} from '@material-ui/core';
import TablePagination from '@material-ui/core/TablePagination';
import {FaPlus} from 'react-icons/all';
import {ModalButton} from '../modals/Modal';
import Loader from '../panels/Loader';
import LoaderButton from '../snippets/LoaderButton';
import Grow from '../snippets/Grow';

const useStyles = makeStyles(theme => ({
  root: {
    alignItems: 'center',
    bottom: 0,
    display: 'flex',
    justifyContent: 'space-between',
    height: 52,
    paddingLeft: 12,
    position: 'absolute',
    width: '100%'
  },
  pagination: {},
  addBtn: {
    color: theme.palette.common.black
  },
  btn: {},
  actions: {
    height: '100%',
    float: 'right',
    '& > *': {
      marginLeft: theme.spacing(2),
      display: 'block'
    }
  },
}));

export const TableFooter = (props: TableFooterProps) => {
  const classes = useStyles(props);

  return (
    <div className={classes.root}>
      {props.addBtnLabel && (
        <Button
          onClick={props.addBtnClicked}
          variant={'contained'}
          color={'primary'}
          className={classes.addBtn}
          startIcon={<FaPlus/>}>{props.addBtnLabel}</Button>
      )}
      <Grow/>
      <TablePagination
        className={props.className}
        rowsPerPageOptions={[]}
        component={'div'}
        count={props.rows.length}
        rowsPerPage={props.rowsPerPage}
        page={props.page}
        onChangePage={props.onPageChange}
        onChangeRowsPerPage={props.onRowsPerPageChange}
      />
      {props.buttons && props.buttons.map(btn => (
        <LoaderButton
          key={'modal-btn-' + btn.title}
          variant={btn.variant || 'contained'}
          color={btn.color}
          onClick={btn.onClick}
          disabled={btn.disabled || props.loading}
          loading={btn.loading}
        >{btn.title}</LoaderButton>
      ))}
    </div>
  );
};

export default TableFooter;

interface TableFooterProps {
  addBtnClicked?: () => void;
  addBtnLabel?: string;
  buttons?: ModalButton[];
  className?: string;
  rows: any[];
  page: number;
  rowsPerPage: number;
  loading: boolean;
  disableRowsPerPage?: boolean;
  onPageChange: (event, newPage) => void;
  onRowsPerPageChange: (event) => void;
}

import {BaseObject} from './core/BaseObject';
import Model from './core/Model';
import Collection from './core/Collection';
import {apiGet, apiGetAllWithCount, apiPostAny, FindWithCountResult, getRowsById} from './core/ApiCalls';
import moment from 'moment';
import {FeedbackEmotion, FeedbackEmotionId} from '../components/snippets/EmotionPicker';
import {newObjectId} from '../utils';

const route = '/feedback';

export interface FeedbackAnswer extends BaseObject {
  feedbackId: string;
  organisationId: string;
  userId: string;
  emotion: FeedbackEmotionId;
  score?: number;
  user?: string;
  note?: string;
}

export interface FeedbackStats {
  answerCount?: number;
  uniqueUserCount?: number;
  noteCount?: number;
}

export interface FeedbackData extends BaseObject {
  question: string;
  organisationId: string;
  startDate: Date;
  endDate: Date;
  startTime: string; // 08:00
  endTime: string; // 20:00
  amountOfDays: number;
  repeat?: boolean;
  answersCount?: number;
  stats?: FeedbackStats;
  answers?: FeedbackAnswer[]
}

export class Feedback extends Model<FeedbackData, FeedbackCollectionClass> {
  public collection = FeedbackCollection;
  privateFinds = true;

  get repeat() {
    return this.data.repeat;
  }

  get startDate() {
    return this.data.startDate;
  }

  get endDate() {
    return this.data.endDate;
  }

  get startTime() {
    return this.data.startTime;
  }

  get endTime() {
    return this.data.endTime;
  }

  get question() {
    return this.data.question;
  }

  get stats() {
    return this.data.stats || {} as FeedbackStats;
  }

  get amountOfDays() {
    return this.data.amountOfDays;
  }

  get answers() {
    return this.data.answers;
  }

  get answersCount() {
    return this.data.answers?.length;
  }
}

export class FeedbackCollectionClass extends Collection<FeedbackData, Feedback> {
  public route: string = route;
  public privateFinds = true;
  public cachedFinds = false;

  async submitFeedbackAnswer(answer: FeedbackAnswer): Promise<boolean> {
    return apiPostAny(this.url + this.route +  '/submit', answer).then(res => !!res);
  }

  async findActiveFeedback(params: any = {}): Promise<Feedback> {
    return apiGet(this.url + this.route + '/active', this.createModel);
  }

  async findFeedbackById(id: string): Promise<Feedback> {
    return apiGet(this.url + this.route + `/${id}`, this.createModel);
  }
}

export const FeedbackCollection = new FeedbackCollectionClass(Feedback);
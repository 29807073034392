import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Modal, {ModalButton} from './Modal';
import PlaceReservationPanel from '../panels/PlaceReservationPanel';

const useStyles = makeStyles({});

const PlaceReservationModal = function (props: PlaceReservationModalProps) {
  const classes = useStyles();

  const [error, setError] = useState('');

  const handleClose = () => {
    props.onClose();
  };

  const handleRefresh = () => {
    props.onRefresh();
    handleClose();
  }

  return (
    <Modal
      open={props.open}
      onClose={handleClose}
      title={props.title}
      maxWidth={'md'}
      noContentPadding
    >
      <PlaceReservationPanel
        workplaceId={props.workplaceId}
        onReservationPlaced={handleRefresh}
        onError={(msg) => setError(msg)}
      />
    </Modal>
  );
};

interface PlaceReservationModalProps {
  workplaceId: string;
  open: boolean;
  onClose: (filesAdded?: boolean) => void;
  onRefresh: () => void;
  title: string;
}

export default PlaceReservationModal;

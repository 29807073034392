import React, {useContext} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {FaBars} from 'react-icons/all';
import DubwebMenu, {DubwebMenuItem} from './DubwebMenu';
import {AppContext} from '../../contexts/AppContext';
import {FaSignOutAlt} from 'react-icons/fa/index';
import {useHistory} from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {}
}));

export const HomeMenu = (props: HomeMenuProps) => {
  const classes = useStyles(props);

  const history = useHistory();

  const {logout, user} = useContext(AppContext);

  let items: DubwebMenuItem[] = [
    {
      text: 'Uitloggen',
      onClick: () => logout(),
    }
  ]

  if (user?.level === 1) {
    items.splice(0, 0, {
      text: 'Admin paneel',
      onClick: () => history.push('/admin/reservations')
    })
  }

  return (
    <DubwebMenu
      icon={FaBars}
      items={items}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
    />
  )
}

export default HomeMenu;

interface HomeMenuProps {

}

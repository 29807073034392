import React from 'react';
import {makeStyles} from '@material-ui/core';
import ProfileButton from '../navbar/ProfileButton';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    width: '100%',
    height: 60,
    top: 0,
    left: 0,
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  left: {
    // padding: theme.spacing(3, 4),
    height: '100%',
    width: 200,
    display: 'flex',
    // justifyContent: 'space-around'
  },
  right: {
    position: 'absolute',
    height: 60,
    right: 0,
    top: 0,
  },
  logo: {
    height: '100%',
    paddingTop: theme.spacing(2),
    margin: 'auto'
  }
}));

export const Navbar = (props: NavbarProps) => {
  const classes = useStyles(props);

  return (
    <div className={classes.root}>

      <div className={classes.left}>
        <img src={'/images/servicepunt.png'} className={classes.logo} />
      </div>

      <div className={classes.right}>
        <ProfileButton />
      </div>
    </div>
  );
};

export default Navbar;

interface NavbarProps {

}

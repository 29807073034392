import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Button, Paper, Typography} from '@material-ui/core';
import {RowAction} from '../table/BaseTable';
import {FaCopy, FaTrash} from 'react-icons/fa/index';
import ActionsOverlay from './ActionsOverlay';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
  }
}));

export const CanvasPaper = (props: CanvasPaperProps) => {
  const classes = useStyles(props);

  return (
    <Paper className={classes.root}>
      {props.children}
      {props.rowActions && <ActionsOverlay rowActions={props.rowActions} />}
    </Paper>
  )
}

export default CanvasPaper;

interface CanvasPaperProps {
  rowActions?: RowAction<any>[];
  children: any;
}

import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {InputLabel, ListSubheader, MenuItem, Select, Typography} from '@material-ui/core';
import TableRowAction from '../table/TableRowAction';
import {RowAction} from '../table/BaseTable';
import Grow from '../snippets/Grow';

const useStyles = makeStyles(theme => ({
  root: {},
  optionRowActions: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around'
  },
  label: {
    fontSize: 13
  }
}));

export const FormInputSelect = (props: FormInputSelectProps) => {
  const classes = useStyles(props);

  const {value, onChange, label, className, required, color, disabled} = props.inputProps;

  const handleChange = (e) => {
    const tag = e.nativeEvent.target.tagName;

    /** If tagName is 'svg' or 'path', then we know it's a
     * RowAction, so we prevent the default onChange event */
    if (tag == 'svg' || tag == 'path') {
      e.preventDefault();
      return;
    }

    onChange(e);
  }

  return (
    <>
      {label && <InputLabel id={props.id} >{label}</InputLabel>}
      <Select
        labelId={props.id}
        value={value}
        onChange={handleChange}
        label={label}
        className={className}
        required={required}
        disabled={disabled}
        color={color}
        multiple={props.multiple}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          },
          getContentAnchorEl: null,
        }}
        renderValue={(val) => {
          return Array.isArray(val) ? val.map(v => props.options.find(o => o.value == v)?.label || '').join(', ') : props.options.find(option => option.value == val)?.label || '';
        }}
      >
        {props.options.map((option) => {

          if (option.type === 'subheader') {
            return (
              <ListSubheader key={option.label}>{option.label}</ListSubheader>
            )
          }

          return (
            <MenuItem key={option.value} value={option.value} disabled={option.disabled}>
              <div style={{display: 'flex', width: '100%'}}>
                <div className={classes.label}>
                  {option.label}
                </div>
                {props.rowActions && (
                  <>
                    <Grow />
                    <div className={classes.optionRowActions}>
                      <div>
                        {props.rowActions.map((rowAction, index) => (
                          <TableRowAction key={index} row={option.value} {...rowAction} />))}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </MenuItem>
          )
        })}
      </Select>
    </>
  );
}

export default FormInputSelect;

interface FormInputSelectProps {
  id: string;
  inputProps: any;
  disabled?: boolean;
  multiple?: boolean;
  options?: InputOption[];
  rowActions?: RowAction<string>[];
}

export interface InputOption {
  label: any;
  value: any;
  disabled?: boolean;
  type?: 'item' | 'subheader';
}


import React, {useRef, useState} from 'react';
import {Button, makeStyles} from '@material-ui/core';
import {TableColumn} from '../table/TableHeader';
import {RowAction} from '../table/BaseTable';
import {UserCollection, User} from '../../models/User';
import DynamicTable from '../table/DynamicTable';
import {FaEnvelope, FaUserCheck, FaUserTimes} from 'react-icons/fa/index';
import {useHistory} from 'react-router-dom';
import Loader from '../panels/Loader';
import {Organisation, OrganisationCollection} from '../../models/Organisation';
import {Reservation} from '../../models/Reservation';
import {FaBan, FaPen} from 'react-icons/all';
import {ToolbarAction} from '../table/TableToolbarAction';
import {FaTrash} from 'react-icons/fa';

const useStyles = makeStyles(theme => ({
  root: {}
}));

export const OrganisationTable = (props: OrganisationTableProps) => {
  const classes = useStyles(props);

  const columns: TableColumn[] = [
    {id: 'name', label: 'Naam'},
    {id: 'active', label: 'Actief'},
    {id: 'updatedAt', label: 'Aangepast', type: 'dateTime'},
    {id: 'createdAt', label: 'Aangemaakt', type: 'dateTime'}
  ];

  return (
    <div className={classes.root}>
      <DynamicTable
        onAddBtnClick={props.onAddBtnClick}
        addBtnLabel={'Nieuwe organisatie toevoegen'}
        columns={columns}
        collection={OrganisationCollection}
        title={'Organisaties'}
        ref={props.tableRef}
        rowActions={props.rowActions}
        onLoad={props.onLoad}
        disablePadding
      />
    </div>
  );
};

export default OrganisationTable;

interface OrganisationTableProps {
  onAddBtnClick?: () => void;
  rowActions?: RowAction<Organisation>[];
  onLoad?: () => void;
  onRefresh?: () => void;
  tableRef?: any;
}

import React, {useContext, useEffect, useLayoutEffect, useState} from 'react';
import {makeStyles, Typography} from '@material-ui/core';
import Modal, {ModalButton} from './Modal';
import {validateEmail} from '../../utils/validateEmail';
import {UserCollection} from '../../models/User';
import FormInput from '../forms/FormInput';
import {Organisation, OrganisationCollection} from '../../models/Organisation';
import {InputOption} from '../forms/FormInputSelect';
import Loader from '../panels/Loader';
import {AppContext} from '../../contexts/AppContext';
import OrganisationSelector from '../snippets/OrganisationSelector';

const useStyles = makeStyles(theme => ({
  root: {
    height: 250,
    '& > *': {
      paddingBottom: theme.spacing(2)
    }
  }
}));

export const AddUserModal = (props: AddUserModalProps) => {
  const classes = useStyles(props);

  const {user} = useContext(AppContext);

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState<string>('');
  const [organisation, setOrganisation] = useState<string>();
  const [errorMessage, setErrorMessage] = useState('');

  const handleSendInvitationClick = () => {
    setErrorMessage('');
    setLoading(true);

    const emails = email.replace(/( +)/g, '').split(',');

    for (const email of emails) {
      if (!validateEmail(email)) {
        setErrorMessage(`Het volgende email-adres is incorrect: ` + email);
        setLoading(false);
        return;
      }
    }

    UserCollection.invite(emails, organisation).then((res) => {
      props.onInvitationSend(emails);
      props.onClose();
    }).catch((error) => {
      if (error?.response?.status === 409) {
        setErrorMessage('Gebruiker met dit email adres bestaat al.');
      }
    }).finally(() => {
      setLoading(false);
    })
  }

  const buttons: ModalButton[] = [
    {
      title: 'Uitnodiging versturen',
      onClick: handleSendInvitationClick,
      disabled: loading || !validateEmail(email),
      color: 'primary'
      // disabled: !validateEmail(email)
    }
  ]

  const handleOrgChange = (organisation: Organisation) => {
    setOrganisation(organisation.id);

    user.preferences = {
      organisations: {
        preferredOrganisationId: organisation.id
      }
    }

    user.savePreferences();
  }

  const handleLoad = () => {
    setLoading(false);
  }

  return (
    <Modal open={props.open} onClose={props.onClose} title={props.title} buttons={buttons} maxWidth={'sm'}>
      <div className={classes.root}>
        <Typography>Vul het e-mail adres in van de persoon die je wilt uitnodigen. Klik vervolgens op
          uitnodiging versturen. Je kan ook meerdere e-mail adressen toevoegen door een komma "<strong>,</strong>" te
          plaatsen tussen elk adres.</Typography>

        <FormInput
          id={'email'}
          label={'Email'}
          placeholder={'voorbeeld@live.nl, nog-een-voorbeeld@gmail.com'}
          onChange={setEmail}
          invalidMessage={errorMessage}
          validator={val => !errorMessage}
          initialValidate
        />
        <OrganisationSelector
          user={user}
          onChange={handleOrgChange}
          onLoad={handleLoad}
        />
      </div>
      <Loader loading={loading}/>
    </Modal>
  );
};

export default AddUserModal;

interface AddUserModalProps {
  open: boolean;
  onClose: () => void;
  onInvitationSend: (emails: string[]) => void;
  title: string;
}

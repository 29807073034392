import React, {useEffect, useState} from 'react';
import {Grid, makeStyles, Typography} from '@material-ui/core';
import BackgroundPanel from '../components/panels/BackgroundPanel';
import Title from '../components/snippets/Title';
import HomeMenu from '../components/menus/HomeMenu';
import DubwebCard from '../components/panels/DubwebCard';
import {theme} from '../assets/themes/main-theme';
import ActivityMenu from '../components/menus/ActivityMenu';
import {useHistory, useParams} from 'react-router-dom';
import {Workspace, WorkspaceCollection} from '../models/Workspace';
import Img from '../components/snippets/Img';
import {Workplace} from '../models/Workplace';
import PlaceReservationPanel from '../components/panels/PlaceReservationPanel';

const useStyles = makeStyles(theme => ({
  root: {},
  container: {
    // overflow: 'auto'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  logo: {
    height: '3rem',
    display: 'block',
    zIndex: 10003,
    '&:hover': {
      cursor: 'pointer'
    }
  },
}));

type WorkspaceReserveParams = {
  organisation: string;
  workspace: string;
  workplace?: string;
}

export const WorkspaceReserve = (props: WorkspaceReserveProps) => {
  const classes = useStyles(props);

  const history = useHistory();
  const {organisation, workspace, workplace} = useParams<WorkspaceReserveParams>();

  const [error, setError] = useState('');

  const handleReservationPlaced = (reservation) => {
    history.push('/?success=' + reservation?.id)
  }

  const handleHomeClick = () => {
    history.push('/')
  }

  return (
    <BackgroundPanel paddingTop={20}>
      <Grid container justify={'space-around'} style={{overflow: 'auto'}}>
        <Grid item xs={12} md={10} lg={10} xl={6}>
          <Grid
            className={classes.container}
            container
            justify={'space-around'}
            spacing={3}
            alignContent={'flex-start'}
          >

            <Grid item xs={11} md={10} lg={10} xl={7} className={classes.header}>
              <img src={'/images/servicepunt.png'} className={classes.logo} onClick={handleHomeClick} />
              <HomeMenu />
            </Grid>

            <Grid item xs={11} md={10} lg={10} xl={7} className={classes.header}>
              <Title>Waar wil je zitten?</Title>
            </Grid>

            {error && (
              <Grid item xs={11} md={10} lg={10} xl={7}>
                <Title color={'textSecondary'}>{error}</Title>
              </Grid>
            )}

            <Grid item xs={11} md={10} lg={10} xl={7}>
              {!error && (
                <PlaceReservationPanel
                  organisationName={organisation}
                  workspaceName={workspace}
                  workplaceName={workplace}
                  onReservationPlaced={handleReservationPlaced}
                  onError={(msg) => setError(msg)}
                />
              )}
            </Grid>

          </Grid>
        </Grid>
      </Grid>
    </BackgroundPanel>
  );
};

export default WorkspaceReserve;

interface WorkspaceReserveProps {

}

import React, {useContext, useEffect, useRef, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Modal, {ModalButton} from './Modal';
import FormInput from '../forms/FormInput';
import {Button, Typography} from '@material-ui/core';
import {FileCollection} from '../../models/File';
import Alert from '../snippets/Alert';
import OrganisationSelector from '../snippets/OrganisationSelector';
import {AppContext} from '../../contexts/AppContext';
import {Organisation} from '../../models/Organisation';

const useStyles = makeStyles(theme => ({
  root: {
    height: 300
  },
  fileInput: {
    position: 'fixed',
    height: 0,
    width: 0,
    opacity: 0,
    top: -1000,
  },
  preview: {
    marginTop: theme.spacing(2),
    width: '100%'
  },
  img: {
    maxWidth: '100%',
    maxHeight: 200,
  },
  selector: {
    marginBottom: theme.spacing(2)
  }
}));

export const UploadFileModal = (props: UploadFileModalProps) => {
  const classes = useStyles(props);
  const acceptedFileTypes = ['.png', '.jpeg', '.gif', 'image/png', 'image/jpeg', 'image/gif']
  const maxSize = 16 * 1048576;

  const {user} = useContext(AppContext);

  const [file, setFile] = useState<any>();
  const [filePreview, setFilePreview] = useState<any>();
  const [fileName, setFileName] = useState<string>();
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [organisation, setOrganisation] = useState<string>();

  const fileInput = useRef(null);

  const handleFileInput = (e) => {
    const file = e.target.files[0];

    if (!file) {
      return;
    }

    if (file.size > maxSize) {
      setError('Het gekozen bestand is te groot. Het bestand mag niet groter zijn dan 16MB.');
      return;
    }

    setError(null);
    setFile(file);
    setFilePreview(URL.createObjectURL(file))
    setFileName(file.name);
  }

  const uploadFile = () => {
    const formData = new FormData();

    // const extension = file.name.split('.').pop();
    // const name = fileName.endsWith(extension) ? fileName : fileName.replace('.', '') + `.${extension}`;

    setLoading(true);

    formData.append('file', file, fileName);

    FileCollection.upload(formData, organisation).then(res => {
      props.onClose();
      props.onRefresh();
      clearSettings();
    }).catch(error_ => {
      setError('Er is iets fout gegaan, probeer het later nog eens.');
      console.log(error_);
    }).finally(() => {
      setLoading(false);
    })
  }

  const handleFileUploadBtnClick = () => {
    if (fileInput.current) {
      fileInput.current.click();
    }
  }

  const buttons: ModalButton[] = [
    {
      title: 'Kies bestand',
      onClick: handleFileUploadBtnClick,
      variant: 'text',
      disabled: loading
    }, {
      title: 'Upload bestand',
      onClick: uploadFile,
      disabled: !file || loading,
      color: 'primary',
      loading: loading
    }
  ];

  const clearSettings = () => {
    setFile(null);
    setFilePreview(null);
    setFileName(null);
    setError(null);
    setLoading(false);
  }

  const handleFileNameChange = (val) => {
    setFileName(val);
  }

  const handleOrgChange = (organisation: Organisation) => {
    setOrganisation(organisation.id);
  }

  const handleLoad = () => {
    setLoading(false);
  }

  return (
    <Modal open={props.open} onClose={props.onClose} title={props.title} buttons={buttons}>
      <div className={classes.root}>

        {file ? (
          <FormInput
            id={'file-name'}
            defaultValue={fileName}
            onChange={handleFileNameChange}
            label={'Bestandsnaam'}
            className={classes.selector}
          />
        ) : (
          <Typography gutterBottom>{'Kies een bestand door op "Kies bestand" te klikken.'}</Typography>
        )}

        <input
          type="file"
          onChange={handleFileInput}
          className={classes.fileInput}
          ref={fileInput}
          accept={acceptedFileTypes.join(',')}

        />

        <OrganisationSelector user={user} onChange={handleOrgChange} onLoad={handleLoad} />

        <div className={classes.preview}>
          {error && <Alert severity={'warning'} message={error} />}
          {filePreview && <img src={filePreview} className={classes.img} alt={'Upload image'} />}
        </div>
      </div>
    </Modal>
  );
}

export default UploadFileModal;

interface UploadFileModalProps {
  open: boolean;
  onClose: () => void;
  onRefresh: () => void;
  title: string;
}

import React, {useContext, useRef, useState} from 'react';
import {Button, makeStyles, Typography} from '@material-ui/core';
import {TableColumn} from '../table/TableHeader';
import {ResultFilter, RowAction} from '../table/BaseTable';
import {UserCollection, User} from '../../models/User';
import DynamicTable from '../table/DynamicTable';
import {FaEnvelope, FaEye, FaFilter, FaUserCheck, FaUserTimes} from 'react-icons/fa/index';
import {useHistory} from 'react-router-dom';
import Loader from '../panels/Loader';
import {AppContext} from '../../contexts/AppContext';
import {ToolbarAction} from '../table/TableToolbarAction';
import {FileCollection} from '../../models/File';
import {Img} from '../snippets/Img';

const useStyles = makeStyles(theme => ({
  root: {}
}));

export const FileTable = (props: FileTableProps) => {
  const classes = useStyles(props);

  const [filters, setFilters] = useState<ResultFilter<File>[]>([])

  const history = useHistory();

  const {user, showPreviewBackdrop} = useContext(AppContext);

  const columns: TableColumn[] = [
    // {id: 'uuid', label: 'UUID'},
    {id: 'originalName', label: 'Bestandsnaam'},
    {id: 'organisation', label: 'Organisatie', filterable: true},
    {id: 'mimeType', label: 'Type', filterable: true},
  ];

  const handlePreviewClick = (row: any) => {
    showPreviewBackdrop({
      children: <Img fileName={row.id} style={{maxWidth: '100%'}}/>,
      title: row.originalName
    })
  }

  const rowActions: RowAction<File>[] = [
    {
      label: () => 'Open afbeelding',
      icon: <FaEye />,
      onClick: handlePreviewClick
    }
  ];

  const toolbarActions: ToolbarAction<File[]>[] = []

  const [loading, setLoading] = useState(false);

  const handleFiltersChange = (filters: ResultFilter<File>[]) => {
    setFilters(filters);
  }

  return (
    <div className={classes.root}>

      <DynamicTable
        onAddBtnClick={props.onAddBtnClick}
        addBtnLabel={'Bestand toevoegen'}
        columns={columns}
        collection={FileCollection}
        title={'Bestanden'}
        ref={props.tableRef}
        rowActions={rowActions}
        onLoad={props.onLoad}
        disablePadding
        toolbarActions={toolbarActions}
        filters={filters}
        onFiltersChange={handleFiltersChange}
      />
      <Loader loading={loading} />
    </div>
  );
};

export default FileTable;

interface FileTableProps {
  onAddBtnClick?: () => void;
  onLoad?: () => void;
  onRefresh?: () => void;
  tableRef?: any;
}

import React, {useContext, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {AppContext} from '../contexts/AppContext';

const useStyles = makeStyles(theme => ({
  root: {}
}));

export const Logout = (props: LogoutProps) => {
  const classes = useStyles(props);

  const {logout} = useContext(AppContext);

  useEffect(() => {
    logout();
  }, [])

  return null;
}

export default Logout;

interface LogoutProps {

}
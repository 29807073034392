import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import BackgroundPanel from '../components/panels/BackgroundPanel';
import Title from '../components/snippets/Title';
import {Grid} from '@material-ui/core';
import HomeMenu from '../components/menus/HomeMenu';
import {useHistory} from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    marginTop: 20
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  logo: {
    height: '3rem',
    display: 'block',
    zIndex: 10003,
    '&:hover': {
      cursor: 'pointer'
    }
  },
  message: {
    marginTop: '20%',
    width: '100%',

    display: 'flex',
    justifyContent: 'space-around',
  }
}));

export const PageNotFound = (props: PageNotFoundProps) => {
  const classes = useStyles(props);

  const history = useHistory();

  const handleHomeClick = () => {
    history.push('/')
  }

  return (
    <BackgroundPanel>
      <Grid
        className={classes.root}
        container
        justify={'space-around'}
        spacing={3}
        alignContent={'flex-start'}
      >
        <Grid item xs={11} md={10} lg={8} xl={7} className={classes.header}>
          <img src={'/images/servicepunt.png'} className={classes.logo} onClick={handleHomeClick} />
        </Grid>
        <Grid item xs={11} md={10} lg={8} xl={7} className={classes.header}>
          <div className={classes.message}>
            <Title>Helaas, we konden niet vinden wat je zocht</Title>
          </div>
        </Grid>
      </Grid>
    </BackgroundPanel>
  );
}

export default PageNotFound;

interface PageNotFoundProps {

}

import React, {useContext, useEffect, useRef, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Modal, {ModalButton} from './Modal';
import FormInput from '../forms/FormInput';
import {Grid, Paper, Typography} from '@material-ui/core';
import CanvasPanel from '../panels/CanvasPanel';
import {Workspace, WorkspaceCollection} from '../../models/Workspace';
import {CanvasElement, CanvasItem} from '../snippets/CanvasElement';
import CanvasPaper from '../snippets/CanvasPaper';
import FilePicker from '../snippets/FilePicker';
import CanvasSelected from '../snippets/CanvasSelected';
import {RowAction} from '../table/BaseTable';
import {FaPlus} from 'react-icons/fa/index';
import {WorkplaceType, WorkplaceTypeCollection} from '../../models/WorkplaceType';
import {AppContext} from '../../contexts/AppContext';
import AddWorkplaceTypeModal from './AddWorkplaceTypeModal';
import OrganisationSelector from '../snippets/OrganisationSelector';
import moment from 'moment';
import {Organisation} from '../../models/Organisation';
import {Prompt} from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    height: 600
  },
  fileInput: {
    position: 'fixed',
    height: 0,
    width: 0,
    opacity: 0,
    top: -1000,
  },
  preview: {
    width: '100%'
  },
  img: {
    maxWidth: '100%',
    maxHeight: 200,
    marginTop: theme.spacing(2)
  },
  leftPanel: {
    // boxSizing: 'content-box',
    width: '100%',
    height: `calc(100% - 50px)`,
    // border: `1px solid ${theme.palette.secondary.light}`
  },
  rightPanel: {
    backgroundColor: '#EEEEEEA0',
    borderLeft: `1px solid ${theme.palette.secondary.light}`,
    padding: theme.spacing(4),
    // height: 'calc(100% - 50px)',
    height: '100%',
    width: '100%',
    zIndex: 3
  },
  elements: {
    width: '100%',
    display: 'flex',
    overflowX: 'auto'
  },
  paper: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  backgroundPicker: {
    height: '100%',
    overflow: 'auto'
  },
}));

export const WorkspaceModal = (props: WorkSpaceModalProps) => {
  const classes = useStyles(props);

  const {user, showConfirmationModal} = useContext(AppContext);

  const [workspaceName, setWorkspaceName] = useState(props.editingWorkspace?.name || 'Werkruimte');
  const [selectedCanvasElements, setSelectedCanvasElements] = useState([]);
  const [editingWorkspace, setEditingWorkspace] = useState(props.editingWorkspace);
  const [addWorkplaceTypeOpen, setAddWorkplaceTypeOpen] = useState(false);
  const [editorEnabled, setEditorEnabled] = useState(false);
  const [saving, setSaving] = useState(false);
  const [canvasElements, setCanvasElements] = useState<CanvasItem[]>([]);
  const [organisation, setOrganisation] = useState<string>(props.editingWorkspace?.organisationId);

  const imageRef = useRef(null);
  const rootRef = useRef(null);
  const canvasRef = useRef(null);
  const me = useRef({
    background: null,
    addWorkplaceTypeName: null,
    addWorkplaceTypeFileId: null,
    organisationId: props.editingWorkspace?.organisationId
  });
  const getMe = () => me.current;

  const clearSettings = () => {
    const me = getMe();

    me.background = null;
    me.organisationId = null;

    setEditorEnabled(false);
    setEditingWorkspace(null);
    setWorkspaceName('Werkruimte')
  }

  const handleSave = () => {
    const me = getMe();
    const canvasJson = canvasRef.current.exportCanvas();
    const newWorkspace: any = editingWorkspace ? {...editingWorkspace.data} : {};

    setSaving(true);

    newWorkspace.name = workspaceName;
    newWorkspace.data = canvasJson;
    newWorkspace.organisationId = me.organisationId
    newWorkspace.backgroundFileId = me.background?.id || newWorkspace.backgroundFileId;

    WorkspaceCollection.put(newWorkspace).then(res => {
      setEditingWorkspace(res);
      setSaving(false);

      // props.onRefresh && props.onRefresh();
    })

  }

  const addWorkplace = (imgId: string, title: string, typeId: string) => {
    const canvas = canvasRef.current;

    if (canvas) {
      canvas.addImage(imgId, title, typeId);
    }
  }

  const handleElementsRemove = () => {
    const canvas = canvasRef.current;

    if (canvas) {
      setSelectedCanvasElements([]);
      canvas.removeSelectedObject();
    }
  }

  const handleElementsDuplicate = () => {
    const canvas = canvasRef.current;

    if (canvas) {
      canvas.duplicate();
    }
  }

  const handleFlexChange = (value) => {
    const canvas = canvasRef.current;

    if (canvas) {
      canvas.updateSelectedObject({
        flex: Boolean(value)
      })
    }
  }

  const handleNotesChange = (value) => {
    const canvas = canvasRef.current;

    if (canvas) {
      canvas.updateSelectedObject({
        notes: value
      })
    }
  }

  const handlePrefixChange = (value) => {
    const canvas = canvasRef.current;

    if (canvas) {
      canvas.updateSelectedObject({
        prefix: `${value}`
      })
    }
  }

  const handleElementNumberChange = (value) => {
    const canvas = canvasRef.current;

    if (canvas) {
      canvas.updateSelectedObject({
        id: Number.parseInt(value)
      })
    }
  }

  const handleUndo = () => {
    const canvas = canvasRef.current;

    if (canvas) {
      canvas.undo();
    }
  }

  const buttons: ModalButton[] = [
    {
      title: 'Ongedaan maken',
      onClick: handleUndo,
      color: 'default',
      disabled: !editorEnabled
    },
    {
      title: 'Opslaan',
      onClick: handleSave,
      color: 'primary',
      disabled: !editorEnabled,
      loading: saving
    }
  ];

  const handleSelectionChange = (selection) => {
    if (!Array.isArray(selection)) {
      console.error('Selection must be of type array.');
      return;
    }

    if (selection.length > 0) {
      setSelectedCanvasElements([...selection])
    } else {
      setSelectedCanvasElements([]);
    }
  }

  const editingWorkspaceDependency = props.editingWorkspace ? JSON.stringify(props.editingWorkspace) : undefined;

  const handleActiveBackgroundChange = (file: any) => {
    const me = getMe();

    me.background = file;

    setEditorEnabled(true);
  }

  const handleClose = () => {
    clearSettings();
    props.onRefresh && props.onRefresh();
    props.onClose();
  }

  const handleNewCanvasElementClick = () => {
    /** Show FilePicker popup */
    setAddWorkplaceTypeOpen(true);
  }

  const canvasElementsRowActions: RowAction<any>[] = [
    {
      icon: FaPlus,
      label: 'Nieuw item aanmaken',
      onClick: () => handleNewCanvasElementClick(),
    }
  ]

  const handleDeleteWorkplaceType = (row: WorkplaceType) => {
    row.delete().then(() => handleWorkplaceTypeRefresh());
  }

  const handleWorkplaceTypeRefresh = () => {
    const me = getMe();
    WorkplaceTypeCollection.findAllWithCount({organisationId: me.organisationId}).then(res => {
      setCanvasElements(res.rows.map((row) => ({
        fileId: row.fileId,
        name: row.title,
        onClick: () => addWorkplace(row.fileId, row.title, row.id),
        onDelete: () => handleDeleteWorkplaceType(row)
      })))
    })
  }

  const handleOrganisationChange = (organisation: Organisation) => {
    const me = getMe();
    me.organisationId = organisation?.id;
    setOrganisation(organisation?.id);
  }

  useEffect(() => {
    const me = getMe();

    me.organisationId = props.editingWorkspace?.organisationId || user?.preferences?.organisations?.preferredOrganisationId;
    setOrganisation(me.organisationId);

    handleWorkplaceTypeRefresh();

    if (props.editingWorkspace) {
      setEditingWorkspace(props.editingWorkspace);
      setWorkspaceName(props.editingWorkspace.name);
      setEditorEnabled(true);
    }
  }, [editingWorkspaceDependency]);

  useEffect(() => {
    return () => {
      clearSettings();
    }
  }, []);

  const messages = [
    `Opgeslagen: ${moment(editingWorkspace?.updatedAt).format('D/M/YYYY HH:mm:ss')}`
  ]

  const discardMessage = 'Weet je zeker dat je dit scherm wilt sluiten? Niet opgeslagen aanpassingen worden weggegooid.';

  return (
    <Modal
      open={props.open}
      onClose={handleClose}
      title={props.title}
      buttons={buttons}
      closeConfirmationMessage={discardMessage}
      messages={editingWorkspace ? messages : []}
      maxWidth={'lg'}
      noContentPadding
    >
      <Grid container className={classes.root}>
        <Grid item xs={9} style={{height: '100%'}}>
          <div className={classes.leftPanel} ref={rootRef}>
            {editorEnabled ? (
              <CanvasPanel
                active={props.open}
                backgroundId={me.current.background?.id}
                editMode
                json={props.editingWorkspace?.data?.data}
                onSelectionChange={handleSelectionChange}
                ref={canvasRef}
                rootRef={rootRef}
              />
            ) : organisation && (
              <div className={classes.backgroundPicker}>
                <FilePicker onSelect={handleActiveBackgroundChange} organisationId={organisation} />
              </div>
            )}
          </div>
        </Grid>

        <Grid item xs={3}>
          <div className={classes.rightPanel}>

            {!editorEnabled && (
              <>
                <CanvasPaper>
                  <OrganisationSelector
                    user={user}
                    value={organisation}
                    onChange={handleOrganisationChange}
                    onLoad={handleOrganisationChange}
                  />
                </CanvasPaper>
                <CanvasPaper>
                  <Typography>Kies een achtergrond</Typography>
                </CanvasPaper>
              </>
            )}

            {editorEnabled && (
              <>
                <CanvasPaper>
                  <FormInput
                    id={'workspace-name'}
                    label={'Naam werkruimte'}
                    defaultValue={workspaceName}
                    onChange={val => setWorkspaceName(val)}
                  />
                </CanvasPaper>

                <CanvasPaper>
                  <OrganisationSelector
                    user={user}
                    value={organisation}
                    disabled={editorEnabled}
                    onChange={handleOrganisationChange}
                  />
                </CanvasPaper>

                <CanvasPaper rowActions={canvasElementsRowActions}>
                  <Typography gutterBottom variant={'body2'}>Items toevoegen</Typography>
                  {canvasElements?.length === 0 && (
                    <Typography variant={'subtitle2'}>Je hebt nog geen werkplek types toegevoegd. Klik op
                                                      de <strong>+</strong> om er één toe te voegen.</Typography>
                  )}
                  <div className={classes.elements}>
                    {canvasElements.map((el, index) => <CanvasElement item={el} key={index} />)}
                  </div>
                </CanvasPaper>

                <CanvasSelected
                  elements={selectedCanvasElements}
                  onElementsDuplicate={handleElementsDuplicate}
                  onElementsRemove={handleElementsRemove}
                  onNumberChange={handleElementNumberChange}
                  onPrefixChange={handlePrefixChange}
                  onFlexChange={handleFlexChange}
                  onNotesChange={handleNotesChange}
                />

              </>
            )}
          </div>
        </Grid>
      </Grid>

      <Prompt message={discardMessage} />

      <AddWorkplaceTypeModal
        open={addWorkplaceTypeOpen}
        onClose={() => setAddWorkplaceTypeOpen(false)}
        onRefresh={handleWorkplaceTypeRefresh}
        organisationId={organisation}
        title={'Werkplek type toevoegen'}
      />
    </Modal>
  );
}

export default WorkspaceModal;

interface WorkSpaceModalProps {
  open: boolean;
  onClose: () => void;
  onRefresh: () => void;
  title: string;
  editingWorkspace?: Workspace;
}

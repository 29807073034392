import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Button, Grid, Typography} from '@material-ui/core';
import {RowAction} from '../table/BaseTable';
import {FaBackspace, FaCopy, FaTrash} from 'react-icons/fa/index';
import ActionsOverlay from './ActionsOverlay';
import CanvasPaper from './CanvasPaper';
import FormInput from '../forms/FormInput';
import CodeTag from './CodeTag';
import KeyBinding from './KeyBinding';
import DubwebCard from '../panels/DubwebCard';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative'
  },
  paperActions: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2),
    '& > *': {
      marginLeft: theme.spacing(1)
    }
  },
  switch: {
    display: 'flex',
    justifyContent: 'space-between',
    marginLeft: 0
  },
  input: {
    marginBottom: theme.spacing(2)
  }
}));

export const CanvasSelected = (props: CanvasSelectedProps) => {
  const classes = useStyles(props);

  const rowActions: RowAction<any>[] = [
    {
      label: 'Dupliceren',
      labelSuffix: <KeyBinding modifiers={['ctrl']} keys={['D']}/>,
      icon: FaCopy,
      onClick: props.onElementsDuplicate,
      disabled: () => props.elements.length === 0
    }, {
      label: 'Verwijderen',
      labelSuffix: <KeyBinding modifiers={['ctrl']} keys={['backspace']}/>,
      icon: FaTrash,
      onClick: props.onElementsRemove,
      disabled: () => props.elements.length === 0
    }
  ]

  const getWorkplaceTitle = (workplace) => {
    let title = workplace.name + ' ';
    // if (workplace.prefix) {
    //   title += workplace.prefix;
    // }
    //
    // title += workplace.id;

    return title;
  }

  const ElementsInfo = (innerProps: CanvasSelectedProps) => {
    switch (innerProps.elements.length) {
      case 1:
        if (innerProps.elements[0].type === 'activeSelection') {
          return (
            <div>
              <Typography gutterBottom variant={'body2'}>
                {innerProps.elements[0].getObjects().length} items geselecteerd
              </Typography>
            </div>
          )
        }
        return (
          <div>
            <Typography gutterBottom>{getWorkplaceTitle(innerProps.elements[0])}</Typography>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormInput
                  id={'object-prefix'}
                  placeholder={'Voorvoegsel'}
                  defaultValue={innerProps.elements[0].prefix}
                  onChange={innerProps.onPrefixChange}
                />
              </Grid>
              <Grid item xs={6}>
                <FormInput
                  id={'object-number'}
                  type={'number'}
                  placeholder={'Nummer'}
                  defaultValue={innerProps.elements[0].number}
                  onChange={innerProps.onNumberChange}
                />
              </Grid>
            </Grid>
            {/*<div className={classes.split}>*/}
            {/*<Typography>{'Flexplek'}</Typography>*/}
            <FormInput
              id={'is-flex'}
              type={'switch'}
              defaultValue={innerProps.elements[0].flex}
              label={'Flexplek'}
              labelPlacement={'start'}
              className={classes.switch}
              fullWidth
              onChange={innerProps.onFlexChange}
            />
            <FormInput
              id={'notes'}
              defaultValue={innerProps.elements[0].notes}
              // label={'Notities'}
              placeholder={'Extra voorzieningen'}
              className={classes.input}
              fullWidth
              onChange={innerProps.onNotesChange}
            />
            {/*</div>*/}
            {/*<Typography>Breedte: {Math.round(innerProps.elements[0].width * innerProps.elements[0].scaleX)}px</Typography>*/}
            {/*<Typography>Hoogte: {Math.round(innerProps.elements[0].height * innerProps.elements[0].scaleY)}px</Typography>*/}
          </div>
        )
      default:
        return (
          <div>
            <Typography gutterBottom variant={'body2'}>{innerProps.elements.length} items geselecteerd</Typography>
          </div>
        )
    }
  }

  return <CanvasPaper rowActions={rowActions}><ElementsInfo {...props} /></CanvasPaper>
}

export default CanvasSelected;

interface CanvasSelectedProps {
  elements: any[];
  onElementsRemove: () => void;
  onElementsDuplicate: () => void;
  onPrefixChange: (value: string) => void;
  onNumberChange: (value: number) => void;
  onFlexChange: (value: boolean) => void;
  onNotesChange: (value: string) => void;
}

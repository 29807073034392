import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import {Breakpoints} from '../../constants/Breakpoints';

const font = 'Lato';

const MAIN_COLOR = '#EFBE2C';
const SECONDARY_COLOR = '#323232';
// const MAIN_COLOR = '#82CA3F';

export const theme = createMuiTheme({
  palette: {
    primary: {
      dark: '#b18c12',
      main: MAIN_COLOR,
      light: '#efd063',
    },
    secondary: {
      dark: '#1F1F1F',
      main: SECONDARY_COLOR,
      light: '#AEAEAE',
    },
    common: {
      black: '#323232',
      white: '#FEFEFE'
    },
    error: {
      dark: '#B00020',
      main: '#d77474',
      light: '#e28181'
    },
    success: {
      dark: '#489f4d',
      main: '#70b873',
      light: '#81c784'
    },
    text: {
      primary: '#323232',
      secondary: '#FEFEFE'
    },
    background: {}
  },
  typography: {
    fontFamily: font,
  },
  overrides: {
    MuiTypography: {
      root: {
        fontSize: 14
      },
      body2: {
        fontSize: 14
      },
      body1: {
        fontSize: 13
      },
      h6: {
        fontSize: 16
      },
      h5: {
        fontSize: 18
      },
      h4: {
        fontSize: 20
      },
      h3: {
        fontSize: 22
      },
      h2: {
        fontSize: 24
      },
      h1: {
        fontSize: 26
      },
      gutterBottom: {
        marginBottom: 8
      }
    },
    // MuiMenu: {
    //   paper: {
    //     fontFamily: font,
    //     fontSize: '0.95rem',
    //     fontWeight: 500,
    //     textTransform: 'unset',
    //   },
    // },
    MuiButton: {
      root: {
        fontFamily: font,
        fontSize: 14,
        fontWeight: 500,
        textTransform: 'unset',
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: 13,
        backgroundColor: MAIN_COLOR,
        color: SECONDARY_COLOR
      },
      arrow: {
        color: MAIN_COLOR
      }
    },
    MuiTablePagination: {
      caption: {
        fontSize: 13
      },
    },
    MuiIconButton: {
      root: {
        '&:hover': {
          color: MAIN_COLOR
        }
      }
    },
    MuiInputLabel: {
      root: {
        color: '#323232'
      }
    },
    MuiSwitch: {
      colorSecondary: {
        '&$checked': {
          color: MAIN_COLOR,
        }
      },
      track: {
        '$checked$checked + &': {
          opacity: 0.7,
          backgroundColor: '#b18c12'
        }
      }
    },
    MuiSelect: {
      root: {
        fontSize: 14
      }
    },
    MuiSlider: {
      markLabel: {
        color: SECONDARY_COLOR
      },
    },
    MuiTableCell: {
      head: {
        '&:hover': {
          color: MAIN_COLOR
        }
      }
    },
    MuiTableSortLabel: {
      root: {
        '&:hover': {
          color: MAIN_COLOR
        }
      },
      active: {
        '&:hover': {
          color: MAIN_COLOR
        }
      }
    },
    MuiCheckbox: {
      root: {
        transition: '100ms all',
        color: SECONDARY_COLOR
      },
    },
    MuiFormControlLabel: {
      root: {
        // fontSize: 12
      }
    },
    MuiInputBase: {
      root: {
        fontSize: 14
      }
    }
  },
  props: {
    MuiCheckbox: {
      color: 'secondary',
      size: 'small'
    },
    MuiTooltip: {
      arrow: true,
    },
    MuiButton: {
      // disableElevation: true
    },
    MuiIconButton: {
      color: 'secondary'
    },
    MuiInputLabel: {
      shrink: true
    }
  },
  spacing: 4,
});

import React, {forwardRef, useImperativeHandle, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Button, Grid, IconButton, Menu, PopoverOrigin, Tooltip} from '@material-ui/core';
import {FaPlus} from 'react-icons/fa';
import Title from '../snippets/Title';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around'
  },
  menu: {
    maxWidth: 400,
    padding: theme.spacing(2, 4),
  }
}));

export const DubwebMenu = forwardRef((props: DubwebMenuProps, ref) => {
  const classes = useStyles(props);

  const [menuAnchorElement, setMenuAnchorElement] = useState();

  useImperativeHandle(ref, () => ({
    close() {
      handleClose();
    }
  }));

  const handleClick = (event) => {
    setMenuAnchorElement(event.currentTarget);
  }

  const handleClose = () => {
    setMenuAnchorElement(null);
  }

  return (
    <div>
      <Tooltip title={props.tooltip || ''}>
        <>
          <IconButton
            style={{fontSize: props.iconSize, color: props.iconColor}}
            size={'medium'}
            color={'primary'}
            onClick={handleClick}
          ><props.icon /></IconButton>
          <Menu
            elevation={5}
            open={!!menuAnchorElement}
            anchorEl={menuAnchorElement}
            onClose={handleClose}
            anchorOrigin={props.anchorOrigin || {
              vertical: 'top',
              horizontal: 'center',
            }}
            transformOrigin={props.transformOrigin || {
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <Grid container className={classes.menu} spacing={3}>
              <Grid item xs={12} style={{marginBottom: 4}}>
                <Grid container justify={'space-around'}>
                  <Title variant={'h4'} color={'textPrimary'}>Wat wil je doen?</Title>
                </Grid>
              </Grid>

              {props.items.map(item => (
                <Grid item xs={12} key={item.text}>
                  <Button
                    variant={'contained'}
                    color={'primary'}
                    onClick={item.onClick}
                    disabled={item.disabled}
                    endIcon={item.endIcon}
                    startIcon={item.startIcon}
                    fullWidth
                  >{item.text}</Button>
                </Grid>
              ))}
            </Grid>
          </Menu>
        </>
      </Tooltip>
    </div>
  )
});

DubwebMenu.displayName = 'DubwebMenu';

export default DubwebMenu;

export interface DubwebMenuItem {
  text: string;
  onClick?: () => void;
  disabled?: boolean;
  endIcon?: any;
  startIcon?: any;
}

interface DubwebMenuProps {
  tooltip?: string;
  icon?: any;
  iconSize?: string;
  iconColor?: string;
  items: DubwebMenuItem[];
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
}

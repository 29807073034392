const staticEnvironmentCache: EnvironmentCache = {
  apiUrl: undefined,
};

interface EnvironmentCache {
  apiUrl: string;
}

/**
 * Must be called to set API credentials.
 * @param {string} apiUrl
 */
export function setApiUrl(apiUrl: string) {
  staticEnvironmentCache.apiUrl = apiUrl;
}

export function getApiUrl(): string {
  return staticEnvironmentCache.apiUrl;
}
import React, {useRef, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Modal, {ModalButton} from './Modal';
import {Grid} from '@material-ui/core';
import FormInput from '../forms/FormInput';
import FilePicker from '../snippets/FilePicker';
import {WorkplaceTypeCollection} from '../../models/WorkplaceType';

const useStyles = makeStyles(theme => ({
  filePicker: {
    height: 'calc(100% - 80px)',
  }
}));

export const AddWorkplaceTypeModal = (props: AddWorkplaceTypeModalProps) => {
  const classes = useStyles(props);

  const [loading, setLoading] = useState(false);
  const [formValid, setFormValid] = useState(false);

  const me = useRef({
    workplaceTypeName: 'Werkplek',
    workplaceTypeFileId: null
  })
  const getMe = () => me.current;

  const handleAddWorkplaceTypeNameChange = (value: string) => {
    const me = getMe();

    me.workplaceTypeName = value;

    if (me.workplaceTypeFileId) {
      setFormValid(true);
    } else {
      setFormValid(false);
    }
  }

  const handleAddWorkplaceTypeFileChange = (file: any) => {
    const me = getMe();

    me.workplaceTypeFileId = file.id;

    if (me.workplaceTypeName) {
      setFormValid(true);
    } else {
      setFormValid(false);
    }
  }

  const clearSettings = () => {
    const me = getMe();

    me.workplaceTypeName = 'werkplek';
    me.workplaceTypeFileId = null;

    setLoading(false);
    setFormValid(false);
  }

  const handleClose = () => {
    props.onClose();
    props.onRefresh();

    clearSettings();
  }

  const handleAddWorkplaceTypeClick = () => {
    const me = getMe();

    setLoading(true);

    WorkplaceTypeCollection.insert({
      title: me.workplaceTypeName,
      fileId: me.workplaceTypeFileId,
      organisationId: props.organisationId,
    }).then(res => {
      setLoading(false);
      handleClose();
    })
  }

  const buttons: ModalButton[] = [
    {
      title: 'Toevoegen',
      loading: loading,
      onClick: handleAddWorkplaceTypeClick,
      disabled: !formValid,
      color: 'primary'
    }
  ]

  return (
    <Modal onClose={handleClose} open={props.open} title={props.title} buttons={buttons}>
      <Grid container spacing={3} style={{height: '400px', overflow: 'auto'}}>
        <Grid item xs={12}>
          <FormInput
            id={'item-name'}
            label={'Item naam'}
            defaultValue={'Werkplek'}
            onChange={handleAddWorkplaceTypeNameChange}
          />
        </Grid>
        <Grid item xs={12}>
          <FilePicker
            onSelect={handleAddWorkplaceTypeFileChange}
            className={classes.filePicker}
            organisationId={props.organisationId}
          />
        </Grid>
      </Grid>
    </Modal>
  );
}

export default AddWorkplaceTypeModal;

interface AddWorkplaceTypeModalProps {
  open: boolean;
  organisationId: string;
  onClose: () => void;
  onRefresh: () => void;
  title: string;
}

import React from 'react';
import {makeStyles} from '@material-ui/core';

import loader_dubweb from '../../assets/images/loading_dubweb.gif';
import loader_inctrl from '../../assets/images/loading_inctrl.gif';
import {theme} from '../../assets/themes/main-theme';


const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    padding: theme.spacing(10),
    transition: '400 all ease-in-out',
    opacity: 0,
  },
  img: {
    maxWidth: 200,
    maxHeight: 200,
    margin: 'auto'
  }
}));

export const ContentLoader = (props: ContentLoaderProps) => {
  const classes = useStyles(props);

  const getStyle = () => {
    if (props.loading) {
      return {
        opacity: 1,
        zIndex: 1000,
        background: theme.palette.common.white
      }
    }

    return {
      background: theme.palette.common.white,
      opacity: 0,
      zIndex: -1000
    }
  }

  const getLoader = () => {
    if (props.loader === 'inctrl') {
      return loader_inctrl;
    }

    return loader_dubweb;
  }

  return (
    <div className={classes.root} style={getStyle()}>
      <img src={getLoader()} className={classes.img} alt={'loading...'}/>
    </div>
  );
};

export default ContentLoader;

interface ContentLoaderProps {
  loading: boolean;
  loader?: 'dubweb' | 'inctrl';
}

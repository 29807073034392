import {useEffect, useRef, useState} from 'react';

export function test(){}

/**
 * Returns a debounced value as a state.
 * @param value
 * @param delay
 * @returns {unknown}
 */
export function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    () => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    }, [value]);

  return debouncedValue;
}

/**
 * Use a referenced function
 * @param func
 * @returns {any}
 */
export function useRefFunction(func) {
  const funcRef = useRef(func);
  return funcRef.current;
}

/**
 * Return a debounced function that will be executed after the delay timer has been omitted.
 * @param func
 * @param delay
 * @returns {() => void}
 */
export function useDebouncedFunction(func, delay) {
  const funcRef = useRef(debounce(func, delay));
  return funcRef.current;
}

/**
 * Returns a debounced function that will be called after given given time, it will be postponed if called again.
 * @param fn The function to invoke.
 * @param time The time in milliseconds to wait before invoking.
 * @returns {() => void}
 */
export function debounce(fn, time) {
  let timeout: any = 0;
  return function () {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;

    // eslint-disable-next-line prefer-rest-params
    const args = arguments;

    const functionCall = function functionCall() {
      return fn.apply(self, args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(functionCall, time);
  };
}

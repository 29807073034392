import React, {useContext, useLayoutEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Grid} from '@material-ui/core';
import {AppContext} from '../contexts/AppContext';
import BackgroundPanel from '../components/panels/BackgroundPanel';
import Title from '../components/snippets/Title';
import DubwebCard from '../components/panels/DubwebCard';
import {theme} from '../assets/themes/main-theme';
import {Reservation, ReservationCollection} from '../models/Reservation';
import ReservationCard from '../components/cards/ReservationCard';
import {useQuery} from '../utils';
import PlaceReservationModal from '../components/modals/PlaceReservationModal';
import Loader from '../components/panels/Loader';
import ActivityMenu from '../components/menus/ActivityMenu';
import HomeMenu from '../components/menus/HomeMenu';
import KeyBinding from '../components/snippets/KeyBinding';
import {useHistory} from 'react-router-dom';
import Grow from '../components/snippets/Grow';
import Zoom from '../components/snippets/Zoom';
import {FeedbackCollection} from '../models/Feedback';
import FeedbackFormModal from '../components/modals/FeedbackFormModal';

const useStyles = makeStyles(theme => ({
  container: {
    overflow: 'auto'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  logo: {
    height: '3rem',
    display: 'block',
    zIndex: 10003,
    '&:hover': {
      cursor: 'pointer'
    }
  },
}));

export const Home = (props: HomeProps) => {
  const classes = useStyles(props);

  const query = useQuery();
  const history = useHistory();

  const {user, logout} = useContext(AppContext);

  const [reservations, setReservations] = useState<Reservation[]>();
  const [reservationModalOpen, setReservationModalOpen] = useState(false);

  const [activeFeedback, setActiveFeedback] = useState(null);
  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);

  const fetchActiveFeedback = () => {
    FeedbackCollection.findActiveFeedback().then(res => {
      setActiveFeedback(res);
      setFeedbackModalOpen(true);
    }).catch(() => null)
  }

  const refreshReservations = () => {
    ReservationCollection.findMyReservations().then(res => {
      setReservations(res.rows);
    })
  }

  const handleReservationCancel = (row: Reservation) => {
    setReservations(old => {
      return [...old].filter(reservation => reservation.id !== row.id);
    })
  }

  const handleReserveWorkplaceClick = () => {
    history.push(`/reserveren/${user.organisationName}`);
    // setReservationModalOpen(true);
  }

  useLayoutEffect(() => {
    refreshReservations();
    fetchActiveFeedback();
  }, []);

  return (
    <BackgroundPanel paddingTop={20}>
      <Grid
        className={classes.container}
        container
        justify={'space-around'}
        spacing={3}
        alignContent={'flex-start'}
      >
        <Grid item xs={11} md={10} lg={8} xl={7} className={classes.header}>
          <img src={'/images/servicepunt.png'} className={classes.logo}/>
          <HomeMenu/>
        </Grid>

        <Grid item xs={11} md={10} lg={8} xl={7} className={classes.header}>
          <Title>Welkom terug, {user?.firstName}</Title>
        </Grid>

        <Grid item xs={11} md={10} lg={8} xl={7}>
          <DubwebCard backgroundColor={theme.palette.common.black} spacing={2}>
            <ActivityMenu onReserveWorkplaceClick={handleReserveWorkplaceClick} onRefresh={refreshReservations}/>
          </DubwebCard>
        </Grid>

        {reservations?.map((reservation) => (
          <Grid item xs={11} md={10} lg={8} xl={7} key={reservation.id}>
            <ReservationCard
              reservation={reservation}
              onCancel={handleReservationCancel}
              activeId={query.get('success')}
            />
          </Grid>
        ))}

        {reservations?.length === 0 && (
          <Grid item xs={11} md={10} lg={8} xl={7}>
            <DubwebCard backgroundColor={theme.palette.common.black} spacing={0}>
              <Title color={'textSecondary'} variant={'h3'}>Je hebt nog geen activiteiten</Title>
            </DubwebCard>
          </Grid>
        )}

        {!reservations && (
          <Grid item xs={11} md={10} lg={8} xl={7}>
            <Loader loading={true} transparent={true}/>
          </Grid>
        )}
      </Grid>

      <PlaceReservationModal
        workplaceId={'471c7d77-d3d1-4dcc-a59c-851a03da6bd3'} //TODO: remove this dev
        onClose={() => setReservationModalOpen(false)}
        onRefresh={refreshReservations}
        open={reservationModalOpen}
        title={'Reservering plaatsen'}
      />

      <FeedbackFormModal
        feedback={activeFeedback}
        onClose={() => setFeedbackModalOpen(false)}
        open={feedbackModalOpen}
        title={'Korte vraag'}/>

    </BackgroundPanel>
  );
}

export default Home;

interface HomeProps {

}

import React, {useContext} from 'react';
import {Avatar, makeStyles, Typography} from '@material-ui/core';
import {AppContext} from '../../contexts/AppContext';
import {FaUser} from 'react-icons/all';
import {useHistory} from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    '&:hover $username': {
      opacity: 1,
      transform: 'translateX(0)'
    }
  },
  username: {
    lineHeight: '60px',
    transition: '200ms all',
    opacity: 0,
    transform: 'translateX(100%)'
  },
  avatar: {
    height: 36,
    width: 36,
    margin: 10,
    border: `2px solid ${theme.palette.primary.main}`,
    background: theme.palette.secondary.main,
    transition: '100ms all ease',
    '&:hover': {
      cursor: 'pointer',
      background: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    fontSize: 16
  }
}));

export const ProfileButton = (props: ProfileButtonProps) => {
  const classes = useStyles(props);

  const history = useHistory();
  const {user} = useContext(AppContext);

  const handleMenuClick = (e) => history.push('/profile');

  if (user?.email) {
    let username = '';
    user.firstName && (username += user.firstName[0].toUpperCase());
    user.lastName && (username += user.lastName[0].toUpperCase());

    if (!user.firstName || !user.firstName) {
      const baseEmail = user.email.split('@')[0];
      const separator = baseEmail.includes('.') ? '.' : (baseEmail.includes('-') ? '-' : baseEmail.includes('_') ? '_' : '');
      if (baseEmail.includes(separator)) {
        for (const [idx, part] of baseEmail.split(separator).entries()) {
          idx < 2 && (username += part[0].toUpperCase());
        }
      }
    }
    return (
      <div className={classes.root}>
        <Typography
          variant={'h6'}
          className={classes.username}
        >{user.email}</Typography>
        <Avatar
          className={classes.avatar}
          // onClick={handleMenuClick}
        >{username}</Avatar>
      </div>
    )
  }

  const handleEmptyProfileClick = () => history.push('/login');

  return <Avatar className={classes.avatar} onClick={handleEmptyProfileClick}><FaUser /></Avatar>;
};

export default ProfileButton;

interface ProfileButtonProps {

}

import {BaseObject} from './core/BaseObject';
import Model from './core/Model';
import Collection from './core/Collection';

interface _WorkplaceType extends BaseObject {
  fileId: string;
  title: string;
  organisationId: string;
}

export class WorkplaceType extends Model<_WorkplaceType, WorkplaceTypeCollectionClass> {
  public collection = WorkplaceTypeCollection;


  get organisationId() {
    return this.data.organisationId;
  }

  get fileId() {
    return this.data.fileId;
  }

  get title() {
    return this.data.title;
  }

}

export class WorkplaceTypeCollectionClass extends Collection<_WorkplaceType, WorkplaceType> {
  public route = '/workplaces/types';

}

export const WorkplaceTypeCollection = new WorkplaceTypeCollectionClass(WorkplaceType);

import React from 'react';
import {Checkbox, makeStyles, Typography} from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import {TableColumn} from './TableHeader';
import moment from 'moment';
import {RowAction} from './BaseTable';
import TableRowAction from './TableRowAction';
import CodeTag from '../snippets/CodeTag';
import Model from '../../models/core/Model';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%'
  },
  rowActions: {
    justifyContent: 'flex-end',
    display: 'flex',
    '& > *': {
      paddingRight: theme.spacing(2)
    }
  },
  checkbox: {
    padding: '0 !important',
    color: theme.palette.common.black
  }
}));

export const TableContent = (props: TableContentProps) => {
  const classes = useStyles(props);

  const {rows, order, orderBy, page, rowsPerPage} = props;

  const handleClick = (row: any) => {
  }

  const formatType = (row: any, col: TableColumn) => {
    let value = row[col.id];

    if (col.format) {
      value = col.format(row, col);
    }

    switch (col.type) {
      case 'date':
        return formatComponent(moment(value).format('D/M/YYYY'), row, col);
      case 'dateTime':
        return formatComponent(moment(value).format('D/M/YYYY HH:mm'), row, col);
      case 'boolean':
        return formatComponent(!!value, row, col);
      default:
        return formatComponent(value, row, col);
    }
  }

  const formatComponent = (value: any, row: any, col: TableColumn) => {
    switch (col.component) {
      case 'checkbox':
        return <Checkbox
          checked={value}
          onChange={() => col.onClick && col.onClick(row)}
          disabled={col.disabled?.(row)}/>;
      case 'code':
        return <CodeTag text={value} onClick={() => col.onClick && col.onClick(row)} disabled={col.disabled?.(row)}/>
      default:
        return <Typography>{value || '-'}</Typography>;
    }
  }

  const getColSpan = () => {
    if (props.rowActions && props.rowActions.length > 0) {
      return props.columns.length + 1;
    }
    return props.columns.length;
  }

  const isSelected = (row) => {
    if (props.multiSelect) {
      return props.selected.indexOf(row.id) !== -1;
    }
    return false;
  }

  return (
    <TableBody className={classes.root}>
      {stableSort(rows, getComparator(order, orderBy))
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((row: any, index) => {
          const isItemSelected = isSelected(row);
          // const isItemSelected = false;
          const labelId = `enhanced-table-checkbox-${index}`;

          return (
            <TableRow
              hover
              // onClick={(event) => handleClick(event, row.name)}
              role="checkbox"
              aria-checked={isItemSelected}
              tabIndex={-1}
              key={'tableContent-' + index}
              selected={isItemSelected}
            >
              {props.multiSelect && (
                <TableCell padding="checkbox">
                  <Checkbox
                    size={'small'}
                    checked={isItemSelected}
                    inputProps={{'aria-labelledby': labelId}}
                    onClick={(e) => props.onClick(row, 'checkbox', e)}
                    className={classes.checkbox}
                    color={'primary'}

                  />
                </TableCell>
              )}
              {props.columns.map((col, index) => {
                return <TableCell key={col.id + index} align={col.align}>{formatType(row, col)}</TableCell>
              })}
              {props.rowActions?.length > 0 && (
                <TableCell>
                  <div className={classes.rowActions}>
                    {props.rowActions.map(rowAction => <TableRowAction
                      {...rowAction}
                      row={row}
                      key={rowAction.label}
                    />)}
                  </div>
                </TableCell>
              )}
            </TableRow>
          );
        })}
      {rows.length === 0 && (
        <TableRow>
          <TableCell colSpan={getColSpan()} size={'medium'}>Geen resultaten gevonden.</TableCell>
        </TableRow>
      )}
    </TableBody>
  );
};

export default TableContent;

interface TableContentProps {
  columns: TableColumn[];
  disablePadding?: boolean;
  rowActions?: RowAction<any>[];
  multiSelect?: boolean;
  onClick?: (row: Model<any, any>, type: string, event) => void;
  order: 'asc' | 'desc';
  orderBy: string;
  page: number;
  rows: any[];
  rowsPerPage: number;
  selected?: any[];
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

import React, {useContext, useRef, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {BaseModalProps, ModalButton} from './Modal';
import {Typography} from '@material-ui/core';
import {Feedback, FeedbackCollection} from '../../models/Feedback';
import DubwebModal from './DubwebModal';
import EmotionPicker from '../snippets/EmotionPicker';
import FormInput from '../forms/FormInput';
import {AppContext} from '../../contexts/AppContext';
import {withSnackbar} from 'notistack';

const useStyles = makeStyles(theme => ({
  root: {}
}));

export const FeedbackFormModal = (props: FeedbackFormModalProps) => {
  const classes = useStyles(props);

  const {user, showNotification} = useContext(AppContext);

  const getMe = () => me.current;
  const me = useRef({
    loading: false,
    selectedEmotion: null,
    notes: '',
  })

  const [loading, setLoading] = useState(false);
  const [selectedEmotion, setSelectedEmotion] = useState(null);

  const handleSaveFeedbackClick = () => {
    const me = getMe();

    if (me.loading) {
      return;
    }

    me.loading = true;
    setLoading(true);

    FeedbackCollection.submitFeedbackAnswer({
      feedbackId: props.feedback.id,
      organisationId: props.feedback.data.organisationId,
      userId: user.id,
      emotion: me.selectedEmotion?.id,
      score: me.selectedEmotion?.score,
      note: me.notes,
    }).finally(() => {
      handleClose();
      showNotification('Bedankt voor je feedback.', 'success')
    })

  }

  const handleEmotionSelect = (emotion) => {
    const me = getMe();
    me.selectedEmotion = emotion;
    setSelectedEmotion(emotion);
  }

  const handleNotesChange = (value) => {
    const me = getMe();
    me.notes = value;
  }

  const handleClose = () => {

    const me = getMe();
    me.selectedEmotion = null;
    me.notes = '';
    me.loading = false;

    setLoading(false);
    setSelectedEmotion(null);

    if (props.onClose) {
      props.onClose();
    }
  }

  const buttons: ModalButton[] = [
    {
      color: 'primary',
      title: 'Doorgaan',
      loading: loading,
      onClick: handleSaveFeedbackClick,
      disabled: loading || !selectedEmotion
    }
  ]

  return (
    <DubwebModal
      open={props.open}
      onClose={handleClose}
      title={'Even een korte vraag tussendoor:'}
      buttons={buttons}
    >
      <Typography variant={'h4'} color={'textSecondary'} gutterBottom>{props.feedback?.question}</Typography>
      <EmotionPicker
        onClick={handleEmotionSelect}
        selected={selectedEmotion?.id}
        gutterBottom
        disabled={loading}
        margin={10}
        itemSize={60}/>
      <FormInput
        id={'notes'}
        label={'Heb je een opmerking?'}
        placeholder={'Vul hier je opmerking in'}
        type={'text'}
        textColor={'white'}
        disabled={loading}
        onChange={handleNotesChange}
        InputProps={{
          multiline: true,
          rows: 4
        }}
      />
    </DubwebModal>
  );
}

export default FeedbackFormModal;

interface FeedbackFormModalProps extends BaseModalProps {
  feedback: Feedback;
}
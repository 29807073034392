import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Img from './Img';
import {IconButton, Tooltip, Typography} from '@material-ui/core';
import {FaPlus} from 'react-icons/fa';
import {FaTimes} from 'react-icons/fa/index';

const useStyles = makeStyles(theme => ({
  root: {
    // width: 60,
    // height: 60,
    borderRadius: 5,
    transition: '200ms all',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    marginTop: 5,
    marginRight: theme.spacing(2),
    '&:hover $plus': {
      display: 'block',
      opacity: 1
    },
    '&:hover $remove': {
      display: 'block',
      opacity: 1
    },
    '&:hover': {
      cursor: 'pointer'
    },
    '&:hover $elementImage': {
      backgroundImage: `radial-gradient(${theme.palette.common.white}50 0%, transparent 50%)`,
    }
  },
  plus: {
    display: 'none',
    position: 'absolute',
    padding: 8,
    fontSize: 20,
    left: 'calc(100% / 2 - 18px)',
    top: 'calc(100% / 2 - 26px)',
    color: theme.palette.primary.main,
    transition: '200ms all',
    '&:hover': {
      backgroundColor: 'transparent !important',
      borderColor: 'unset'
    },
  },
  remove: {
    display: 'none',
    opacity: 0,
    position: 'absolute',
    zIndex: 3,
    padding: 1,
    top: -5,
    right: -5,
    fontSize: 13,
    backgroundColor: '#FEFEFE',
    // color: ,
    transition: '200ms all',
    webkitBoxShadow: `0px 0px 6px -3px ${theme.palette.common.black}`,
    mozBoxShadow: `0px 0px 6px -3px ${theme.palette.common.black}`,
    boxShadow: `0px 0px 6px -3px ${theme.palette.common.black}`,
    '&:hover': {
      backgroundColor: '#FEFEFE !important',
      color: theme.palette.error.dark,
    }
  },
  elementImage: {
    transition: '200ms all',
    maxHeight: 40,
  },
  title: {
   textAlign: 'center'
  }
}));

export const CanvasElement = (props: CanvasElementProps) => {
  const classes = useStyles(props);

  return (
    <div className={classes.root}>
      {props.item.img ? (
        <img src={props.item.img} className={classes.elementImage} />
      ) : (props.item.fileId ? (
        <Img fileName={props.item.fileId} className={classes.elementImage} type={'thumbnail'} format={'png'} />
      ) : null)}

      <Typography variant={'caption'} className={classes.title}>{props.item.name}</Typography>
      <IconButton className={classes.remove} onClick={props.item.onDelete}><FaTimes /></IconButton>

      <Tooltip title={props.item.name + ' toevoegen'}>
        <IconButton className={classes.plus} onClick={props.item.onClick}><FaPlus /></IconButton>
      </Tooltip>

    </div>
  );
}

export default CanvasElement;

interface CanvasElementProps {
  item: CanvasItem;
}

export interface CanvasItem {
  name: string;
  fileId?: string;
  img?: any;
  onClick: () => void;
  onDelete: () => void;
}

import React, {useContext} from 'react';
import {makeStyles} from '@material-ui/core';
import Link from '../text/Link';
import {AppContext} from '../../contexts/AppContext';
import NavLink from './NavLink';
import {FaSignOutAlt} from 'react-icons/fa/index';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: 60,
    left: 0,
    width: 200,
    height: 'calc(100% - 60px)',
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    borderRight: `6px solid ${theme.palette.primary.main}`,
    display: 'flex',
    flexDirection: 'column'
  },
  routes: {
    padding: theme.spacing(4),
    '& > *': {
      transition: '150ms padding-left',
      paddingBottom: theme.spacing(1),
      '&:hover': {
        paddingLeft: 8
      }
    }
  },
  footer: {
    padding: theme.spacing(1, 4),
  },
  link: {
    fontSize: 16,
    color: theme.palette.common.white,
    fontWeight: 400,
    '& > img': {
      maxWidth: '100%'
    }
  }
}));

export const Sidebar = (props: SidebarProps) => {
  const classes = useStyles(props);
  const {logout, hasPermission, getSidebarModules} = useContext(AppContext);

  const permittedModules = getSidebarModules().filter(m => hasPermission(m));

  return (
    <div className={classes.root}>
      <div className={classes.routes}>
        {permittedModules.map(module => (
          <Link
            key={module.name}
            to={module.route}
            icon={module.icon}
          >{module.title}</Link>
        ))}
        <NavLink onClick={logout} variant={'body2'}>
          <span style={{marginRight: 16}}>
            <FaSignOutAlt />
          </span>
          Afmelden
        </NavLink>
      </div>
      <div style={{flexGrow: 1}} />
      <div className={classes.footer}>
        <a className={classes.link} href={'http://www.randfenpmanagement.nl/'}>
          <img src={'/images/rand2.png'} />
        </a>
      </div>
    </div>
  );
};

export default Sidebar;

interface SidebarProps {

}

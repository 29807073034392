import {TimeBlock} from '../components/snippets/TimeBlockPicker';
import {Workspace} from '../models/Workspace';
import {Workplace} from '../models/Workplace';
import moment from 'moment';

export function checkCollision(timeBlock1: TimeBlock, timeBlock2: TimeBlock): boolean {
  const A = {
    x1: timeBlock1.fromTime,
    x2: timeBlock1.tillTime
  }

  const B = {
    x1: timeBlock2.fromTime,
    x2: timeBlock2.tillTime
  }

  // console.log(A.x1 < B.x1, A.x2 <= B.x1, A.x2 < B.x2)
  // console.log(A.x1 < B.x1, A.x2 <= B.x1, A.x2 < B.x2, B.x1 < A.x1, B.x2 <= A.x1, B.x1 < A.x2);
  /** First segment = after, second segment = before */
  /** A = unavailable block, B = selected block */
  return (A.x1 < B.x1 && A.x2 <= B.x1 && A.x2 < B.x2) || (B.x1 < A.x1 && B.x2 <= A.x1 && B.x1 < A.x2);
}

export function checkOverlap(timeBlock1: TimeBlock, timeBlock2: TimeBlock): boolean {
  const A = {
    x1: timeBlock1.fromTime,
    x2: timeBlock1.tillTime
  }

  const B = {
    x1: timeBlock2.fromTime,
    x2: timeBlock2.tillTime
  }

  return !((A.x1 <= B.x1 && A.x2 <= B.x1) || (A.x1 >= B.x2 && A.x2 >= B.x2));
}

export function getUnavailableTimeBlocks(workspace: Workspace, workplace: Workplace, date: Date, minHour: number, ignoreReservationId?: string): TimeBlock[] {
  const timeBlocks: TimeBlock[] = [];

  if (!workspace || !workplace) {
    return timeBlocks;
  }

  const d = moment(date).seconds(0).milliseconds(0);
  const today = moment().seconds(0).milliseconds(0);

  const workplaceReservations = (workspace.reservations || [])
    .filter(r => !r.canceled)
    .filter(r => r.workplaceId === workplace.id)
    .filter(r => r.id !== ignoreReservationId)
    .filter(r => {
      const from = moment(r.from);
      return from.date() === d.date() && from.month() === d.month() && from.year() === d.year();
    });


  for (const reservation of workplaceReservations) {
    timeBlocks.push({
      id: reservation.id,
      from: moment(reservation.from).toDate(),
      fromTime: moment(reservation.from).seconds(0).milliseconds(0).toDate().getTime(),
      till: moment(reservation.till).toDate(),
      tillTime: moment(reservation.till).seconds(0).milliseconds(0).toDate().getTime()
    })
  }

  if (today.date() === d.date() && today.month() === d.month() && today.year() === d.year()) {
    const fromDate = today.clone().hour(minHour).minutes(0).toDate();
    const tillDate = today.clone().minutes((Math.ceil((new Date().getMinutes() / 60) * 4) / 4) * 60).toDate();
    timeBlocks.push({
      id: 'unavailable',
      from: fromDate,
      fromTime: fromDate.getTime(),
      till: tillDate,
      tillTime: tillDate.getTime()
    })
  }

  for (const tb1 of timeBlocks) {
    for (const tb2 of timeBlocks) {
      if (tb1.id == tb2.id) continue;

      let overlap = false;

      if (checkOverlap(tb1, tb2)) {
        overlap = true;
      }

      if (tb1.tillTime == tb2.fromTime || tb2.tillTime == tb2.fromTime) {
        overlap = true;
      }

      if(overlap && tb1.id !== 'unavailable') {
        tb1.from = tb2.from;
        tb1.fromTime = tb2.fromTime;
        timeBlocks.splice(timeBlocks.findIndex(tb => tb.id == tb2.id), 1);
      }

    }
  }

  return timeBlocks;
}

import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {BaseTable} from '../table/BaseTable';
import {TableColumn} from '../table/TableHeader';
import {Feedback, FeedbackAnswer, FeedbackCollection} from '../../models/Feedback';
import {emotionsDict} from '../snippets/EmotionPicker';
import {ModalButton} from '../modals/Modal';

const useStyles = makeStyles(theme => ({
  root: {
    // width: 'calc(100% - 32px)'
  },
  icon: {
    maxWidth: 32
  }
}));

export const FeedbackAnswerTable = (props: FeedbackAnswerTableProps) => {
  const classes = useStyles(props);

  const getEmotionIcon = (emotionId: string) => {
    return <img key={emotionId} src={emotionsDict[emotionId]} className={classes.icon}/>;
  }

  const columns: TableColumn[] = [
    {
      id: 'note',
      label: 'Opmerking',
    }, {
      id: 'user',
      label: 'Gebruiker',
    }, {
      id: 'emotion',
      label: 'Gevoel',
      format: (row: FeedbackAnswer) => getEmotionIcon(row.emotion),
      align: 'right'
    }
  ]

  return (
    <BaseTable
      className={classes.root}
      columns={columns}
      buttons={props.tableButtons}
      title={'Opmerkingen'}
      disablePadding
      data={props.feedback?.answers || []}
      filters={[]}
    />
  );
}

export default FeedbackAnswerTable;

interface FeedbackAnswerTableProps {
  feedback: Feedback;
  tableButtons?: ModalButton[];
}
import Collection from './core/Collection';
import Model from './core/Model';
import moment from 'moment';
import {apiGetAllWithCount} from './core/ApiCalls';
import {BaseObject} from './core/BaseObject';
import {User} from './User';
import {Workplace} from './Workplace';
import {WorkplaceType} from './WorkplaceType';
import {Workspace} from './Workspace';
import {Organisation} from './Organisation';

interface _Reservation extends BaseObject {
  canceled?: boolean;
  from: Date;
  notes?: string;
  organisationId: string;
  userId?: string;
  till: Date;
  workplaceId: string;
  workspaceId: string;
  user?: User;
  organisation?: Organisation;
  workplace?: Workplace;
  workplaceType?: WorkplaceType;
  workspace?: Workspace;
}

export class Reservation extends Model<_Reservation, ReservationCollectionClass> {
  public collection = ReservationCollection;

  get workplaceId(): string {
    return this.data.workplaceId;
  }

  get date(): Date {
    return new Date(this.data.from);
  }

  get from(): Date {
    return new Date(this.data.from);
  }

  get canceled(): boolean {
    return this.data.canceled;
  }

  set canceled(value: boolean) {
    this.data.canceled = value;
  }

  get canceledString(): string {
    if (this.data.canceled === true) {
      return 'Ja'
    } else {
      return 'Nee'
    }
  }

  get till(): Date {
    return new Date(this.data.till);
  }

  get notes(): string {
    return this.data.notes;
  }

  get createdByUser(): string {
    return [this.user?.firstName, this.user?.lastName].join(' ')?.trim() || '-'
  }

  get user() {
    return this.data.user;
  }

  get createdAgo(): string {
    return moment(this.createdAt).fromNow()
  }

  get dateCalendar(): string {
    return moment(this.date).format('dddd, D MMMM YYYY');
  }

  get dateString(): string {
    return moment(this.date).format('DD/MM/YYYY');
  }

  get fromTimeString(): string {
    return moment(this.from).format('HH:mm');
  }

  get tillTimeString(): string {
    return moment(this.till).format('HH:mm');
  }

  get organisation(): string {
    return this.data.organisation?.name;
  }

  get workspace(): string {
    return this.data.workspace?.name;
  }

  get workplace(): string {
    const workplaceType = this.data.workplaceType?.title || '-';
    const workplacePrefix = this.data.workplace?.prefix || ''
    const workplaceNumber = this.data.workplace?.number || '';
    return `${workplaceType} ${workplacePrefix || ''}${workplaceNumber}`;
  }

  cancel(): Promise<boolean> {
    return this.delete();
  }

}

export class ReservationCollectionClass extends Collection<_Reservation, Reservation> {
  cachedFinds: false
  public route = '/reservations';

  findMyReservations() {
    return apiGetAllWithCount(this.url + this.route + '/me', this.createModel, true);
  }

}

export const ReservationCollection = new ReservationCollectionClass(Reservation);


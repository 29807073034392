import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Typography} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: (props: TitleProps) => ({
    fontWeight: props.fontWeight || 'bold',
    fontStyle: props.fontStyle || 'italic',
    marginBottom: props.gutterBottom ? 8 : 0
  })
}));

export const Title = (props: TitleProps) => {
  const classes = useStyles(props);

  return (
    <Typography
      className={classes.root}
      variant={props.variant || 'h1'}
      color={props.color || 'primary'}
    >{props.children || props.text}</Typography>
  )
}

export default Title;

interface TitleProps {
  text?: string;
  children?: any;
  variant?: 'inherit' | 'button' | 'overline' | 'caption' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'subtitle1' | 'subtitle2' | 'body1' | 'body2' | 'srOnly' | undefined;
  color?: 'initial' | 'inherit' | 'primary' | 'secondary' | 'textPrimary' | 'textSecondary' | 'error';
  fontWeight?: any;
  fontStyle?: any;
  gutterBottom?: boolean;
}

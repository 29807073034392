import React, {useContext, useRef, useState} from 'react';
import {makeStyles, Typography} from '@material-ui/core';
import WorkspaceTable from '../components/tables/WorkspaceTable';
import WorkspaceModal from '../components/modals/WorkspaceModal';
import {Workspace, WorkspaceCollection} from '../models/Workspace';
import {AppContext} from '../contexts/AppContext';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.background.default

  },
}));

export const WorkspaceManager = (props: WorkspaceManagerProps) => {
  const classes = useStyles(props);

  const rootRef = useRef(null);

  const {showConfirmationModal} = useContext(AppContext);

  const [addWorkspaceOpen, setAddWorkspaceOpen] = useState(false);
  const [activeWorkspace, setActiveWorkspace] = useState(null);

  const handleAddButtonClick = () => {
    setAddWorkspaceOpen(true);
  }

  const handleAddButtonClose = () => {
    setAddWorkspaceOpen(false);
  }

  const handleRefresh = () => {
    getTable().refresh();
  }

  const table = useRef(null);
  const getTable = function () {
    return table.current;
  };

  const handleLoad = () => {
  }

  const handleUploadFileModalClose = () => {
    setAddWorkspaceOpen(false);
    setActiveWorkspace(null);
  }

  const handleEdit = (row: Workspace) => {
    setActiveWorkspace(row);
    setAddWorkspaceOpen(true);
  }

  const handleDelete = (row: Workspace) => {
    showConfirmationModal({
      title: `Werkruimte verwijderen`,
      content: <Typography>{`Weet je zeker dat je "${row.name}" wilt verwijderen?`}</Typography>,
      loader: true,
      callback: async () => {
        WorkspaceCollection.delete(row.id).then(() => {
          handleRefresh();
          setActiveWorkspace(null);
        })
      }
    })
  }


  return (
    <div className={classes.root} ref={rootRef}>
      <WorkspaceTable
        onAddBtnClick={handleAddButtonClick}
        tableRef={table}
        onRefresh={handleRefresh}
        onLoad={handleLoad}
        onEdit={handleEdit}
        onDelete={handleDelete}
      />

      {addWorkspaceOpen && (
        <WorkspaceModal
          open={addWorkspaceOpen}
          onClose={handleUploadFileModalClose}
          onRefresh={handleRefresh}
          title={'Werkruimte'}
          editingWorkspace={activeWorkspace}
        />
      )}
    </div>
  );
};

export default WorkspaceManager;

interface WorkspaceManagerProps {

}

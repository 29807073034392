import React, {useContext, useRef, useState} from 'react';
import {Button, makeStyles} from '@material-ui/core';
import {TableColumn} from '../table/TableHeader';
import {ResultFilter, RowAction} from '../table/BaseTable';
import {UserCollection, User} from '../../models/User';
import DynamicTable from '../table/DynamicTable';
import {FaCannabis, FaEnvelope, FaFilter, FaUserCheck, FaUserTimes} from 'react-icons/fa/index';
import {useHistory} from 'react-router-dom';
import Loader from '../panels/Loader';
import {AppContext} from '../../contexts/AppContext';
import {ToolbarAction} from '../table/TableToolbarAction';

const useStyles = makeStyles(theme => ({
  root: {}
}));

export const UserTable = (props: UserTableProps) => {
  const classes = useStyles(props);

  const [filters, setFilters] = useState<ResultFilter<User>[]>([
    // {
    //   filter: (row) => row.organisation === 'Dubweb',
    //   value: 'Dubweb'
    // }
  ])

  const history = useHistory();

  const {user} = useContext(AppContext);

  const columns: TableColumn[] = [
    {id: 'email', label: 'Email'},
    {id: 'firstName', label: 'Voornaam'},
    {id: 'lastName', label: 'Achternaam'},
    {id: 'roleTranslated', label: 'Rol'},
    {id: 'organisationName', label: 'Organisatie', filterable: true},
    {id: 'updatedAt', label: 'Laatst aangepast', type: 'dateTime'},
    {id: 'status', label: 'Status', filterable: true},
  ];

  const rowActions: RowAction<User>[] = [
    {
      label: () => 'Open uitnodiging',
      icon: <FaEnvelope />,
      onClick: (row: User) => history.push(row.invitationUrl + `&email=${row.email}`),
      disabled: (row: User) => !row.specialToken || row.status !== 'Uitgenodigd' || !row.organisationActive
    }, {
      label: (row) => row.status === 'Inactief' ? 'Activeer gebruiker' : 'Deactiveer gebruiker',
      icon: (row) => row.status == 'Inactief' ? <FaUserCheck /> : <FaUserTimes />,
      onClick: (row: User) => row.status == 'Inactief' ? handleActivate(row) : handleDeactivate(row),
      disabled: (row: User) => row.email == user.email || !row.organisationActive
    }
  ];

  const toolbarActions: ToolbarAction<User[]>[] = []

  const [loading, setLoading] = useState(false);

  const handleActivate = async (user: User) => {
    await user.activate();

    props.onRefresh && props.onRefresh();
  }

  const handleDeactivate = async (user: User) => {
    await user.deactivate();

    props.onRefresh && props.onRefresh();
  }

  const handleFiltersChange = (filters: ResultFilter<User>[]) => {
    setFilters(filters);
  }

  return (
    <div className={classes.root}>

      <DynamicTable
        onAddBtnClick={props.onAddBtnClick}
        addBtnLabel={'Nieuwe gebruiker toevoegen'}
        columns={columns}
        collection={UserCollection}
        title={'Gebruikers'}
        ref={props.tableRef}
        rowActions={rowActions}
        onLoad={props.onLoad}
        disablePadding
        toolbarActions={toolbarActions}
        filters={filters}
        onFiltersChange={handleFiltersChange}
      />
      <Loader loading={loading} />
    </div>
  );
};

export default UserTable;

interface UserTableProps {
  onAddBtnClick?: () => void;
  onLoad?: () => void;
  onRefresh?: () => void;
  tableRef?: any;
}

import React, {useEffect, useState} from 'react';
import {Grid, makeStyles, Typography} from '@material-ui/core';
import BackgroundPanel from '../components/panels/BackgroundPanel';
import Title from '../components/snippets/Title';
import HomeMenu from '../components/menus/HomeMenu';
import DubwebCard from '../components/panels/DubwebCard';
import {theme} from '../assets/themes/main-theme';
import ActivityMenu from '../components/menus/ActivityMenu';
import {useHistory, useParams} from 'react-router-dom';
import {Workspace, WorkspaceCollection} from '../models/Workspace';
import Img from '../components/snippets/Img';
import {Workplace} from '../models/Workplace';

const useStyles = makeStyles(theme => ({
  root: {},
  container: {
    overflow: 'auto'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  logo: {
    height: '3rem',
    display: 'block',
    zIndex: 10003,
    '&:hover': {
      cursor: 'pointer'
    }
  },
  img: {
    maxWidth: '100%',
  }
}));

export const OrganisationReserve = (props: OrganisationReserveProps) => {
  const classes = useStyles(props);

  const history = useHistory();
  const {organisation} = useParams<{ organisation: string }>();

  const [error, setError] = useState('');
  const [workspaces, setWorkspaces] = useState<Workspace[]>([]);

  const handleWorkspaceClick = (workspace: Workspace) => {
    history.push(`/reserveren/${organisation}/${workspace.name}`)
  }

  const handleHomeClick = () => {
    history.push('/')
  }

  useEffect(() => {
    setError('');
    WorkspaceCollection.findOrganisationWorkspaces(organisation, false).then(res => {
      if (res.count === 0) {
        setError(`We kunnen niks vinden voor "${organisation}"`)
      } else {
        setWorkspaces(res.rows);
      }
    }).catch()
  }, [organisation]);

  return (
    <BackgroundPanel paddingTop={20}>
      <Grid
        className={classes.container}
        container
        justify={'space-around'}
        spacing={3}
        alignContent={'flex-start'}
      >
        <Grid item xs={11} md={10} lg={8} xl={7} className={classes.header}>
          <img src={'/images/servicepunt.png'} className={classes.logo} onClick={handleHomeClick}/>
          <HomeMenu/>
        </Grid>

        <Grid item xs={11} md={10} lg={8} xl={7} className={classes.header}>
          <Title>Kies een werkruimte</Title>
        </Grid>

        <Grid item xs={11} md={10} lg={8} xl={7}>
          <Title color={'textSecondary'}>{error}</Title>
        </Grid>


        <Grid item xs={11} md={10} lg={8} xl={7}>
          <Grid container style={{color: 'white'}} spacing={5}>
            {workspaces.map(workspace => (
              <Grid item xs={12} md={6} key={workspace.id}>
                <DubwebCard
                  elevation={5}
                  title={workspace.name}
                  inverted
                  flatBorderTop
                  hoverable
                  onClick={() => handleWorkspaceClick(workspace)}
                >
                  <Img fileName={workspace.backgroundFileId} className={classes.img}/>
                </DubwebCard>
              </Grid>
            ))}
          </Grid>
        </Grid>

        {/*<Grid item xs={11} md={10} lg={8} xl={7}>*/}
        {/*  <DubwebCard backgroundColor={theme.palette.common.black} elevation={5}>*/}
        {/*    <Typography style={{color: 'white'}}>Boop</Typography>*/}
        {/*  </DubwebCard>*/}
        {/*</Grid>*/}

      </Grid>
    </BackgroundPanel>
  );
};

export default OrganisationReserve;

interface OrganisationReserveProps {

}

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {theme} from './assets/themes/main-theme';
import {BrowserRouter} from 'react-router-dom';
import {CssBaseline, Slide, ThemeProvider} from '@material-ui/core';
import AppProvider from './contexts/AppContext';
import {setApiUrl} from './models/core/ApiUrl';
import env from './env/environment';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import {SnackbarProvider} from 'notistack';

setApiUrl(env.api.randinctrl.host);

if (env.deployment === 'production' && window.location.protocol !== 'https:') {
  window.location.replace(`https:${window.location.href.slice(window.location.protocol.length)}`);
}

if (env.deployment === 'development' && window.location.host.includes('localhost')) {
  window.location.host = 'dev.randinctrl.nl:3000'
}

ReactDOM.render(
  // <React.StrictMode>
  <React.Fragment>

    <ThemeProvider theme={theme}>
      <SnackbarProvider
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        TransitionComponent={Slide}
      >
        <AppProvider>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <BrowserRouter>
              <CssBaseline/>
              <App/>
            </BrowserRouter>
          </MuiPickersUtilsProvider>
        </AppProvider>
      </SnackbarProvider>
    </ThemeProvider>
  </React.Fragment>,
  document.querySelector('#root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Grid, Typography} from '@material-ui/core';
import {Route, Switch, useParams, useRouteMatch} from 'react-router-dom';
import BackgroundPanel from '../components/panels/BackgroundPanel';

const useStyles = makeStyles(theme => ({}));

export const Reserve = (props: ReserveProps) => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}/:workplaceId`}><ReserveContent /></Route>
      <Route path={`${match.path}/`}><EmptyReserveContent /></Route>
    </Switch>
  );
}

const EmptyReserveContent = () => {
  return (
    <Typography>{'You don\'t wanna do that.'}</Typography>
  )
}

const ReserveContent = (props: ReserveProps) => {
  const classes = useStyles(props);

  const {workplaceId} = useParams<{ workplaceId: string }>();

  return (
    <BackgroundPanel>
      <Grid container justify={'space-around'}>
        <Grid item xs={12} md={10} lg={8} xl={6}>

        </Grid>
      </Grid>
    </BackgroundPanel>
  )
}

export default Reserve;

interface ReserveProps {

}

import React, {forwardRef, useEffect, useRef, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableContent from './TableContent';
import TableHeader, {TableColumn} from './TableHeader';
import TableFooter from './TableFooter';
import TableToolbar from './TableToolbar';
import Loader from '../panels/Loader';
import {ToolbarAction} from './TableToolbarAction';
import Model from '../../models/core/Model';
import {ModalButton} from '../modals/Modal';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    height: '100%',
    position: 'relative',
    width: '100%',
  },
  root: {
    width: '100%',
    height: '100%',
    position: 'absolute',
  },
  container: {
    overflow: 'auto',
    height: 'calc(100% - 100px)'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
    position: 'relative',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));


export function BaseTable<M>(props: BaseTableProps<M>) {
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [order, setOrder] = useState<'asc' | 'desc'>(props.order || 'desc');
  const [orderBy, setOrderBy] = useState(props.orderBy || 'createdAt');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const [selected, setSelected] = useState([]);

  const me = useRef({prevSelected: null});
  const getMe = () => me.current;

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(Number.parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    let newSelected = [];

    if (event.target.checked) {
      newSelected = rows.map(row => row.id);
    } else {
      newSelected = [];
    }

    setSelected(newSelected);

    if (props.onSelectedChange) {
      const selectedRows = rows.filter(row => newSelected.includes(row.id));
      props.onSelectedChange(selectedRows);
    }
  };

  const handleClick = (row: Model<any, any>, type: string, event) => {
    const me = getMe();
    const id = row.id;

    // if (!props.multiSelect || type !== 'checkbox' && !props.clickRowToSelect) {
    //   if (props.onItemClick) {
    //     me.prevSelected = id;
    //     props.onItemClick(row, type);
    //   }
    //   return;
    // }

    let newSelected = [];

    /** If shift key is pressed, select multiple rows at once */
    /** Shift also auto selects text. Disable this with 'user-select: none' */
    if (me.prevSelected && event?.shiftKey) {
      const ids = rows.map(row => row.id);
      const [startIndex, endIndex] = [ids.indexOf(me.prevSelected), ids.indexOf(id)];
      const includedIds = ids.filter(id => {
        return ids.indexOf(id) >= Math.min(startIndex, endIndex) && ids.indexOf(id) <= Math.max(endIndex, startIndex);
      });

      newSelected = [...selected];

      includedIds.forEach(id => !newSelected.includes(id) && newSelected.push(id));
    } else {
      const selectedIndex = selected.indexOf(id);

      if (selectedIndex === -1) {
        // If not included
        newSelected = newSelected.concat(selected, id);
      } else if (selectedIndex === 0) {
        // If included and at first position
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        // If included and at last position
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        // If included
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1),
        );
      }
    }

    if (props.onSelectedChange) {
      const selectedRows = rows.filter(row => newSelected.includes(row.id));
      props.onSelectedChange(selectedRows);
    }

    me.prevSelected = id;
    setSelected(newSelected);
  }

  useEffect(() => {
    if(props.data) {
      const data = props.filters ? props.data.filter((row) => props.filters.every((resultFilter) => resultFilter.filter(row))) : props.data

      setRows(data);
    }
  }, [props.data, props.filters]);

  return (
    <div className={[classes.root, props.className].join(' ')}>
      <TableToolbar
        actions={props.toolbarActions}
        columns={props.columns}
        rows={props.data}
        title={props.title}
        filters={props.filters}
        onFiltersChange={props.onFiltersChange}
      />
      <TableContainer className={classes.container}>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size={props.disablePadding ? 'small' : 'medium'}
          aria-label="enhanced table"
        >
          <TableHeader
            className={null}
            columns={props.columns}
            order={order as 'asc' | 'desc'}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowActions={props.rowActions}
            numSelected={selected.length}
            rowCount={rows.length}
            multiSelect={props.multiSelect}

            onSelectAllClick={handleSelectAllClick}
          />
          <TableContent
            columns={props.columns}
            disablePadding={props.disablePadding}
            rowActions={props.rowActions}
            multiSelect={props.multiSelect}
            onClick={handleClick}
            order={order}
            orderBy={orderBy}
            page={page}
            rows={rows}
            rowsPerPage={rowsPerPage}
            selected={selected}
          />

        </Table>
      </TableContainer>
      <TableFooter
        addBtnClicked={props.addBtnClicked}
        addBtnLabel={props.addBtnLabel}
        rows={rows}
        page={page}
        buttons={props.buttons}
        rowsPerPage={rowsPerPage}
        loading={props.loading}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
      />
      <Loader loading={props.loading} />
    </div>
  );
}

interface BaseTableProps<M> {
  addBtnClicked?: () => void;
  addBtnLabel?: string;
  buttons?: ModalButton[];
  columns: TableColumn[];
  className?: string;
  data: any[];
  loading?: boolean;
  rowActions?: RowAction<M>[];
  disablePadding?: boolean;
  title: string;
  toolbarActions?: ToolbarAction<M>[];
  multiSelect?: boolean;
  order?: 'asc' | 'desc';
  orderBy?: string;
  filters: ResultFilter<any>[];
  onFiltersChange?: (filters: ResultFilter<any>[]) => void;
  onSelectedChange?: (rows: Model<any, any>[]) => void;
}

export interface RowAction<M> {
  label: any;
  labelSuffix?: any;
  onClick: (row: M) => void;
  icon: any;
  hidden?: (row: M) => boolean;
  disabled?: (row: M) => boolean;
}

export interface ResultFilter<M> {
  filter: (row: M) => void;
  value: string;
}

import React, {useContext, useState} from 'react';
import {makeStyles, Typography} from '@material-ui/core';
import Modal, {ModalButton} from './Modal';
import FormInput from '../forms/FormInput';
import {Organisation, OrganisationCollection} from '../../models/Organisation';
import {AppContext} from '../../contexts/AppContext';
import Alert from '../snippets/Alert';

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 400,
    '& > *': {
      paddingBottom: theme.spacing(2)
    }
  }
}));

export const AddOrganisationModal = (props: AddOrganisationModalProps) => {
  const classes = useStyles(props);

  const {user} = useContext(AppContext);

  const [loading, setLoading] = useState(false);
  const [organisation, setOrganisation] = useState<string>('');

  const handleAddOrganisation = () => {
    setLoading(true);

    let upsertPromise;

    if (props.editingOrganisation) {
      const org = new Organisation({name: organisation?.trim(), id: props.editingOrganisation.id});
      upsertPromise = org.save();
    } else {
      upsertPromise = OrganisationCollection.insert({name: organisation.trim()});
    }

    upsertPromise.then(res => {
      props.onAdded();
      props.onClose();
      setLoading(false);
    });
  }

  const buttons: ModalButton[] = [
    {
      title: 'Organisatie opslaan',
      onClick: handleAddOrganisation,
      disabled: organisation?.trim()?.length === 0 && loading
    }
  ]

  return (
    <Modal open={props.open} onClose={props.onClose} title={props.title} buttons={buttons} loading={loading}>
      <div className={classes.root}>
        <Typography>Vul hieronder de naam van de organisatie in. Klik vervolgens op opslaan.</Typography>
        {props.editingOrganisation && (
          <Alert
            gutterBottom
            severity={'warning'}
            message={'Let op! Het aanpassen van de naam van een organisatie kan er voor zorgen dat de QR codes van gekoppelde werkplekken ongeldig worden.'}/>
        )}

        <FormInput
          id={'organisation'}
          label={'Organisatie'}
          onChange={setOrganisation}
          defaultValue={props.editingOrganisation?.name}/>
      </div>
    </Modal>
  );
};

export default AddOrganisationModal;

interface AddOrganisationModalProps {
  editingOrganisation?: Organisation;
  open: boolean;
  onClose: () => void;
  onAdded: () => void;
  title: string;
}

import {BaseObject} from './core/BaseObject';
import Model from './core/Model';
import Collection from './core/Collection';

interface _Organisation extends BaseObject {
  name: string;
  active?: boolean;
}

export class Organisation extends Model<_Organisation, OrganisationCollectionClass> {
  public collection = OrganisationCollection;
  privateFinds = true;

  get name(): string {
    return this.data.name;
  }

  get active(): string {
    if (this.data.active === true) {
      return 'Ja'
    } else {
      return 'Nee'
    }
  }

  set name(value: string) {
    this.data.name = value;
  }
}

export class OrganisationCollectionClass extends Collection<_Organisation, Organisation> {
  public route = '/organisations';
  privateFinds = true;
}

export const OrganisationCollection = new OrganisationCollectionClass(Organisation);


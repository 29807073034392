import React, {useContext, useEffect, useRef, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {useHistory} from 'react-router-dom';
import {Workspace, WorkspaceCollection} from '../../models/Workspace';
import {Workplace, WorkplaceCollection} from '../../models/Workplace';
import moment, {Moment} from 'moment';
import {AppContext} from '../../contexts/AppContext';
import {Reservation} from '../../models/Reservation';
import {Grid, Typography} from '@material-ui/core';
import Modal, {ModalButton} from '../modals/Modal';
import CanvasPanel, {CanvasObject, ObjectModifier} from './CanvasPanel';
import DateTimePicker from './DateTimePicker';
import DubwebCard from './DubwebCard';
import Title from '../snippets/Title';
import TimeBlockPicker, {TimeBlock} from '../snippets/TimeBlockPicker';
import {checkCollision, checkOverlap, getUnavailableTimeBlocks} from '../../utils/reservationUtils';
import FormInput from '../forms/FormInput';
import CodeTag from '../snippets/CodeTag';
import {User} from '../../models/User';
import {Dict, setObjRecursive} from '../../utils';
import {theme} from '../../assets/themes/main-theme';
import Alert from '../snippets/Alert';

const useStyles = makeStyles(theme => ({
  root: {
    // overflow: 'auto',
    // height: 'calc(100% + 150px)'
  },
  container: {
    flexGrow: 1
  },
  canvas: {
    backgroundColor: 'white',
    height: 400,
    width: 'calc(100% + 24px)',
    margin: theme.spacing(0, -3, 2, -3),
    // marginBottom: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      height: 300
    }
  },
  content: {
    marginTop: 20
  },
  datePicker: {
    width: '100%'
  }
}));

const minHour = 6;
const maxHour = 22;

const decimalHourFormatter = (date: moment.Moment, value: number) => date.hour(value).minute(value % 1 * 60);

export const PlaceReservationPanel = (props: PlaceReservationPanelProps) => {
  const classes = useStyles(props);

  const currentDate = moment().locale('nl').minutes((Math.ceil((new Date().getMinutes() / 60) * 4) / 4) * 60).seconds(0).milliseconds(0);

  const history = useHistory();
  const canvasRef = useRef();
  const me = useRef({
    workspace: null as Workspace,
    workplace: null as Workplace,
    date: currentDate,
    from: currentDate.clone(),
    till: currentDate.clone().hours(currentDate.hours() + 1),
    notes: '',
    unavailableTimeBlocks: [],
    placingReservation: false,

  });
  const getMe = () => me.current;

  const {showConfirmationModal} = useContext(AppContext);

  const [workspace, setWorkspace] = useState<Workspace>(null);
  const [workplace, setWorkplace] = useState<Workplace>(null);
  const [unavailableTimeBlocks, setUnavailableTimeBlocks] = useState<TimeBlock[]>([]);
  const [objectModifiers, setObjectModifiers] = useState<Dict<ObjectModifier>>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [formValid, setFormValid] = useState(true);

  const clearSettings = () => {
    const me = getMe();
    me.workspace = null;
    me.workplace = null;
    me.date = null;
    me.from = null;
    me.till = null;
    me.notes = '';
    me.unavailableTimeBlocks = [];
    me.placingReservation = false;

    setWorkspace(null);
    setWorkplace(null);
    setUnavailableTimeBlocks([]);
    setLoading(true);
    setFormValid(true);
  }

  const handleLoad = (objects: CanvasObject[]) => {
    checkAvailabilityForOtherWorkplaces();
  }

  const handleSelectionChange = (objects: CanvasObject[]) => {
    const me = getMe();
    const firstObject = objects[0];
    const workplaceId = firstObject?.id;

    if (workplaceId) {
      me.workplace = workspace.getWorkplaceById(workplaceId);
      me.unavailableTimeBlocks = getUnavailableTimeBlocks(me.workspace, me.workplace, me.date.toDate(), minHour, props.reservation?.id)

      setWorkplace(me.workplace);
      setUnavailableTimeBlocks(me.unavailableTimeBlocks);

      validateForm();

      checkAvailabilityForOtherWorkplaces();
    }
  }

  const validateForm = () => {
    const me = getMe();

    const fromTime = me.from.seconds(0).milliseconds(0).toDate().getTime();
    const tillTime = me.till.seconds(0).milliseconds(0).toDate().getTime()

    const collidedReservations = me.unavailableTimeBlocks.filter(tb => {
      return !checkCollision(tb, {
        fromTime,
        tillTime
      } as TimeBlock);
    })
    const alreadyBooked = collidedReservations.length > 0;

    if (fromTime < Date.now() || alreadyBooked) {
      setFormValid(false);
    } else {
      setFormValid(true);
    }
  }

  const handleDateChange = (date: Date) => {
    const me = getMe();

    const m = moment(date);

    if (date) {
      me.date = m;
      me.from = me.from.year(m.year()).month(m.month()).date(m.day());
      me.till = me.till.year(m.year()).month(m.month()).date(m.day());
      me.unavailableTimeBlocks = getUnavailableTimeBlocks(me.workspace, me.workplace, me.date.toDate(), minHour, props.reservation?.id)
      setUnavailableTimeBlocks(me.unavailableTimeBlocks);
    }

    validateForm();

    checkAvailabilityForOtherWorkplaces();
  }

  const handleFromTillChange = (fromTill: number[]) => {
    const me = getMe();

    me.from = decimalHourFormatter(me.date.clone(), fromTill[0]);
    me.till = decimalHourFormatter(me.date.clone(), fromTill[1]);

    validateForm();

    checkAvailabilityForOtherWorkplaces();
  }

  const checkAvailabilityForOtherWorkplaces = () => {
    const me = getMe();

    setObjectModifiers(getObjectModifiers(me.workspace.reservations, me.from.toDate(), me.till.toDate()));
  }

  const handlePlaceReservation = async () => {
    const me = getMe();

    setLoading(true);

    if (me.placingReservation) {
      return;
    }

    me.placingReservation = true;

    // console.log('handlePlaceReservation', props.reservation?.id);

    const reservation = new Reservation({
      id: props.reservation?.id,
      from: me.from.seconds(0).milliseconds(0).toDate(),
      till: me.till.seconds(0).milliseconds(0).toDate(),
      workplaceId: me.workplace.id,
      workspaceId: me.workspace.id,
      organisationId: me.workspace.organisationId,
      notes: me.notes
    })

    if (props.userOverride) {
      reservation.data.userId = props.userOverride.id;
    }

    return reservation.save().then((res) => {
      props.onReservationPlaced && props.onReservationPlaced(res);
      clearSettings();
    }).finally(() => {
      me.placingReservation = false;
    })
  }

  const handlePlaceReservationClick = () => {
    const me = getMe();
    const date = me.from.format('D MMMM');
    const [from, till] = [me.from.format('HH:mm'), me.till.format('HH:mm')]

    const getTitle = () => {
      if (props.reservation) {
        return 'Weet je zeker dat je deze reservering wilt aanpassen?';
      } else {
        return 'Weet je zeker dat je een werkplek wilt reserveren?';
      }
    }

    showConfirmationModal({
      title: props.reservation ? 'Reservering aanpassen' : 'Reservering plaatsen',
      content: (
        //voor <br/><strong>{from}</strong> tot <strong>{till}</strong> op <strong>{me.workplace?.fullName}</strong> in <strong>{me.workspace?.name}</strong>?</Typography>
        <div>
          <Typography variant={'body2'} gutterBottom>{getTitle()}</Typography>
          <table>
            <tbody>
            <tr>
              <td>Datum:</td>
              <td><strong>{date}</strong></td>
            </tr>
            <tr>
              <td>Vanaf:</td>
              <td><strong>{from}</strong></td>
            </tr>
            <tr>
              <td>Tot:</td>
              <td><strong>{till}</strong></td>
            </tr>
            <tr>
              <td>Werkplek:</td>
              <td><strong>{me.workplace?.fullName}</strong></td>
            </tr>
            <tr>
              <td>Werkruimte:</td>
              <td><strong>{me.workspace?.name}</strong></td>
            </tr>
            </tbody>
          </table>
        </div>
      ),
      callback: handlePlaceReservation,
      confirmButtonLabel: 'Bevestigen',
    })
  }

  const buttons: ModalButton[] = [{
    title: props.reservation ? 'Reservering opslaan' : 'Reservering plaatsen',
    color: 'primary',
    variant: 'contained',
    onClick: handlePlaceReservationClick,
    disabled: !formValid || !workplace
  }];

  useEffect(() => {
    if (!props.workplaceId) {
      return;
    }

    setLoading(true);

    WorkplaceCollection.getWorkspaceByWorkplaceId(props.workplaceId, true).then(res => {
      // console.log('getWorkspaceByWorkplaceId', props.workplaceId);

      const me = getMe();
      const workspace = new Workspace(res);

      me.workspace = workspace;
      me.workplace = workspace.getWorkplaceById(props.workplaceId)

      setUnavailableTimeBlocks(getUnavailableTimeBlocks(me.workspace, me.workplace, me.date.toDate(), minHour, props.reservation?.id));
      setWorkspace(me.workspace);
      setWorkplace(me.workplace);
      setLoading(false);
    }).catch(error => {
      console.error({error});
      props.onError('We konden geen werkruimte vinden, probeer het later nog eens.');
      setLoading(false);
    })
  }, [props.workplaceId]);

  useEffect(() => {
    if (!props.workspaceName || !props.organisationName || props.workplaceId) {
      return;
    }

    setLoading(true);

    WorkspaceCollection.findWorkspaceByNameAndOrganisation(props.workspaceName, props.organisationName, true).then(res => {
      const me = getMe();


      if (Array.isArray(res)) {
        res = res[0];
      }

      me.workspace = new Workspace(res);
      me.workplace = undefined;

      if (props.workplaceName) {
        me.workplace = me.workspace.getWorkplaceByName(props.workplaceName) || me.workspace.workplaces[0];
      } else {
        me.workplace = me.workspace.workplaces[0];
      }

      /** Create getObjectModifiers function based on the workplaces and their reservations */
      setObjectModifiers(getObjectModifiers(me.workspace.reservations, me.from.toDate(), me.till.toDate()));

      if (me.workplace) {
        setUnavailableTimeBlocks(getUnavailableTimeBlocks(me.workspace, me.workplace, me.date.toDate(), minHour, props.reservation?.id));
      }

      setTimeout(() => {
        const currDate = moment();
        const from = currDate.hours() + (Math.ceil(currDate.minutes() / 15) * 0.25);
        const till = currDate.hours() + 1 + (Math.ceil(currDate.minutes() / 15) * 0.25);

        handleFromTillChange([from, till])
      }, 1)

      setWorkspace(me.workspace);
      setWorkplace(me.workplace);
      setLoading(false);
    }).catch(error => {
      console.error({error});
      props.onError('We konden geen werkruimte vinden, probeer het later nog eens.');
      setLoading(false);
    })

  }, [props.workspaceName, props.organisationName, props.workplaceName]);

  const getObjectModifiers = (reservations: Reservation[], fromDate: Date, tillDate: Date): Dict<ObjectModifier> => {
    const modifiers: Dict<ObjectModifier> = {};

    const [fromTime, tillTime] = [fromDate.getTime(), tillDate.getTime()];

    for (const reservation of reservations) {
      if (checkOverlap({
        from: reservation.from,
        till: reservation.till,
        fromTime: reservation.from.getTime(),
        tillTime: reservation.till.getTime()
      }, {
        from: fromDate,
        till: tillDate,
        fromTime: fromTime,
        tillTime: tillTime
      })) {
        // setObjRecursive(modifiers, `${reservation.workplaceId}.backgroundColor`, theme.palette.error.main)
        setObjRecursive(modifiers, `${reservation.workplaceId}.opacity`, 0.3)
      }
    }

    return modifiers;
  }

  const getDefaultValue = () => {
    if (!props.reservation) {
      return undefined;
    }

    const fromHour = props.reservation.from.getHours() + (1 / (60 / props.reservation.from.getMinutes()));
    const tillHour = props.reservation.till.getHours() + (1 / (60 / props.reservation.till.getMinutes()));

    return [fromHour, tillHour];
  }

  return (
    <DubwebCard buttons={buttons} className={classes.root}>
      <div className={classes.canvas} ref={canvasRef}>
        <CanvasPanel
          active={workspace !== null}
          editMode={false}
          json={workspace?.data?.data}
          onSelectionChange={handleSelectionChange}
          onLoad={handleLoad}
          rootRef={canvasRef}
          objectModifiers={objectModifiers}
        />
      </div>
      {!workplace && (
        <Typography variant={'subtitle2'}>
          {`Sleep hierboven over de plattegrond van `}
          <strong>{workspace?.name || 'de werkruimte'} </strong>
          {`om hem te verplaatsen. Kies een werkplek door er op te drukken.`}
        </Typography>
      )}
      {workplace && (
        <Grid container className={classes.content} spacing={2}>
          <Grid item xs={12}>
            <Title color={'secondary'}>{workplace.fullName}</Title>
          </Grid>
          {workplace.notes && (
            <Grid item xs={12}>
              <CodeTag text={workplace.notes}/>
            </Grid>
          )}
          <Grid item xs={12}>
            <Typography variant={'subtitle2'} gutterBottom>{'Kies een tijd en datum.'}</Typography>
            <Grid container>
              <Grid item xs={12}>
                <FormInput
                  id={'date'}
                  label={'Datum'}
                  type={'date'}
                  onChange={handleDateChange}
                  onLoad={handleDateChange}
                  className={classes.datePicker}
                />
              </Grid>
            </Grid>
          </Grid>

          {/*<Grid item xs={6}>*/}
          {/*  <FormInput id={'timeStart'} label={'Begin'} type={'time'} step={900}/>*/}
          {/*</Grid>*/}
          {/*<Grid item xs={6}>*/}
          {/*  <FormInput id={'timeEnd'} label={'Eind'} type={'time'} addValue={3600} step={900}/>*/}
          {/*</Grid>*/}

          <Grid item xs={12} >
            <TimeBlockPicker
              defaultValue={getDefaultValue()}
              minHour={minHour}
              maxHour={maxHour}
              onLoad={validateForm}
              onFromTillChange={handleFromTillChange}
              unavailableTimeBlocks={unavailableTimeBlocks}
            />
          </Grid>
          <Grid item xs={12}>
            {!formValid &&
            <Alert severity={'warning'} message={'Deze werkplek is niet beschikbaar voor de geselecteerde tijd.'}/>}
          </Grid>
        </Grid>
      )}
    </DubwebCard>
  );
}

export default PlaceReservationPanel;

interface PlaceReservationPanelProps {
  userOverride?: User;
  reservation?: Reservation;
  workplaceId?: string;
  workspaceName?: string;
  workplaceName?: string;
  organisationName?: string;
  onReservationPlaced?: (reservation) => void;
  onError: (message: string) => void;
}

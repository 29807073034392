import React, {useState} from 'react';
import {makeStyles, Typography} from '@material-ui/core';

import {FaExclamationCircle, FaExclamationTriangle, FaInfoCircle, FaCheckCircle, FaTimes} from 'react-icons/fa';
import {theme} from '../../assets/themes/main-theme';


const useStyles = makeStyles(theme => ({
  root: {
    padding: '6px 16px',
    display: 'flex',
    borderRadius: 4,
    width: 'auto',
    position: 'relative',
  },
  clickable: {
    '&:hover': {
      cursor: 'pointer',
      opacity: 0.8
    }
  },
  container: {
    display: 'flex',
  },
  iconContainer: {
    flexDirection: 'column',
    justifyContent: 'center',
    display: 'flex',
    padding: 8,
    fontSize: 18,
    opacity: 0.8,
    marginRight: 6,
  },
  contentContainer: {
    paddingTop: 8,
    '& > p': {
      paddingBottom: 8
    }
  },
  message: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around'
  },
  close: {
    opacity: 0.6,
    padding: '7px 0 0 8px',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  error: {
    color: 'rgb(97, 26, 21)',
    backgroundColor: 'rgb(255,107,88)',
  },
  errorIcon: {
    color: theme.palette.secondary.main
  },
  warning: {
    color: 'rgb(102, 60, 0)',
    backgroundColor: 'rgb(255, 244, 229)',
  },
  warningIcon: {
    color: '#ff9800'
  },
  info: {
    color: 'rgb(13, 60, 97)',
    backgroundColor: 'rgb(232, 244, 253)',
  },
  infoIcon: {
    color: '#2196f3'
  },
  success: {
    color: 'rgb(30, 70, 32)',
    backgroundColor: 'rgb(237, 247, 237)',
  },
  successIcon: {
    color: '#4caf50'
  }
}));


export const Alert = (props: AlertProps) => {
  const classes = useStyles(props);

  const [closed, setClosed] = useState(false);

  const {severity} = props;

  const severityClass = classes[severity];
  const severityClassIcon = classes[`${severity}Icon`];

  const SeverityIcon = () => {
    switch (severity) {
      default:
      case 'error':
        return <FaExclamationCircle />;
      case 'warning':
        return <FaExclamationTriangle />;
      case 'info':
        return <FaInfoCircle />;
      case 'success':
        return <FaCheckCircle />;
    }
  };

  const handleClose = () => {
    setClosed(true);
    props.onClose && props.onClose();
  }

  if (closed) {
    return <div />;
  }

  const rootStyle = {
    minWidth: props.minWidth && props.minWidth,
    maxWidth: props.maxWidth && props.maxWidth,
    marginTop: props.gutterTop && theme.spacing(2),
    marginBottom: props.gutterBottom && theme.spacing(2)
  }

  return (
    <div className={`${classes.root} ${severityClass} ${props.className} ${props.onClick && classes.clickable}`} style={rootStyle}>
      <div className={classes.container} onClick={props.onClick}>
        <div className={`${classes.iconContainer} ${severityClassIcon}`}>
          <SeverityIcon />
        </div>
        <div className={classes.contentContainer}>
          {props.title && (
            <Typography variant={'body2'}>
              <b>{props.title}</b>
            </Typography>
          )}
          <Typography variant={'body2'} className={classes.message}>{props.message}</Typography>
        </div>
      </div>
      <div style={{flexGrow: 1}} />
      {props.onClose && (
        <div className={classes.close} onClick={handleClose}><FaTimes /></div>)}
    </div>
  )
};

export default Alert;

export interface AlertProps {
  severity: AlertSeverity;
  minWidth?: number;
  maxWidth?: number;
  title?: string;
  onClose?: () => void;
  onClick?: (event?) => void;
  message: string;
  className?: string;
  gutterTop?: boolean;
  gutterBottom?: boolean;
}

export type AlertSeverity = 'error' | 'info' | 'success' | 'warning';

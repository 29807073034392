import React, {useContext, useState} from 'react';
import {makeStyles} from '@material-ui/core';
import {TableColumn} from '../table/TableHeader';
import {ResultFilter, RowAction} from '../table/BaseTable';
import DynamicTable from '../table/DynamicTable';
import {useHistory} from 'react-router-dom';
import Loader from '../panels/Loader';
import {AppContext} from '../../contexts/AppContext';
import {ToolbarAction} from '../table/TableToolbarAction';
import {Workplace, WorkplaceCollection} from '../../models/Workplace';
import {FaQrcode} from 'react-icons/all';

const useStyles = makeStyles(theme => ({
  root: {}
}));

export const WorkplaceTable = (props: WorkplaceTableProps) => {
  const classes = useStyles(props);

  const [filters, setFilters] = useState<ResultFilter<Workplace>[]>([])

  const history = useHistory();
  const {user} = useContext(AppContext);

  const columns: TableColumn[] = [
    {id: 'fullName', label: 'Werkplek'},
    {id: 'workspace', label: 'Werkruimte', filterable: true},
    {id: 'organisation', label: 'Organisatie', filterable: true},
    {id: 'type', label: 'Type', filterable: true},
  ];
  const rowActions: RowAction<Workplace>[] = [];

  const [loading, setLoading] = useState(false);

  const handleFiltersChange = (filters: ResultFilter<Workplace>[]) => {
    setFilters(filters);
  }



  return (
    <div className={classes.root}>
      <DynamicTable
        columns={columns}
        collection={WorkplaceCollection}
        title={'Werkplekken'}
        ref={props.tableRef}
        rowActions={rowActions}
        multiSelect={true}
        onLoad={props.onLoad}
        disablePadding
        orderBy={'fullName'}
        order={'asc'}
        toolbarActions={props.toolbarActions}
        filters={filters}
        onFiltersChange={handleFiltersChange}
        onSelectedChange={props.onSelectedChange}
      />
      <Loader loading={loading}/>
    </div>
  );
};

export default WorkplaceTable;

interface WorkplaceTableProps {
  onLoad?: () => void;
  onRefresh?: () => void;
  onSelectedChange?: (rows) => void;
  toolbarActions?: ToolbarAction<Workplace[]>[];
  tableRef?: any;
}

import React from 'react';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: (props: LogoProps) => ({
    position: 'absolute',
    '& > img': {
      height: props.size || '3rem',
      display: 'block',
      margin: 'auto'
    }
  })
}));

export const Logo = (props: LogoProps) => {
  const classes = useStyles(props);

  return (
    <div className={[classes.root, props.className].join(' ')} onClick={props.onClick}>
      <img src={props.src}/>
    </div>
  );
}

export default Logo;

interface LogoProps {
  src: string;
  onClick?: any;
  size?: string;
  className?: string;
}

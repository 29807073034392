import React from 'react';
import {makeStyles, Typography} from '@material-ui/core';
import {Route, Switch} from 'react-router-dom';
import CovidContact from '../../routes/CovidContact';
import Login from '../../routes/Login';
import PrivateRoute from './PrivateRoute';
import Content from './Content';
import AcceptInvitation from '../../routes/AcceptInvitation';
import Home from '../../routes/Home';
import Redirector from '../../routes/Redirector';
import Reserve from '../../routes/Reserve';
import {getModuleByName} from '../../constants/Modules';
import OrganisationReserve from '../../routes/OrganisationReserve';
import WorkspaceReserve from '../../routes/WorkspaceReserve';
import PageNotFound from '../../routes/PageNotFound';
import ResetPassword from '../../routes/ResetPassword';
import {routes} from '../../constants/Routes';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  }
}));

export const RouterPanel = (props: RouterPanelProps) => {
  const classes = useStyles(props);

  return (
    <div className={classes.root}>
      <Switch>
        {routes.filter(route => !route.private).map(route => (
          <Route exact={route.exact} path={route.path} key={route.id}>
            <route.component/>
          </Route>
        ))}

        {routes.filter(route => route.private).map(route => (
          <PrivateRoute exact={route.exact} path={route.path} module={route.module} key={route.id}>
            <route.component/>
          </PrivateRoute>
        ))}

      </Switch>
    </div>
  );
};

export default RouterPanel;

interface RouterPanelProps {

}

import React from 'react';
import {makeStyles} from '@material-ui/core';

import logo from '../../assets/images/rand_logo.png';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    transition: '400ms all ease-in-out',
  },
  wrapper: {},
  '@keyframes beat': {
    to: {
      transform: 'scale(1.1)',
    },
  },
  logo: {
    width: 100,
    transform: 'scale(1.0)',
    transformOrigin: 'center',
    animation: '$beat .5s infinite alternate'
  }
}));

export const Loader = (props: LoaderProps) => {
  const classes = useStyles(props);

  const getStyle = () => {
    if (props.loading) {
      return {
        backgroundColor: props.transparent ? undefined : props.backgroundColor || '#fff',
        opacity: 1,
        zIndex: 1000
      };
    }

    return {
      backgroundColor: props.transparent ? undefined : props.backgroundColor || '#fff',
      opacity: 0,
      zIndex: -1000
    };
  }

  return (
    <div className={classes.root} style={getStyle()}>
      <div className={classes.wrapper}>
        <img src={logo} className={classes.logo}/>
      </div>
    </div>
  );
};

export default Loader;

interface LoaderProps {
  loading: boolean;
  transparent?: boolean;
  backgroundColor?: string;
}

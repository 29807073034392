import {BaseObject} from './core/BaseObject';
import Model from './core/Model';
import Collection from './core/Collection';
import {apiGetAny, apiPostAny} from './core/ApiCalls';
import {Role} from './Role';
import {Organisation} from './Organisation';

interface _User extends BaseObject {
  email: string;
  firstName?: string;
  lastName?: string;
  organisationId: string;
  settings?: any;
  preferences?: any;
  password: string;
  roleId: string;
  specialToken?: string;
  role?: Role;
  organisation?: Organisation;
  status?: string;
}

export class User extends Model<_User, UserCollectionClass> {
  public collection = UserCollection;

  get settings(): any {
    return this.data.settings || {};
  }

  set settings(value: any) {
    this.data.settings = value;
  }

  get preferences(): any {
    return this.data.preferences || {};
  }

  set preferences(value: any) {
    this.data.preferences = value;
  }

  get role(): string {
    return this.data.role?.name;
  }

  get roleTranslated(): string {
    switch (this.role) {
      case 'Guest':
        return 'Gast';
      case 'User':
        return 'Gebruiker';
      default:
        return this.role;
    }
  }

  get status(): string {
    switch (this.data.status) {
      case 'Active':
        return 'Actief';
      case 'Inactive':
        return 'Inactief';
      case 'Invited':
        return 'Uitgenodigd';
      default:
        return 'Onbekend';
    }
  }


  get organisationActive() {
    return this.organisation?.active
  }

  get organisationName(): string {
    return this.organisation?.name;
  }

  get organisation() {
    return this._data.organisation;
  }

  get email(): string {
    return this._data.email;
  }

  set email(value: string) {
    this._data.email = value;
  }

  get displayName(): string {
    return `${this.fullName} (${this.email})`;
  }

  get firstName(): string {
    return this._data.firstName;
  }

  set firstName(value: string) {
    this._data.firstName = value;
  }

  get id(): string {
    return this._data.id;
  }

  set id(value: string) {
    this._data.id = value;
  }

  get lastName(): string {
    return this._data.lastName;
  }

  set lastName(value: string) {
    this._data.lastName = value;
  }

  get organisationId(): string {
    return this._data.organisationId || this.organisation?.id || this.organisation?.['_id'];
  }

  set organisationId(value: string) {
    this._data.organisationId = value;
  }

  get level(): number {
    return this.data.role.level;
  }

  get specialToken(): string {
    return this.data.specialToken;
  }

  get invitationUrl(): string {
    return `/accept-invitation?invitation=${this.specialToken}`;
  }

  get fullName(): string {
    return `${this.firstName} ${this.lastName}`;
  }

  activate(): Promise<boolean> {
    return UserCollection.activate(this.id);
  }

  deactivate(): Promise<boolean> {
    return UserCollection.deactivate(this.id);
  }

  savePreferences(): Promise<User> {
    return UserCollection.put(this.data);
  }

  savePreference(prefs: Record<string, any>): Promise<User> {
    return UserCollection.put({
      id: this.id,
      ...prefs
    } as User)
  }
}

export class UserCollectionClass extends Collection<_User, User> {
  public route = '/users';
  privateFinds = true;

  findMe(refreshCache = false): Promise<User> {
    return super.find('me');
  }

  login(email: string, password: string): Promise<User> {
    return apiPostAny(this.url + '/auth/login', {email, password}, true).then(res => {
      const user = new User(res);
      localStorage.setItem('token', res.specialToken);
      return user;
    });
  }

  resetPassword(email: string, newPassword: string, token: string): Promise<any> {
    return apiPostAny(this.url + '/password-reset-resolve', {email, token, newPassword});
  }

  passwordRequest(email: string): Promise<any> {
    return apiPostAny(this.url + '/password-reset', {email});
  }

  invite(email: string[], organisationId: string): Promise<any> {
    return apiPostAny(this.url + this.route + '/invite', {email, organisationId});
  }

  acceptInvitation(email: string, firstName: string, lastName: string, password: string, invitation: string): Promise<any> {
    return apiPostAny(this.url + '/accept-invitation', {firstName, lastName, email, password}, false, {invitation});
  }

  activate(id: string): Promise<boolean> {
    return apiGetAny(`${this.url + this.route}/${id}/activate`, false);
  }

  deactivate(id: string): Promise<boolean> {
    return apiGetAny(`${this.url + this.route}/${id}/deactivate`, false);
  }
}

export const UserCollection = new UserCollectionClass(User);


import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Modal, {ModalButton} from './Modal';

const useStyles = makeStyles({
  root: {
    // @ts-ignore
    zIndex: '1303 !important'
  }
});

const ConfirmationModal = function (props: ConfirmationModalProps) {
  const classes = useStyles(props);

  const [loading, setLoading] = useState(false);

  const buttons: ModalButton[] = [{
    color: 'default',
    title: 'Annuleren',
    onClick: () => {
      props.onClose();
    },
    disabled: loading
  }, {
    title: props.confirmButtonLabel || 'Verwijderen',
    onClick: () => {
      if (props.loader) {
        setLoading(true);
      } else {
        props.onClose();
      }

      /** Check of callback is of type promise */
      props.callback().finally(res => {
        setLoading(false);
        props.onClose();
        return res;
      })
    },
    loading: loading,
    color: 'primary'
  }];

  useEffect(() => {
    return () => {
      setLoading(false);
    }
  }, [props.open]);

  return (
    <Modal
      buttons={buttons}
      className={classes.root}
      maxWidth={props.maxWidth ||'sm'}
      open={props.open || false}
      onClose={props.onClose}
      title={props.title}
    >
      <div>
        {props.content}
      </div>
    </Modal>
  );
};

export interface ConfirmationModalProps {
  confirmButtonLabel?: string;
  callback: any;
  content: any;
  open?: boolean;
  onClose?: () => void;
  title: string;
  url?: string;
  loader?: boolean;
  maxWidth?: false | 'sm' | 'md' | 'xs' | 'lg' | 'xl';
}

ConfirmationModal.defaultProps = {
  title: 'Title'
};

export default ConfirmationModal;

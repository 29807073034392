import React, {useContext, useState} from 'react';
import {makeStyles} from '@material-ui/core';
import {TableColumn} from '../table/TableHeader';
import {ResultFilter, RowAction} from '../table/BaseTable';
import DynamicTable from '../table/DynamicTable';
import {useHistory} from 'react-router-dom';
import Loader from '../panels/Loader';
import {AppContext} from '../../contexts/AppContext';
import {ToolbarAction} from '../table/TableToolbarAction';
import {Workspace, WorkspaceCollection} from '../../models/Workspace';
import {FaPen, FaTrash} from 'react-icons/fa';

const useStyles = makeStyles(theme => ({
  root: {}
}));

export const WorkspaceTable = (props: WorkspaceTableProps) => {
  const classes = useStyles(props);

  const [filters, setFilters] = useState<ResultFilter<Workspace>[]>([])

  const history = useHistory();

  const {user} = useContext(AppContext);

  const columns: TableColumn[] = [
    {id: 'name', label: 'Naam'},
    {id: 'workplacesCount', label: 'Aantal werkplekken'},
    {id: 'organisation', label: 'Organisatie', filterable: true},
    {
      id: 'reservationPath',
      label: 'Reserveren',
      component: 'code',
      onClick: (row) => history.push(row.reservationPath),
      disabled: (row) => row.organisationId !== user.organisationId
    },
    {id: 'updatedAt', label: 'Laatst aangepast', type: 'dateTime'},
  ];

  const rowActions: RowAction<Workspace>[] = [
    {
      label: () => 'Werkruimte aanpassen',
      icon: <FaPen/>,
      onClick: props.onEdit,
    }, {
      label: () => 'Werkruimte verwijderen',
      icon: <FaTrash/>,
      onClick: props.onDelete,
    }
  ];

  const toolbarActions: ToolbarAction<Workspace[]>[] = []

  const [loading, setLoading] = useState(false);

  const handleFiltersChange = (filters: ResultFilter<Workspace>[]) => {
    setFilters(filters);
  }

  return (
    <div className={classes.root}>

      <DynamicTable
        onAddBtnClick={props.onAddBtnClick}
        addBtnLabel={'Werkruimte toevoegen'}
        columns={columns}
        collection={WorkspaceCollection}
        title={'Werkruimtes'}
        ref={props.tableRef}
        rowActions={rowActions}
        onLoad={props.onLoad}
        disablePadding
        toolbarActions={toolbarActions}
        filters={filters}
        onFiltersChange={handleFiltersChange}
      />
      <Loader loading={loading}/>
    </div>
  );
};

export default WorkspaceTable;

interface WorkspaceTableProps {
  onAddBtnClick?: () => void;
  onLoad?: () => void;
  onRefresh?: () => void;
  onEdit?: (row: Workspace) => void;
  onDelete?: (row: Workspace) => void;
  tableRef?: any;
}

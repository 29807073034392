import React, {useReducer, useRef, useState} from 'react';
import {Box, Button, makeStyles, Typography} from '@material-ui/core';
import UserTable from '../components/tables/UserTable';
import Modal from '../components/modals/Modal';
import AddUserModal from '../components/modals/AddUserModal';
import FeedbackTable from '../components/tables/FeedbackTable';
import FormInput from '../components/forms/FormInput';
import {RowAction} from '../components/table/BaseTable';
import {Feedback} from '../models/Feedback';
import {FaEdit, FaPoll} from 'react-icons/fa/index';
import {FaPen} from 'react-icons/fa';
import FeedbackModal from '../components/modals/FeedbackModal';
import FeedbackDetailsModal from '../components/modals/FeedbackDetailsModal';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.background.default

  },
}));

export const FeedbackManager = (props: FeedbackManagerProps) => {
  const classes = useStyles(props);

  const [activeFeedback, setActiveFeedback] = useState<Feedback>();
  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);
  const [feedbackDetailsModalOpen, setFeedbackDetailsModalOpen] = useState(false);

  const handleRefresh = (refreshCache?: boolean) => {
    getTable().refresh(refreshCache);
  }

  const table = useRef(null);
  const getTable = function () {
    return table.current;
  };

  const handleSave = () => {
    handleRefresh(true);
  }

  /**
   * Table handlers
   */
  const handleAddButtonClick = () => {
    setActiveFeedback(null);
    setFeedbackModalOpen(true);
  }

  /**
   * Feedback detail modal handlers
   * */
  const handleShowDetailsClick = (row: Feedback) => {
    setActiveFeedback(row);
    setFeedbackDetailsModalOpen(true);
  }

  const handleFeedbackDetailsModalClose = () => {
    setActiveFeedback(null);
    setFeedbackDetailsModalOpen(false);
  }

  /**
   * Feedback modal handlers
   * */
  const handleEditFeedbackClick = (row: Feedback) => {
    setActiveFeedback(row);
    setFeedbackModalOpen(true);
  }

  const handleFeedbackModalClose = () => {
    setActiveFeedback(null);
    setFeedbackModalOpen(false);
  }

  /**
   * RowActions
   */
  const rowActions: RowAction<Feedback>[] = [
    {
      label: 'Toon details',
      icon: <FaPoll/>,
      onClick: handleShowDetailsClick
    }, {
      label: 'Edit feedback',
      icon: <FaPen/>,
      onClick: handleEditFeedbackClick,
      disabled: row => row.startDate < new Date()
    }
  ]

  return (
    <div className={classes.root}>
      <FeedbackTable
        tableRef={table}
        rowActions={rowActions}
        onAddBtnClick={handleAddButtonClick}
      />
      <FeedbackModal
        editingFeedback={activeFeedback}
        onClose={handleFeedbackModalClose}
        onSave={handleSave}
        open={feedbackModalOpen}
        title={'Feedback configureren'}
      />

      <FeedbackDetailsModal
        feedback={activeFeedback}
        onClose={handleFeedbackDetailsModalClose}
        open={feedbackDetailsModalOpen}
        title={'Feedback details'}
      />
    </div>
  );
};

export default FeedbackManager;

interface FeedbackManagerProps {

}

import React, {useRef, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import ReservationTable from '../components/tables/ReservationTable';
import ReservationModal from '../components/modals/ReservationModal';
import {Reservation} from '../models/Reservation';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.background.default
  },
}));

export const ReservationManager = (props: ReservationManagerProps) => {
  const classes = useStyles(props);

  const tableRef = useRef(null);

  const [reservationModalOpen, setReservationModalOpen] = useState(false);
  const [editingReservation, setEditingReservation] = useState(null);

  const handleAddButtonClose = () => {
    setReservationModalOpen(false);
    setEditingReservation(null);
  }

  const handleRefresh = () => {
    if (tableRef?.current) {
      tableRef.current.refresh();
    }
  }

  const handleEditReservation = (reservation: Reservation) => {
    setEditingReservation(reservation);
    setReservationModalOpen(true);
  }

  return (
    <div className={classes.root}>
      <ReservationTable
        onAddBtnClick={() => setReservationModalOpen(true)}
        onEdit={handleEditReservation}
        tableRef={tableRef}
      />

      <ReservationModal
        reservation={editingReservation}
        open={reservationModalOpen}
        onClose={handleAddButtonClose}
        onRefresh={handleRefresh}
        title={editingReservation ? 'Reservering aanpassen' : 'Reservering aanmaken'}
      />
    </div>
  );
}

export default ReservationManager;

interface ReservationManagerProps {

}

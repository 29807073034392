import React, {useContext, useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Grid, Table} from '@material-ui/core';
import Modal, {BaseModalProps, ModalButton} from './Modal';
import {Feedback, FeedbackCollection} from '../../models/Feedback';
import {AppContext} from '../../contexts/AppContext';
import EmotionPicker, {FeedbackEmotionId} from '../snippets/EmotionPicker';
import FeedbackAnswerTable from '../tables/FeedbackAnswerTable';

const useStyles = makeStyles(theme => ({
  root: {},
  tableContainer: {
    height: 500,
    position: 'relative'
  }
}));

export const FeedbackDetailsModal = (props: FeedbackDetailsModalProps) => {
  const classes = useStyles(props);

  const {user, showNotification} = useContext(AppContext)

  const [loading, setLoading] = useState(false);
  const [detailedFeedback, setDetailedFeedback] = useState<Feedback>(props.feedback);

  useEffect(() => {

    if (!detailedFeedback) {
      setDetailedFeedback(props.feedback)
    }
    if (props.feedback?.id) {
      getDetailedFeedback(props.feedback.id);
    }
  }, [props.feedback?.id]);

  const getDetailedFeedback = (feedbackId: string) => {
    FeedbackCollection.findFeedbackById(feedbackId).then(res => {
      setDetailedFeedback(res);
    }).catch(error => {
      setDetailedFeedback(null);
      showNotification('Kan details van feedback niet tonen, probeer het later nog eens.', 'error');
      handleClose();
    })
  }

  const handleClose = () => {
    setLoading(false);
    setDetailedFeedback(null);

    if (props.onClose) {
      props.onClose();
    }
  }


  const buttons: ModalButton[] = [
    {
      title: 'Sluiten',
      color: 'primary',
      onClick: handleClose
    }
  ];

  const FeedbackStats = ({feedback}) => {
    return (
      <Table>
        <tbody>
        <tr>
          <td>Vraag:</td>
          <td><strong>{feedback?.question}</strong></td>
        </tr>
        <tr>
          <td>Looptijd:</td>
          <td><strong>{feedback?.amountOfDays ?? 0}</strong> dagen</td>
        </tr>
        <tr>
          <td>Aantal antwoorden:</td>
          <td><strong>{feedback?.stats?.answerCount}</strong></td>
        </tr>
        <tr>
          <td>Unieke gebruikers:</td>
          <td><strong>{feedback?.stats?.uniqueUserCount}</strong></td>
        </tr>
        <tr>
          <td>Aantal opmerkingen:</td>
          <td><strong>{feedback?.stats?.noteCount}</strong></td>
        </tr>
        </tbody>
      </Table>
    )
  }

  const getEmotionCounts = () => {
    const stats: Record<FeedbackEmotionId, number> = {laugh: 0, smile: 0, frown: 0, angry: 0};
    for (const answer of detailedFeedback?.answers || []) {
      stats[answer.emotion]++;
    }
    return [stats.laugh, stats.smile, stats.frown, stats.angry];
  }

  return (
    <Modal
      className={classes.root}
      open={props.open}
      onClose={handleClose}
      title={props.title}
      // buttons={buttons}
      loading={loading}
      maxWidth={'md'}>
      <div className={classes.root}>
        <Grid container>
          <Grid item xs={6}>
            <FeedbackStats feedback={detailedFeedback}/>
          </Grid>
          <Grid item xs={6}>
            <EmotionPicker subtitles={getEmotionCounts()}/>
          </Grid>
          <Grid item xs={12} className={classes.tableContainer}>
            <FeedbackAnswerTable feedback={detailedFeedback} tableButtons={buttons}/>
          </Grid>
        </Grid>
      </div>
    </Modal>
  );
}

export default FeedbackDetailsModal;

interface FeedbackDetailsModalProps extends BaseModalProps {
  feedback?: Feedback;
}
import React, {useEffect, useRef, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Slider, Typography, withStyles} from '@material-ui/core';
import moment from 'moment';
import {theme} from '../../assets/themes/main-theme';
import {isMobileDevice} from '../../utils';

moment.locale('nl');

const CustomSlider = withStyles(theme => ({
  root: {
    padding: '20px 0',
    fontSize: 10,
    [theme.breakpoints.up('xs')]: {
      fontSize: 12
    }
  },
  thumb: {
    color: theme.palette.common.black,
    // color: theme.palette.primary.main,
    boxShadow: `0px 0px 6px -3px ${theme.palette.common.black}`
  },
  track: {
    color: '#989898',
    // color: theme.palette.primary.main,
    // color: 'unset',
    // backgroundImage: `linear-gradient(15deg, ${theme.palette.secondary.light}, ${theme.palette.secondary.main}, ${theme.palette.secondary.light})`,
    boxShadow: `0px 0px 6px -3px ${theme.palette.common.black}`,
  },
  rail: {
    height: 1,
    backgroundColor: theme.palette.secondary.light
  },
  markLabel: {
    fontSize: 10,
    top: 50,
    [theme.breakpoints.up('xs')]: {
      fontSize: 12
    }
  },
  valueLabel: {
    color: theme.palette.primary.main,
    fontSize: 10,
    '& > span': {
      '& > span': {
        color: theme.palette.common.black
      }
    }
  },

}))(Slider);

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    width: '100%',
    height: 120,
  },
  hourBlock: (props: TimeBlockPickerProps) => ({
    display: 'inline-block',
    // backgroundColor: theme.palette.secondary.light,
    borderWidth: 2,
    borderColor: theme.palette.secondary.light,
    borderStyle: 'dashed dashed dashed dashed',
    borderRadius: 5,
    marginRight: props.spacing,

    padding: theme.spacing(4, 2),
    textAlign: 'center',
    '&:hover': {
      borderColor: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.black,
      cursor: 'pointer'
    }
  }),
  selected: {
    backgroundColor: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    color: theme.palette.common.black,
  },
  slider: {
    zIndex: 1001,
  },
  container: {
    position: 'absolute',
    top: 20,
    width: '100%',
    zIndex: 1000,
    height: 50,
    display: 'flex',
    boxShadow: `0px 0px 6px -3px ${theme.palette.common.black}`,
  },
  unavailableBlock: {
    position: 'absolute',
    height: '100%',
    // background: `linear-gradient(10deg, ${theme.palette.error.light}, ${theme.palette.error.main})`,
    backgroundColor: theme.palette.error.light,
    background: `repeating-linear-gradient(-45deg, #fefefe, #fefefe 8px, #e6e6e6 8px, #e6e6e6 16px)`
  },
  availableBlock: {
    height: '100%',
    background: `linear-gradient(350deg, ${theme.palette.success.light}95, ${theme.palette.success.main}95)`,
  },
  minTime: {
    position: 'absolute',
    left: 2,
    bottom: 0
  },
  maxTime: {
    position: 'absolute',
    right: 2,
    bottom: 0
  },
  timeLabel: {
    fontSize: 10,
  },
  timeLine: {
    position: 'absolute',
    height: 50,
    // color: 'unset',
    // backgroundColor: '#989898',
    backgroundColor: theme.palette.common.black,
    // backgroundImage: `linear-gradient(180deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
    boxShadow: `0px 0px 6px -3px ${theme.palette.common.black}`,
    width: 1
  },
  time: {
    position: 'absolute',
    bottom: -20,
    fontSize: 10,
    [theme.breakpoints.up('xs')]: {
      fontSize: 12
    }
  }
}));

export const TimeBlockPicker = (props: TimeBlockPickerProps) => {
  const classes = useStyles(props);

  const currTime = new Date().getHours() + (Math.ceil((new Date().getMinutes() / 60) * 4) / 4);
  const totalBlocks = (props.maxHour - props.minHour) * 4;
  const isMobile = isMobileDevice();

  const [value, setValue] = useState(props.defaultValue || [currTime, currTime + 1]);

  const fromTimeRef = useRef(null);
  const tillTimeRef = useRef(null);

  const handleTimeChange = (event, value: number[]) => {
    setValue(value);
  }

  const getValueLabelFormat = (value: number, index?: number) => {
    return moment().hour(value).minute(value % 1 * 60).format('HH:mm');
  }

  const handleFromTillChange = (event, value: number[]) => {
    setValue(value);

    if (props.onFromTillChange) {
      props.onFromTillChange(value);
    }
  }

  const getUnavailableBlocks = () => {
    // console.log(props.unavailableTimeBlocks)

    // TODO merge timeblocks (if possible) for better styling

    let diff, hour, left, width, minutes;
    return props.unavailableTimeBlocks.map((block, index) => {
      minutes = moment(block.from).minutes();
      diff = moment.duration(moment(block.till).diff(block.from)).asMinutes();
      hour = moment(block.from).hours() + Math.ceil(minutes / 15) * 0.25;
      left = `calc(${(100 / totalBlocks) * ((hour - props.minHour) * 4)}%)`
      width = `calc(${(100 / totalBlocks) * (diff / 15)}%)`
      // console.log(block.from, diff, hour, minutes, left, width)

      // console.log(minutes, diff, hour, left, width);

      return <span
        className={classes.unavailableBlock}
        key={block.from.getTime() + index}
        style={{width, left}}
        aria-label={`${block.from.getTime()}`}
      />
    });
  }

  const getFromTimeLinePercentage = () => {
    return (100 / totalBlocks * ((value[0] - props.minHour) * 4));
  }

  const getTillTimeLinePercentage = () => {
    return (100 / totalBlocks * ((value[1] - props.minHour) * 4));
  }

  const getFromTimeTextPosition = () => {
    const fromLinePos = getFromTimeLinePercentage()
    const tillLinePos = getTillTimeLinePercentage();
    const start = 100 / totalBlocks * 6
    let offset = 0;

    if (tillLinePos - fromLinePos < start && isMobile) {
      offset = start - (tillLinePos - fromLinePos);
    }

    if (fromTimeRef?.current) {
      return `calc(${fromLinePos - offset}% - ${fromTimeRef?.current.getBoundingClientRect().width / 2}px)`
    }
    return `calc(${fromLinePos}% - 1px)`;
  }

  const getTillTimeTextPosition = () => {
    const fromLinePos = getFromTimeLinePercentage()
    const tillLinePos = getTillTimeLinePercentage();
    const start = 100 / totalBlocks * 6
    let offset = 0;

    if (tillLinePos - fromLinePos < start && isMobile) {
      offset = start - (tillLinePos - fromLinePos);
    }
    if (tillTimeRef?.current) {
      return `calc(${tillLinePos + offset}% - ${tillTimeRef?.current.getBoundingClientRect().width / 2}px)`
    }
    return `calc(${tillLinePos}% - 1px)`;
  }

  useEffect(() => {
    if (props.onLoad) {
      props.onLoad();
    }
  }, []);

  return (
    <div className={classes.root}>
      <CustomSlider
        min={props.minHour}
        max={props.maxHour}
        step={0.25}
        valueLabelDisplay={'auto'}
        // marks={timeMarks}
        value={value}
        // track={'inverted'}
        onChange={handleTimeChange}
        onChangeCommitted={handleFromTillChange}
        valueLabelFormat={getValueLabelFormat}
        color={'secondary'}
        className={classes.slider}
      />

      <div className={classes.container}>
        <span className={classes.availableBlock} key={'all-day'} style={{width: '100%'}} aria-label={'all-day'} />
        {getUnavailableBlocks()}
        <span className={classes.minTime}>
          <Typography className={classes.timeLabel}>{getValueLabelFormat(props.minHour)}</Typography>
        </span>
        <span className={classes.maxTime}>
          <Typography className={classes.timeLabel}>{getValueLabelFormat(props.maxHour)}</Typography>
        </span>

        <span className={classes.timeLine} style={{left: `calc(${getFromTimeLinePercentage()}% - 1px)`}} />
        <span className={classes.timeLine} style={{left: `calc(${getTillTimeLinePercentage()}% - 1px)`}} />

        <Typography
          ref={fromTimeRef}
          className={classes.time}
          style={{left: getFromTimeTextPosition()}}
        >{getValueLabelFormat(value[0])}</Typography>

        <Typography
          ref={tillTimeRef}
          className={classes.time}
          style={{left: getTillTimeTextPosition()}}
        >{getValueLabelFormat(value[1])}</Typography>

      </div>

      {/*{timeMarks.map(hour => (*/}
      {/*  <div*/}
      {/*    key={hour.value}*/}
      {/*    className={classes.hourBlock}*/}
      {/*    style={{width: `calc(${100 / timeMarks.length}% - ${props.spacing}px)`}}*/}
      {/*  >*/}
      {/*    A*/}
      {/*  </div>*/}
      {/*))}*/}
    </div>
  );
}

export default TimeBlockPicker;

export interface TimeBlock {
  id?: string;
  from: Date;
  fromTime?: number;
  till: Date;
  tillTime?: number;
}

interface TimeBlockPickerProps {
  minHour?: number;
  maxHour?: number;
  spacing?: number;
  defaultValue?: number[];
  onLoad?: () => void;
  onFromTillChange?: (values: number[]) => void;
  unavailableTimeBlocks?: TimeBlock[];
}

TimeBlockPicker.defaultProps = {
  minHour: 6,
  maxHour: 22,
  spacing: 2
}

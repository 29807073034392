import Axios from 'axios';

export interface SummarizedRow {
  amount: number;
  label: string;
}

export interface RowsById<M> {
  [id: string]: M;
}

export interface FindWithCountResult<M> {
  count: number;
  rows: M[];
  rowsById: RowsById<M>;
  summarizeRows?: SummarizedRow[];
}

export const getRowsById = <M>(rows: M[], createModel): RowsById<M> => {
  const obj = {};
  for (const row of rows) {
    obj[row['_id'] || row['id']] = createModel(row);
  }
  return obj;
}

/** GETTERS */

export const apiGet = <M>(url: string, createModel: (response: any) => M, isPrivateEndpoint = false, params?: any): Promise<M> => {
  return apiGetAny(url, isPrivateEndpoint, params).then(res => {
    return createModel(res);
  });
}

export const apiGetMany = <M>(url: string, createModel: (response: any) => M, isPrivateEndpoint = false, params?: any): Promise<M[]> => {
  return apiGetAny(url, isPrivateEndpoint, params).then(res => {
    return res.map(createModel);
  });
}

export const apiGetAllWithCount = <M>(url: string, createModel: (response: any) => M, isPrivateEndpoint = false, params?: any): Promise<FindWithCountResult<M>> => {
  return apiGetAny(url, isPrivateEndpoint, params).then(res => {
    return {
      count: res.count || res.length,
      rows: (res.rows || res).map(createModel),
      rowsById: getRowsById(res.rows || res, createModel)
    } as FindWithCountResult<M>;
  }).catch(() => {
    return {
      count: 0,
      rows: [],
      rowsById: {}
    } as FindWithCountResult<M>;
  });
}

export const apiGetAny = (url: string, isPrivateEndpoint = false, params?: any): Promise<any> => {
  return Axios.get(url, {
    params: params,
    headers: {
      authorization: getToken()
    },
  }).then((res) => {
    if (!res.data) {
      // eslint-disable-next-line
      throw('Error');
    }
    return res.data;
  });
}

/** POSTERS */

export const apiPost = (url: string, data: any, isPrivateEndpoint = true, params?: any): Promise<string> => {
  return apiPostAny(url, data, isPrivateEndpoint, params);
}

export const apiPostMany = (url: string, data: any[], isPrivateEndpoint = true, params?: any): Promise<string[]> => {
  return apiPostAny(url, data, isPrivateEndpoint, params) as unknown as Promise<string[]>;
}

export const apiPostAny = (url: string, data: any, isPrivateEndpoint = true, params?: any): Promise<any> => {
  return Axios.post(url, data, {
    params: params,
    headers: {
      authorization: getToken()
    },
  }).then((res) => {
    if (!res.data) {
      // eslint-disable-next-line
      throw('Error');
    }
    return res.data;
  });
}

/** PUTTERS */

export const apiPut = <M>(url: string, data: any, createModel: (response: any) => M, isPrivateEndpoint = true, params?: any): Promise<M> => {
  return apiPutAny(url, data, isPrivateEndpoint, params).then(res => {
    return createModel(res);
  });
}

export const apiPutMany = <M>(url: string, data: any[], createModel: (response: any) => M, isPrivateEndpoint = true, params?: any): Promise<M[]> => {
  if (data.length === 0) return Promise.resolve([]);
  return apiPutAny(url, data, isPrivateEndpoint, params).then(res => {
    return res.map(createModel);
  });
}

export const apiPutAny = (url: string, data: any, isPrivateEndpoint = true, params?: any): Promise<any> => {
  return Axios.put(url, data, {
    params: params,
    headers: {
      authorization: getToken()
    },
  }).then((res) => {
    if (!res.data) {
      // eslint-disable-next-line
      throw('Error');
    }
    return res.data;
  });
}

/** DELETES */

export const apiDelete = (url: string, isPrivateEndpoint = true, params?: any): Promise<boolean> => {
  return apiDeleteAny(url, undefined, isPrivateEndpoint, params).then(res => !!res);
}

export const apiDeleteMany = (url: string, ids: string[], isPrivateEndpoint = true, params?: any): Promise<boolean> => {
  return apiDeleteAny(url, ids, isPrivateEndpoint, params).then(res => !!res);
}

export const apiDeleteAny = (url: string, data?: any, isPrivateEndpoint = true, params?: any): Promise<any> => {
  return Axios.delete(url, {
    params: params,
    headers: {
      authorization: getToken()
    },
    data,
  }).then((res) => {
    if (!res.data) {
      // eslint-disable-next-line
      throw('Error');
    }
    return res.data;
  });
}

/** CACHE */

const defaultCache: any = {};

/**
 * Returns a potentially cached promise.
 * @param {string} cacheKey The key under what this function should be cached.
 * @param {() => Promise<G>} apiAction The function to execute and cache.
 * @param {boolean} refreshCache Whether or not to overwrite the cached promise with given apiAction.
 * @param {object} cache The cache object to store the promises in.
 * @returns {Promise<G>}
 * @example
 * this._cachedApiAction(`findAll${this.model.name}`, () => {
 *    return 'Banana';
 * }, true);
 * @private
 */
export const cachedApiAction = <G>(cacheKey: string, apiAction: () => Promise<G>, refreshCache = false, cache: object = defaultCache): Promise<G> => {
  if (cache[cacheKey] && !refreshCache) {
    return cache[cacheKey];
  }

  cache[cacheKey] = apiAction();
  return cache[cacheKey];
}

export const getToken = () => {
  return localStorage.getItem('token');
}

/** UTILS */


import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Button, ButtonProps} from '@material-ui/core';
import {theme} from '../../assets/themes/main-theme';


const useStyles = makeStyles(theme => ({
  root: {},
  loader: (props: LoaderButtonProps) => ({
    width: 22,
    color: 'white'
  })
}));

export const LoaderButton = (props: LoaderButtonProps) => {
  const classes = useStyles(props);

  /** Clear all non-ButtonProps props */
  const btnProps = {...props};
  delete btnProps.loading;
  delete btnProps.loadingPlacement
  delete btnProps.disableOnLoading;

  if (props.loading) {
    if (props.disableOnLoading) {
      btnProps.disabled = true;
    }

    const loader = <img
      src={btnProps.disabled ? '/images/loading_dark.gif' : '/images/loading_light.gif'}
      // style={{opacity: btnProps.disabled && 0.7}}
      className={classes.loader}
    />

    switch (props.loadingPlacement) {
      case 'right':
        btnProps.endIcon = loader;
        break;
      default:
      case 'left':
        btnProps.startIcon = loader;
        break;
    }
  }

  return (
    <Button {...btnProps} style={{
      backgroundColor: theme.palette.primary.main,
      opacity: props.disabled && 0.4,
      ...props.style
    }}>{props.children}</Button>
  )
}

export default LoaderButton;

interface LoaderButtonProps extends ButtonProps {
  loading?: boolean;
  loadingPlacement?: 'left' | 'right';
  disableOnLoading?: boolean;
}

LoaderButton.defaultProps = {
  disableOnLoading: true,
  style: {}
}

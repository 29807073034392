import React, {Fragment, useState} from 'react';
import {Button, Grid, makeStyles, Typography} from '@material-ui/core';
import Img from '../components/snippets/Img';
import FormInput, {FormInputProps} from '../components/forms/FormInput';
import FormPanel from '../components/forms/FormPanel';
import DubwebCard from '../components/panels/DubwebCard';
import {theme} from '../assets/themes/main-theme';
import Title from '../components/snippets/Title';
import ActionsOverlay from '../components/snippets/ActionsOverlay';
import {RowAction} from '../components/table/BaseTable';
import {FaPlus, FaTrashAlt, FaUtensils} from 'react-icons/fa';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  }
}));

interface Recipe {
  name: string;
  ingredients: {
    [ingredient: string]: number;
  };
}

const foods = ['Kiwi', 'Peer', 'Boerenkool (50gr)', 'Dadel', 'Banaan', 'Avocado', 'Mango', 'Spinazie (50gr)', 'Venkel', 'Pruim', 'Sla (50gr)', 'Lijnzaad (el)', 'Komkommer', 'Melk plantaardig (100ml)', 'Chiazaad (el)', 'Courgette', 'Blauwe bessen (75gr)', 'Cacaopoeder (el)', 'Perzik', 'Paksoi', 'Andijvie (50gr)', 'Appel', 'Aardbeien (100gr)', 'Kokosrasp (el)', 'Frambozen (125gr)', 'Havermout (el)', 'Griekse yoghurt (150ml)', 'Rode biet', 'Ananas (100gr)', 'Water (100ml)', 'Munt (bl)', 'Kaneel (el)', 'Honing (el)', 'Notenpasta ongezoet (el)', 'Cashewnoten (1 hand)'];

const getRecipes = () => {
  if (!localStorage.getItem('recipes')) {
    localStorage.setItem('recipes', JSON.stringify([]));
  }

  return JSON.parse(localStorage.getItem('recipes'));
}

const getFoodStorage = () => {
  const _foods = JSON.parse(localStorage.getItem('foods')) || {};

  for (const food of foods.sort()) {
    if (_foods[food] === undefined) {
      _foods[food] = 0;
    }
  }

  localStorage.setItem('foods', JSON.stringify(_foods));

  return _foods;
}

export const FoodPlanner = (props: FoodPlannerProps) => {
  const classes = useStyles(props);

  const [foodStorage, setFoodStorage] = useState(getFoodStorage())
  const [foodSearch, setFoodSearch] = useState('');
  const [recipeSearch, setRecipeSearch] = useState('');
  const [newRecipe, setNewRecipe] = useState<Recipe>({name: '', ingredients: {Appel: 1}});
  const [recipes, setRecipes] = useState<Recipe[]>(getRecipes());
  const [onlyAvailableRecipes, setOnlyAvailableRecipes] = useState(false);
  const [amountOfPeople, setAmountOfPeople] = useState(1);

  const handleFoodChange = (food: string, value: string) => {
    const foodStorage = getFoodStorage();
    foodStorage[food] = Number.parseInt(value) || 0;
    localStorage.setItem('foods', JSON.stringify(foodStorage));
    setFoodStorage(foodStorage);
  }

  const handleFormValidated = (values) => {
    const newFoodStorage = {...getFoodStorage()}

    Object.keys(values).map(food => {
      newFoodStorage[food] = Number.parseFloat(values[food]) || 0;
    })

    localStorage.setItem('foods', JSON.stringify(newFoodStorage));
    setFoodStorage(newFoodStorage);
  }

  const getFoodInputs = (search = '') => {
    const inputs: FormInputProps[] = Object.keys(foodStorage).filter(f => f.toLowerCase().includes(search.toLowerCase())).map(food => {
      return {
        id: food,
        defaultValue: foodStorage[food],
        label: food,
        // onChange: (val) => handleFoodChange(food, val),
      }
    })

    return <FormPanel
      active={true}
      inputs={inputs}
      onFormValidated={handleFormValidated}
      spacing={3}
      timeTilValidate={200}
      textColor={theme.palette.common.white}
    />
  }

  const handleRecipeNameChange = (value) => {
    setNewRecipe({
      name: value,
      ingredients: {
        ...newRecipe.ingredients
      }
    });
  }

  const addNewIngredientToRecipe = () => {
    setNewRecipe({
      ...newRecipe,
      ingredients: {
        ...newRecipe.ingredients,
        [foods[0]]: 1
      }
    })
  }

  const handleIngredientChange = (ingredient: string, quantity: number, oldIngredient?) => {
    if (oldIngredient) {
      delete newRecipe.ingredients[oldIngredient];
    }

    setNewRecipe({
      name: newRecipe.name,
      ingredients: {
        ...newRecipe.ingredients,
        [ingredient]: quantity
      }
    })
  }

  const handleSaveRecipeClick = () => {
    const recipes = getRecipes();
    recipes.push(newRecipe);
    localStorage.setItem('recipes', JSON.stringify(recipes));

    setRecipes(recipes);
    setNewRecipe({name: '', ingredients: {Appel: 1}});
  }

  const getIngredientInputs = () => {
    if (!newRecipe) {
      return [];
    }

    return Object.keys(newRecipe.ingredients).map((ingredient, index) => (
      <Grid container key={ingredient} spacing={3}>
        <Grid item xs={8}>
          <FormInput
            id={ingredient}
            label={ingredient}
            textColor={theme.palette.common.white}
            type={'select'}
            options={foods.sort().map(f => ({
              value: f,
              label: f
            }))}
            color={'secondary'}
            onChange={(val) => handleIngredientChange(val, newRecipe.ingredients[ingredient], ingredient)}
          />
          <Typography style={{position: 'absolute', color: theme.palette.common.white, marginTop: -26}}>
            {ingredient}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <FormInput
            defaultValue={newRecipe.ingredients[ingredient]}
            id={ingredient + '-amount'} label={'Aantal'} textColor={theme.palette.common.white}
            onChange={(val) => handleIngredientChange(ingredient, val)}
          />
        </Grid>
      </Grid>
    ))
  }

  const handleSubstractIngredients = (name: string) => {
    const foodStorage = getFoodStorage();

    const recipe = recipes.find(r => r.name === name);

    if (!recipe) {
      return;
    }

    for (const ingredient in recipe.ingredients) {
      foodStorage[ingredient] -= recipe.ingredients[ingredient] * amountOfPeople;
    }

    localStorage.setItem('foods', JSON.stringify(foodStorage));
    setFoodStorage(foodStorage);

  }

  const handleRemoveRecipe = (name: string) => {
    const newRecipes = getRecipes().filter(r => r.name !== name);
    setRecipes(newRecipes);
    localStorage.setItem('recipes', JSON.stringify(newRecipes));
  }

  const addRecipeActions: RowAction<Recipe>[] = [
    {
      icon: <FaPlus />,
      label: 'Ingrediënt toevoegen',
      onClick: addNewIngredientToRecipe
    }
  ]

  const recipeActions: RowAction<Recipe>[] = [
    {
      icon: <FaTrashAlt />,
      label: 'Recept verwijderen',
      onClick: (recipe) => handleRemoveRecipe(recipe.name)
    }, {
      icon: <FaUtensils />,
      label: 'Ik heb dit recept gemaakt',
      onClick: (recipe) => handleSubstractIngredients(recipe.name)
    }
  ]

  const getRecipeElements = (search: string) => {
    const foodStorage = getFoodStorage();

    return recipes.filter(r => {
      const searchResult = r.name.toLowerCase().includes(search.toLowerCase());
      if (onlyAvailableRecipes) {
        for (const ingredient of Object.keys(r.ingredients)) {
          if (r.ingredients[ingredient] * amountOfPeople > foodStorage[ingredient]) {
            return false;
          }
        }
      }

      return searchResult;

    }).map(r => (
      <Grid item xs={12} key={r.name}>
        <div style={{position: 'relative'}}>
          <Title color={'textSecondary'} gutterBottom>{r.name}</Title>
          <ActionsOverlay rowActions={recipeActions} row={r} inverted />
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <Typography color={'textSecondary'}>{'Ingredient'}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography color={'textSecondary'}>{'Hoeveelheid nodig'}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography color={'textSecondary'}>{'Dit heb je'}</Typography>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            {Object.keys(r.ingredients).map(ingr => (
              <Fragment key={ingr}>
                <Grid item xs={4}>
                  <Typography color={'textSecondary'} key={ingr}>{ingr}:</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography color={'textSecondary'} key={ingr}>{r.ingredients[ingr] * amountOfPeople}</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography color={'textSecondary'} key={ingr}>{foodStorage[ingr]}</Typography>
                </Grid>
              </Fragment>
            ))}
          </Grid>


        </div>
      </Grid>
    ))
  }

  return (
    <div className={classes.root}>
      <Typography variant={'h4'} gutterBottom>Food planner</Typography>


      <Grid container spacing={6}>
        <Grid item xs={4}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Title color={'textPrimary'} gutterBottom>Wat heb je in huis?</Title>
              <FormInput id={'food-search'} label={'Zoeken naar ingrediënten'} onChange={(val) => setFoodSearch(val)} />
            </Grid>
            <Grid item xs={12}>
              <DubwebCard backgroundColor={theme.palette.primary.main}>
                <div style={{overflow: 'auto', overflowX: 'hidden',  height: 600}}>
                  {getFoodInputs(foodSearch)}
                </div>
              </DubwebCard>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={4}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Title color={'textPrimary'} gutterBottom>Recepten</Title>
              <FormInput id={'recipe-search'} label={'Zoeken naar recepten'} onChange={(val) => setRecipeSearch(val)} />
            </Grid>
            <Grid item xs={12}>
              <DubwebCard backgroundColor={theme.palette.primary.main}>
                <Grid
                  container
                  style={{display: 'flex', justifyContent: 'space-between', width: '100%', color: 'white'}}
                >
                  <Grid item xs={8}>
                    <FormInput
                      id={'only-have-ingredients'}
                      type={'checkbox'}
                      color={'primary'}
                      label={'Toon alleen recepten die ik kan maken.'}
                      onChange={(val) => setOnlyAvailableRecipes(!!val)}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormInput
                      id={'amount-of-people'}
                      type={'number'}
                      defaultValue={amountOfPeople}
                      label={'Aantal personen'}
                      textColor={theme.palette.common.white}
                      onChange={(val) => setAmountOfPeople(Number.parseInt(val))}
                    />
                  </Grid>
                </Grid>
                <div style={{overflow: 'auto', overflowX: 'hidden',  height: 600}}>
                  <Grid container spacing={6}>
                    {recipes?.length > 0 ? getRecipeElements(recipeSearch) : (
                      <Typography style={{margin: 16}} color={'textSecondary'}>Je hebt nog geen recepten, maak er rechts
                                                                               eentje aan.</Typography>
                    )}
                  </Grid>
                </div>
              </DubwebCard>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={4}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {/*<Typography variant={'h6'}>Nieuw recept</Typography>*/}
              <Title color={'textPrimary'} gutterBottom>Nieuw recept</Title>
              <div style={{opacity: 0}}>
                <FormInput id={'food-search'} label={'Zoeken naar recepten'} onChange={(val) => setFoodSearch(val)} />
              </div>
            </Grid>
            <Grid item xs={12} style={{overflow: 'auto', height: 600}}>
              <DubwebCard backgroundColor={theme.palette.primary.main}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <FormInput
                      id={'naam'}
                      textColor={theme.palette.common.white}
                      label={'Receptnaam'}
                      onChange={handleRecipeNameChange}
                    />
                  </Grid>
                  <Grid item xs={12} style={{display: 'relative'}}>
                    <Typography color={'textSecondary'} variant={'h6'}>Ingrediënten</Typography>
                    <ActionsOverlay rowActions={addRecipeActions} row={newRecipe} inverted />
                  </Grid>
                  <Grid item xs={12}>
                    {getIngredientInputs()}
                  </Grid>
                  <Grid item xs={10} />
                  <Grid item xs={2}>
                    <Button
                      color={'primary'}
                      variant={'contained'}
                      fullWidth
                      onClick={handleSaveRecipeClick}
                    >Opslaan</Button>
                  </Grid>
                </Grid>
              </DubwebCard>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

    </div>
  );
};

export default FoodPlanner;

interface FoodPlannerProps {

}

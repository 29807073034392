import React, {useState} from 'react';
import {Chip, makeStyles, Select, Typography} from '@material-ui/core';
import TableToolbarAction, {ToolbarAction} from './TableToolbarAction';
import FormInput from '../forms/FormInput';
import Grow from '../snippets/Grow';
import {ResultFilter} from './BaseTable';
import {FaFilter} from 'react-icons/fa/index';
import FilterModal from '../modals/FilterModal';
import {TableColumn} from './TableHeader';

const useStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(3),
    height: 50,
    minHeight: 50,
    display: 'flex',
    justifyContent: 'space-between'
  },
  title: {
    fontSize: '16px',
    '& > *': {
      lineHeight: '50px'
    },
  },
  actions: {
    margin: 'auto 0',
    '& > *': {
      margin: theme.spacing(0, 2)
    }
  },
  filters: {},
  activeFilter: {
    borderRadius: 4
  }
}));

export const TableToolbar = (props: TableToolbarProps) => {
  const classes = useStyles(props);
  const filterable = props.columns.filter(c => c.filterable).length > 0;

  const [filterModalOpen, setFilterModalOpen] = useState(false);

  const handleFilterDelete = (filter: ResultFilter<any>) => {
    props.onFiltersChange(props.filters.filter(f => f.value !== filter.value));
  }

  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <Typography variant={'h6'}>{props.title}</Typography>
      </div>
      <div className={classes.actions}>
        {filterable && (
          <TableToolbarAction
            rows={props.rows}
            icon={<FaFilter />}
            id={'filters'}
            label={'Resultaten filteren'}
            click={() => setFilterModalOpen(true)}
          />
        )}
        {props.filters && props.filters.map(filter => (
          <Chip
            key={filter.value}
            size={'small'}
            className={classes.activeFilter}
            label={filter.value}
            onDelete={() => handleFilterDelete(filter)}
          />
        ))}
      </div>
      <Grow />
      <div className={classes.actions}>
        {props.actions && props.actions.map(action => (
          <TableToolbarAction {...action} key={action.id} rows={props.rows} />
        ))}
      </div>


      <FilterModal
        columns={props.columns}
        filters={props.filters}
        rows={props.rows}
        open={filterModalOpen}
        onClose={() => setFilterModalOpen(false)}
        title={'Filteren'}
        onFiltersChange={props.onFiltersChange}
      />
    </div>
  );
};

export default TableToolbar;

interface TableToolbarProps {
  actions: ToolbarAction<any>[];
  columns: TableColumn[];
  rows: any[];
  title: string;
  filters: ResultFilter<any>[];
  onFiltersChange?: (filters: ResultFilter<any>[]) => void;
}

import React, {useContext} from 'react';
import {makeStyles} from '@material-ui/core';
import {AppContext} from '../../contexts/AppContext';
import {Route, useHistory} from 'react-router-dom';
import Loader from '../panels/Loader';

const useStyles = makeStyles(theme => ({
  root: {}
}));

export const PrivateRoute = (props: any) => {
  const classes = useStyles(props);
  const {user, isAuthorized, logout} = useContext(AppContext);

  const history = useHistory();

  if (!user) {
    return <Loader loading/>
  }

  if (props.module && !isAuthorized(props.module)) {
    console.log('props.module exists and is not authorized for', props.module)
    history.push('/');
  }

  return <Route {...props} />;
};

export default PrivateRoute;

import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({}));

function TableToolbarAction<M>(props: TableToolbarActionProps<M[]>) {
  const classes = useStyles(props);

  return (
    <Tooltip title={props.label}>
      <span>
        <IconButton
          disabled={props.disabled ? props.disabled(props.rows) : undefined}
          size="small"
          href={props.href ? props.href(props.rows) : undefined}
          onClick={props.click ? () => props.click(props.rows) : undefined}
        >
          {props.icon}
        </IconButton>
      </span>
    </Tooltip>
  );
}

export interface ToolbarAction<M> {
  click?: (rows: M) => void;
  href?: (rows: M) => string;
  disabled?: (rows: M) => boolean;
  icon: any;
  id: string;
  label: string;
}

interface TableToolbarActionProps<M> {
  rows: M;
  click?: (rows: M) => void;
  href?: (rows: M) => string;
  disabled?: (rows: M) => boolean;
  icon: any;
  id: string;
  label: string;
}

export default TableToolbarAction;

import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {theme} from '../../assets/themes/main-theme';
import {Typography} from '@material-ui/core';
import {Dict} from '../../utils';

export type FeedbackEmotionId = 'laugh' | 'smile' | 'frown' | 'angry';

export interface FeedbackEmotion {
  id: FeedbackEmotionId;
  src?: string;
  score: number;
}

export const emotions: FeedbackEmotion[] = [
  {
    id: 'laugh',
    src: '/images/feedback/emotion_laugh.svg',
    score: 100,
  }, {
    id: 'smile',
    src: '/images/feedback/emotion_smile.svg',
    score: 75,
  }, {
    id: 'frown',
    src: '/images/feedback/emotion_frown.svg',
    score: 50,
  }, {
    id: 'angry',
    src: '/images/feedback/emotion_angry.svg',
    score: 25,
  },
]

export const emotionsDict: Dict<string> = emotions.reduce((a, b) => ({...a, [b.id]: b.src}), {});

const useStyles = makeStyles(theme => ({
  root: (props: EmotionPickerProps) => ({
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: props.gutterBottom && theme.spacing(2)
  }),
  img: (props: EmotionPickerProps) => ({
    cursor: props.disabled || !props.onClick ? null : 'pointer',
    transition: '150ms all',
    borderRadius: props.itemSize / 2,
    border: `0px solid ${theme.palette.common.black}`,
    width: props.itemSize,
    margin: props.margin ?? theme.spacing(2)
  }),
  imgWrapper: {
    textAlign: 'center'
  }
}));

export const EmotionPicker = (props: EmotionPickerProps) => {
  const classes = useStyles(props);

  const [selected, setSelected] = useState(props.selected);

  const handleClick = (emotion: FeedbackEmotion, event) => {
    if (props.disabled || !props.onClick) {
      return;
    }

    setSelected(emotion.id);

    props.onClick(emotion, event);
  }

  const getSelectedStyle = (emotion: FeedbackEmotion) => {
    const style: any = {};

    if (selected == emotion.id || props.selected == emotion.id) {
      style.transform = 'scale(1.2)';
    }

    if (props.disabled) {
      style.opacity = 0.7;
    }

    return style;
  }

  return (
    <div className={classes.root}>
      {emotions.map((emotion, index) => (
        <div className={classes.imgWrapper} key={emotion.id}>
          <img
            style={getSelectedStyle(emotion)}
            draggable={false}
            key={emotion.id + '-img'}
            src={emotion.src}
            className={classes.img}
            color={'textSecondary'}
            onClick={(e) => handleClick(emotion, e)}/>
          {props.subtitles && <Typography><strong>{props.subtitles[index]}</strong></Typography>}
        </div>
      ))}
    </div>
  );
}

export default EmotionPicker;

interface EmotionPickerProps {
  subtitles?: string[] | number[];
  onClick?: (emotion: FeedbackEmotion, event?) => void;
  itemSize?: number;
  margin?: number;
  selected?: string;
  gutterBottom?: boolean;
  disabled?: boolean;
}

EmotionPicker.defaultProps = {
  itemSize: 60
}
import React, {useEffect, useRef, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import FormInput from '../forms/FormInput';
import {InputOption} from '../forms/FormInputSelect';
import {User} from '../../models/User';
import {Organisation, OrganisationCollection} from '../../models/Organisation';
import {setObjRecursive} from '../../utils';

const useStyles = makeStyles(theme => ({
  root: {}
}));

export const OrganisationSelector = (props: OrganisationSelectorProps) => {
  const classes = useStyles(props);

  const me = useRef({
    organisations: [],
    organisationsById: {}
  })
  const getMe = () => me.current;

  const [organisation, setOrganisation] = useState<Organisation>()
  const [organisations, setOrganisations] = useState<InputOption[]>([]);

  const handleChange = async (value: string) => {
    const me = getMe();

    const organisation = me.organisationsById[value];

    setOrganisation(organisation);

    if (props.onChange) {
      props.onChange(organisation);
    }

    if (props.user.preferences?.organisations?.preferredOrganisationId !== value) {
      setObjRecursive(props.user, 'preferences.organisations.preferredOrganisationId', value);
      await props.user.savePreference({'preferences.organisations.preferredOrganisationId': value});
    }
  }

  const handleLoad = (org) => {
    if (props.onLoad) {
      props.onLoad(org);
    }
  }

  useEffect(() => {
    const me = getMe();

    OrganisationCollection.findAllWithCount().then(res => {
      const organisations = res.rows;
      const preferredOrganisationId = props.value || props.user.preferences?.organisations?.preferredOrganisationId;

      me.organisations = organisations;
      me.organisationsById = res.rowsById;

      if (!organisations || organisations.length === 0) {
        handleLoad(null);
        return;
      }

      let organisation;

      if (props.value) {
        organisation = organisations.find(org => org.id == props.value) || organisations[0];
      } else if (preferredOrganisationId) {
        organisation = organisations.find(org => org.id == preferredOrganisationId) || organisations[0];
      } else {
        organisation = organisations[0];
      }

      setOrganisation(organisation);
      setOrganisations(organisations.map(org => ({
        value: org.id,
        label: org.name
      })));

      if (props.onLoad) {
        props.onLoad(organisation);
      }

      if (!props.disabled) {
        handleChange(organisation.id);
      }
    });
  }, [props.user]);

  return (
    <FormInput
      id={'organisation'}
      type={'select'}
      label={'Organisatie'}
      disabled={props.disabled}
      defaultValue={props.value || organisation?.id}
      onChange={handleChange}
      options={organisations}
    />
  );
}

export default OrganisationSelector;

interface OrganisationSelectorProps {
  user: User;
  value?: string;
  disabled?: boolean;
  onChange?: (organisation: Organisation) => void;
  onLoad?: (organisation: Organisation) => void;
}

import React from 'react';
import {makeStyles, Typography} from '@material-ui/core';
import {Link as RouterLink, useLocation} from 'react-router-dom';
import {FaUsers} from 'react-icons/fa';

const useStyles = makeStyles(theme => ({
  link: {
    color: theme.palette.common.white,
    textDecoration: 'none',
    '&:hover': {
      opacity: 0.7
    }
  },
  active: {
    fontWeight: 600,
  }
}));

export const Link = (props: LinkProps) => {
  const classes = useStyles(props);
  const location = useLocation();

  const isRouteActive = location.pathname === props.to;

  return (
    <Typography variant={props.variant || 'body2'} className={isRouteActive ? classes.active : null}>
      <RouterLink to={props.to} className={`${classes.link} ${props.className}`}>
        {props.icon && (<span style={{marginRight: 16}}>{<props.icon />}</span>)}

        {props.children}
      </RouterLink>
    </Typography>
  );
};

export default Link;

interface LinkProps {
  to: string;
  children: any;
  icon?: any;
  variant?: 'inherit' | 'button' | 'overline' | 'caption' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'subtitle1' | 'subtitle2' | 'body1' | 'body2' | 'srOnly' | undefined;
  className?: string;
}

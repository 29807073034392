import React from 'react';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: (props: BackgroundPanelProps) => ({
    position: 'fixed',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.common.black,
    display: 'flex',
    justifyContent: 'space-around',
    paddingTop: props.paddingTop || 0
  }),
}));

export const BackgroundPanel = (props: BackgroundPanelProps) => {
  const classes = useStyles(props);

  return <div className={classes.root}>{props.children}</div>
}

export default BackgroundPanel;

interface BackgroundPanelProps {
  children: any;
  paddingTop?: number;
}

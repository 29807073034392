import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core';
import {TableColumn} from '../table/TableHeader';
import {ResultFilter, RowAction} from '../table/BaseTable';
import DynamicTable from '../table/DynamicTable';
import Loader from '../panels/Loader';
import {ToolbarAction} from '../table/TableToolbarAction';
import {Reservation, ReservationCollection} from '../../models/Reservation';
import {FaBan, FaPen} from 'react-icons/all';
import {FaTrash} from 'react-icons/fa';

const useStyles = makeStyles(theme => ({
  root: {}
}));

export const ReservationTable = (props: ReservationTableProps) => {
  const classes = useStyles(props);

  const [filters, setFilters] = useState<ResultFilter<Reservation>[]>([])

  const columns: TableColumn[] = [
    {id: 'createdByUser', label: 'Gebruiker'},
    {id: 'workplace', label: 'Werkplek', filterable: true},
    {id: 'workspace', label: 'Werkruimte', filterable: true},
    {id: 'organisation', label: 'Organisatie', filterable: true},
    // {id: 'notes', label: 'Notities'},
    {id: 'canceledString', label: 'Geannuleerd', filterable: true},
    {id: 'dateString', label: 'Datum', filterable: true},
    {id: 'fromTimeString', label: 'Vanaf'},
    {id: 'tillTimeString', label: 'Tot'},
  ];
  const rowActions: RowAction<Reservation>[] = [
    {
      label: 'Aanpassen',
      icon: <FaPen/>,
      onClick: (row) => props.onEdit(row),
      disabled: (row) => row.canceled || row.till < new Date(),
    }, {
      label: 'Annuleren',
      icon: <FaTrash/>,
      disabled: (row) => row.canceled || row.till < new Date(),
      onClick: (row) => handleCancelReservation(row),
    }];
  const toolbarActions: ToolbarAction<Reservation[]>[] = []

  const [loading, setLoading] = useState(false);

  const handleFiltersChange = (filters: ResultFilter<Reservation>[]) => {
    setFilters(filters);
  }

  const handleCancelReservation = (reservation: Reservation) => {
    setLoading(true);

    reservation.cancel().then(res => {
      reservation.canceled = true;
      setLoading(false);
    }).catch(err => {
      setLoading(false);
    })
  }

  return (
    <div className={classes.root}>

      <DynamicTable
        onAddBtnClick={props.onAddBtnClick}
        addBtnLabel={'Reservering aanmaken'}
        columns={columns}
        collection={ReservationCollection}
        title={'Reserveringen'}
        ref={props.tableRef}
        rowActions={rowActions}
        onLoad={props.onLoad}
        disablePadding
        toolbarActions={toolbarActions}
        filters={filters}
        onFiltersChange={handleFiltersChange}
      />
      <Loader loading={loading}/>
    </div>
  );
};

export default ReservationTable;

interface ReservationTableProps {
  onAddBtnClick?: () => void;
  onLoad?: () => void;
  onRefresh?: () => void;
  onEdit?: (row: Reservation) => void;
  onDelete?: (row: Reservation) => void;
  tableRef?: any;
}

import React, {useRef} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Grid} from '@material-ui/core';
import {useHistory} from 'react-router-dom';
import DubwebMenu, {DubwebMenuItem} from './DubwebMenu';
import {FaPlus} from 'react-icons/fa';
import {theme} from '../../assets/themes/main-theme';

const useStyles = makeStyles(theme => ({
  root: {},
  menu: {
    maxWidth: 400,
    padding: theme.spacing(2, 4),
  }
}));

export const ActivityMenu = (props: ActivityMenuProps ) => {
  const classes = useStyles(props);
  const history = useHistory();

  const plusRef = useRef(null);

  const handleReserveClick = () => {
    if (plusRef.current) {
      plusRef.current.close();
    }

    props.onReserveWorkplaceClick();
  }

  const activities: DubwebMenuItem[] = [
    {
      text: 'Ik wil een werkplek reserveren',
      onClick: handleReserveClick
    },
    // {
    //   text: 'Ik wil een artikel bestellen',
    //   onClick: () => history.push('/reserve'),
    //   disabled: true
    // },
    // {
    //   text: 'Ik wil eten bestellen',
    //   onClick: () => history.push('/reserve'),
    //   disabled: true
    // },
  ]

  return (
    <Grid container justify={'space-around'}>
      <DubwebMenu tooltip={'Activiteit toevoegen'} ref={plusRef} icon={FaPlus} iconSize={'2.5rem'} iconColor={theme.palette.common.white}  items={activities} />
    </Grid>
  );
}

export default ActivityMenu;

interface ActivityMenuProps {
  onReserveWorkplaceClick?: () => void;
  onRefresh?: () => void;
}

import React, {useEffect, useRef, useState} from 'react';
import {makeStyles} from '@material-ui/core';
import Modal, {ModalButton} from './Modal';
import FormInput from '../forms/FormInput';
import {InputOption} from '../forms/FormInputSelect';
import Loader from '../panels/Loader';
import Model from '../../models/core/Model';
import {TableColumn} from '../table/TableHeader';
import {ResultFilter} from '../table/BaseTable';

const useStyles = makeStyles(theme => ({
  root: {
    height: 250,
    '& > *': {
      paddingBottom: theme.spacing(2)
    }
  }
}));

export const FilterModal = (props: FilterModalProps) => {
  const classes = useStyles(props);

  const [loading, setLoading] = useState(true);

  const me = useRef({
    filterableCols: [] as TableColumn[],
    filterables: {},
    filters: {}
  });

  const getMe = () => {
    return me.current;
  };

  const buttons: ModalButton[] = [
    {
      title: 'Opslaan',
      onClick: () => handleFiltersSave(),
      disabled: false
    }
  ];

  const handleFiltersSave = () => {
    const me = getMe();

    const filters: ResultFilter<any>[] = [];


    for (const key of Object.keys(me.filters))  filters.push(me.filters[key]);

    props.onFiltersChange(filters);
    props.onClose();
  }

  const handleChange = (col: TableColumn, value: any) => {
    const me = getMe();

    if (value === '*') {
      delete me.filters[col.id];
    } else {
      me.filters[col.id] = {
        filter: (row) => row[col.id] === value,
        value: value,
      }
    }
  }

  const getInputs = () => {
    const me = getMe();

    return me.filterableCols.map((col) => {
      const options: InputOption[] = [{
        label: 'Alles',
        value: '*'
      }];
      if (me.filters && me.filterables[col.id]) {
        options.push(...me.filterables[col.id].map(f => ({value: f, label: f})))
      }
      return <FormInput
        key={col.id}
        id={col.id}
        label={col.label}
        type={'select'}
        options={options}
        defaultValue={me.filters[col.id]?.value || null}
        onChange={(value) => handleChange(col, value)}
      />
    })
  }

  const resetFilters = () => {
    const me = getMe();

    me.filterableCols = props.columns.filter(col => col.filterable);
    me.filterables = {};
    me.filters = {}
  }

  useEffect(() => {
    const me = getMe();

    resetFilters();

    if (props.rows.length > 0 && me.filterableCols.length > 0) {

      for (const col of me.filterableCols) {
        me.filterables[col.id] = [];

        for (const row of props.rows) {
          if (!me.filterables[col.id].includes(row[col.id])) {
            me.filterables[col.id].push(row[col.id]);
          }
        }
      }
    }

    setLoading(false);
  }, [props.rows, props.filters]);


  return (
    <Modal open={props.open} onClose={props.onClose} title={props.title} buttons={buttons} loading={loading}>
      <div className={classes.root}>
        {getInputs()}

      </div>
      <Loader loading={loading} />
    </Modal>
  );
};

export default FilterModal;

interface FilterModalProps {
  rows: Model<any, any>[];
  columns: TableColumn[];
  open: boolean;
  onClose: () => void;
  title: string;
  filters: ResultFilter<any>[];
  onFiltersChange?: (filters: ResultFilter<any>[]) => void;
}

import React from 'react';
import {Checkbox, makeStyles, Typography} from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableHead from '@material-ui/core/TableHead';
import {RowAction} from './BaseTable';

const useStyles = makeStyles(theme => ({
  root: {},
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  checkbox: {
    padding: '0 !important',
    color: theme.palette.common.black
  }
}));


export const TableHeader = (props: TableHeaderProps) => {
  const classes = useStyles(props);

  const {onSelectAllClick, order, orderBy, numSelected, rowCount} = props;


  const createSortHandler = (property) => (event) => {
    props.onRequestSort(event, property);
  };

  return (
    <TableHead className={`${classes.root} ${props.className}`}>
      <TableRow>
        {props.multiSelect && (
          <TableCell padding="default" style={{backgroundColor: '#fff'}}>
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{'aria-label': 'Selecteer alles'}}
              size={'small'}
              className={classes.checkbox}
              color={'primary'}
            />
          </TableCell>
        )}
        {props.columns.map((headCell) => (
          <TableCell
            key={headCell.id}
            variant={'head'}
            // align={headCell.numeric ? 'right' : 'left'}
            align={headCell.align}
            padding={props.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              <Typography><strong>{headCell.label}</strong></Typography>
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        {props.rowActions?.length > 0 && <TableCell/>}
      </TableRow>
    </TableHead>
  );

};

export default TableHeader;

interface TableHeaderProps {
  className?: string;
  columns: TableColumn[];
  disablePadding?: boolean;
  rowActions?: RowAction<any>[];
  multiSelect?: boolean;
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  rowCount: number;
  order?: 'asc' | 'desc';
  orderBy?: string;
}

export interface TableColumn {
  id: string;
  label: string;
  onClick?: (value?: any) => void;
  disabled?: (row: any) => boolean;
  type?: 'number' | 'dateTime' | 'boolean' | 'date';
  format?: (row: any, col: TableColumn) => any;
  component?: 'checkbox' | 'code';
  align?: 'right' | 'left' | 'inherit' | 'center' | 'justify';
  filterable?: boolean;
}

import React, {useReducer, useRef, useState} from 'react';
import {Box, Button, Input, makeStyles} from '@material-ui/core';
import UserTable from '../components/tables/UserTable';
import Modal from '../components/modals/Modal';
import AddUserModal from '../components/modals/AddUserModal';
import WorkspaceTable from '../components/tables/WorkspaceTable';
import UploadFileModal from '../components/modals/UploadFileModal';
import CanvasPanel from '../components/panels/CanvasPanel';
import env from '../env/environment';
import FileTable from '../components/tables/FileTable';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.background.default
  },
}));

export const FileManager = (props: FileManagerProps) => {
  const classes = useStyles(props);

  const rootRef = useRef(null);

  const [addWorkspaceOpen, setAddWorkspaceOpen] = useState(false);
  const [uploadFileOpen, setUploadFileOpen] = useState(false);

  const handleAddButtonClick = () => {
    setUploadFileOpen(true);
  }

  const handleAddButtonClose = () => {
    setAddWorkspaceOpen(false);
  }

  const handleRefresh = (refreshCache = false) => {
    getTable().refresh(refreshCache);
  }

  const table = useRef(null);
  const getTable = function () {
    return table.current;
  };

  const handleLoad = () => {}

  const handleUploadFileModalClose = () => {
    setUploadFileOpen(false);
  }

  return (
    <div className={classes.root} ref={rootRef}>
      <FileTable
        onAddBtnClick={handleAddButtonClick}
        tableRef={table}
        onRefresh={handleRefresh}
        onLoad={handleLoad}
      />
      {/*<CanvasPanel rootRef={rootRef}/>*/}

      <UploadFileModal
        open={uploadFileOpen}
        onClose={handleUploadFileModalClose}
        onRefresh={handleRefresh}
        title={'Bestand uploaden'}
      />

    </div>
  );
};

export default FileManager;

interface FileManagerProps {

}

import React from 'react';
import {IconButton, makeStyles, Tooltip} from '@material-ui/core';
import {isFunction} from '../../utils';
import Model from '../../models/core/Model';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  rowAction: (props: TableRowActionProps<any>) => ({
    fontSize: 18,
    padding: 0,
    transition: '100ms all',
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.primary.main
    },
    color: props.inverted ? theme.palette.common.white : theme.palette.common.black,
    opacity: 0.9
  })
}));

export function TableRowAction<M>(props: TableRowActionProps<M>) {
  const classes = useStyles(props);

  if (props.hidden && props.hidden(props.row)) {
    return null;
  }

  const getTitle = () => {
    return isFunction(props.label) ? props.label(props.row) : props.label
  }

  const getTitleSuffix = () => {
    return props.labelSuffix ? props.labelSuffix : '';
  }

  return (
    <Tooltip
      title={<div className={classes.root}>{getTitle()}{` `}{getTitleSuffix()}</div>}
      enterDelay={400}
      placement={'top'}
    >
        <span>
            <IconButton
              className={classes.rowAction}
              disableFocusRipple
              disableRipple
              disableTouchRipple
              disabled={props.disabled && props.disabled(props.row)}
              onClick={() => props.onClick(props.row)}
            >
              {isFunction(props.icon) ? props.icon(props.row) : props.icon}
          </IconButton>
        </span>
    </Tooltip>
  );
}

export default TableRowAction;

interface TableRowActionProps<M> {
  row: M;
  label: any;
  labelSuffix?: string;
  onClick: (row: M) => void;
  icon: any;
  hidden?: (row: M) => boolean;
  disabled?: (row: M) => boolean;
  inverted?: boolean;
}

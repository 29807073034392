import React, {useContext} from 'react';
import {makeStyles} from '@material-ui/core';
import ContactInfo from '../../routes/ContactInfo';
import {AppContext} from '../../contexts/AppContext';
import Profile from '../../routes/Profile';
import CovidContact from '../../routes/CovidContact';
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import PrivateRoute from './PrivateRoute';
import UserManager from '../../routes/UserManager';
import Loader from '../panels/Loader';
import {getModuleByName, modules} from '../../constants/Modules';
import OrganisationManager from '../../routes/OrganisationManager';
import WorkspaceManager from '../../routes/WorkspaceManager';
import FileManager from '../../routes/FileManager';
import ReservationManager from '../../routes/ReservationManager';
import QrManager from '../../routes/QrManager';
import FoodPlanner from '../../routes/FoodPlanner';
import OrganisationReserve from '../../routes/OrganisationReserve';
import PageNotFound from '../../routes/PageNotFound';
import {Switch} from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'fixed',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
  },
  content: {
    position: 'absolute',
    top: 60,
    left: 200,
    width: 'calc(100% - 200px)',
    height: 'calc(100% - 60px)',
  }
}));

export const Content = (props: ContentProps) => {
  const classes = useStyles(props);

  const {user} = useContext(AppContext);

  return (
    <div className={classes.root}>
      <Navbar/>
      <Sidebar/>

      <div className={classes.content}>
        {modules.map(module => (
          <PrivateRoute
            path={module.route}
            module={module}
            key={module.name}
            exact={module.exact}
            children={<module.component/>}/>
        ))}

        <Loader loading={!user}/>
      </div>

    </div>
  );
};


export default Content;

interface ContentProps {

}

import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Box} from '@material-ui/core';
import {FaMinus, FaPlus} from 'react-icons/fa';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '& > div': {
      padding: theme.spacing(2, 2, 1, 2),
      backgroundColor: theme.palette.common.white,
      transition: '100ms all',
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        cursor: 'pointer',
      }
    }
  },
  plus: {
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottom: '1px solid #eee'
  },
  minus: {
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
  }
}));

export const Zoom = (props: ZoomProps) => {
  const classes = useStyles(props);

  return (
    <div className={classes.root}>
      <div className={classes.plus} onClick={props.onZoomIn}>
        <FaPlus/>
      </div>
      <div className={classes.minus} onClick={props.onZoomOut}>
        <FaMinus/>
      </div>
    </div>
  );
}

export default Zoom;

interface ZoomProps {
  onZoomIn?: () => void;
  onZoomOut?: () => void;
}
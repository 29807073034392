import React, {createRef, useRef, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import WorkplaceTable from '../components/tables/WorkplaceTable';
import {ToolbarAction} from '../components/table/TableToolbarAction';
import {Workplace, WorkplaceCollection} from '../models/Workplace';
import {FaFileCsv, FaQrcode} from 'react-icons/all';
import QRCode from 'qrcode';
import QRCodeJS from 'easyqrcodejs';
import JSZip from 'jszip';
import FileSaver from 'file-saver';
import moment from 'moment';

const logo = require('../assets/images/rand_square.jpg');


const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.background.default
  },
}));

export const QrManager = (props: QrManagerProps) => {
  const classes = useStyles(props);

  const qrRef = useRef(null);

  const [selected, setSelected] = useState<Workplace[]>([]);

  const toolbarActions: ToolbarAction<Workplace[]>[] = [{
    icon: <FaFileCsv/>,
    id: 'csv',
    label: 'CSV downloaden',
    disabled: (rows) => selected.length === 0,
    click: (rows) => handleDownloadCsvClick()
  }, {
    icon: <FaQrcode/>,
    id: 'export-qr',
    label: 'QR-codes downloaden',
    disabled: (rows) => selected.length === 0,
    click: (rows) => handleDownloadQrClick()
  }]

  const handleDownloadCsvClick = async () => {
    const workplaces = await enrichWorkplacesWithQr(selected);

    const columns = ['Organisatie', 'Werkruimte', 'Werkplek', 'URL'];
    let csvContent = "data:text/csv;charset=utf-8,";
    let row;

    csvContent += columns.join(',') + '\r\n';

    for (const workplace of workplaces) {
      row = [workplace.organisation, workplace.workspace, workplace.fullName, encodeURI(workplace.reserveUrl)].join(",");
      csvContent += row + "\r\n";
    }

    const encodedUri = encodeURI(csvContent);
    window.open(encodedUri);
  }


  const enrichWorkplacesWithQr = async (workplaces: Workplace[]) => {
    const promises: Promise<string>[] = [];
    for (const workplace of workplaces) {
      promises.push(new Promise((resolve, reject) => {
        new QRCodeJS(qrRef.current, {
          text: workplace.reserveUrl,
          logo: logo,
          logoWidth: 60,
          logoHeight: 60,
          onRenderingEnd: (options, data) => {
            workplace['qr'] = data;
            resolve(data);
          }
        });
      }))
    }

    await Promise.all(promises);
    return workplaces;
  }

  const handleDownloadQrClick = async () => {
    const workplaces = await enrichWorkplacesWithQr(selected);

    const zip = new JSZip();
    for (const workplace of workplaces) {
      const fileName = `${workplace.organisation}_${workplace.workspace}_${workplace.urlName}.png`;
      zip.file(fileName, workplace['qr'].replace('data:image/png;base64,', ''), {base64: true});
    }

    zip.generateAsync({type: 'blob'}).then((content) => {
      const today = moment().format('YYYY-MM-DD_HH.mm.ss')
      FileSaver.saveAs(content, `qr_${today}.zip`);
    })

  }

  const handleSelectedChange = (rows) => {
    setSelected(rows);
  }

  return (
    <div className={classes.root}>
      <WorkplaceTable toolbarActions={toolbarActions} onSelectedChange={handleSelectedChange}/>
      <div ref={qrRef} style={{display: 'none'}}/>
    </div>
  );
}

export default QrManager;

interface QrManagerProps {

}

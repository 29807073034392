import React, {useContext, useReducer, useRef, useState} from 'react';
import {Box, Button, makeStyles} from '@material-ui/core';
import UserTable from '../components/tables/UserTable';
import Modal from '../components/modals/Modal';
import AddUserModal from '../components/modals/AddUserModal';
import {AppContext} from '../contexts/AppContext';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.background.default

  },
}));

export const UserManager = (props: UserManagerProps) => {
  const classes = useStyles(props);

  const {showNotification} = useContext(AppContext);

  const [addUserOpen, setAddUserOpen] = useState(false);

  const handleAddButtonClick = () => {
    setAddUserOpen(true);
  }

  const handleAddButtonClose = () => {
    setAddUserOpen(false);
  }

  const handleRefresh = () => {
    getTable().refresh();
  }

  const handleInvitationSend = (emails: string[]) => {
    handleRefresh();
    showNotification('Uitnodigingen zijn verstuurd naar '+ emails.join(', '), 'success')
  }

  const table = useRef(null);
  const getTable = function () {
    return table.current;
  };

  const handleLoad = () => {}

  return (
    <div className={classes.root}>
      <UserTable
        onAddBtnClick={handleAddButtonClick}
        tableRef={table}
        onRefresh={handleRefresh}
        onLoad={handleLoad}
      />

      <AddUserModal
        open={addUserOpen}
        onClose={handleAddButtonClose}
        onInvitationSend={handleInvitationSend}
        title={'Gebruiker toevoegen'}
      />

    </div>
  );
};

export default UserManager;

interface UserManagerProps {

}

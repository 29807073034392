import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import TableRowAction from '../table/TableRowAction';
import {RowAction} from '../table/BaseTable';

const useStyles = makeStyles(theme => ({
  root: (props: ActionsOverlayProps<any>) => ({
    position: 'absolute',
    display: 'flex',
    top: props.padding || theme.spacing(3),
    right: props.padding || theme.spacing(3)
  })
}));

export function ActionsOverlay<M>(props: ActionsOverlayProps<M>) {
  const classes = useStyles(props);

  if (!props.rowActions || props.rowActions.length === 0) {
    return null;
  }

  return (
    <div className={classes.root}>
      {props.rowActions?.map((rowAction, index) => (
        <TableRowAction inverted={props.inverted} key={index} row={props.row} {...rowAction} />))}
    </div>
  );
}

export default ActionsOverlay;

interface ActionsOverlayProps<M> {
  rowActions: RowAction<M>[];
  row?: any;
  padding?: number;
  inverted?: boolean;
}

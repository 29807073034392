import React, {forwardRef, useImperativeHandle, useLayoutEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {BaseTable, ResultFilter, RowAction} from './BaseTable';
import {TableColumn} from './TableHeader';
import Collection from '../../models/core/Collection';
import {ToolbarAction} from './TableToolbarAction';
import Model from '../../models/core/Model';

const useStyles = makeStyles(theme => ({
  root: {}
}));

export const DynamicTable = forwardRef((props: DynamicTableProps, ref) => {
  const classes = useStyles(props);

  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);

  useImperativeHandle(ref, () => ({
    clear() {
      setRows([]);
    },
    refresh(refreshCache?: boolean) {
      loadData(refreshCache);
    }
  }));

  const loadData = (refreshCache?: boolean) => {
    setLoading(true);
    props.collection.findAllWithCount({}, refreshCache).then(res => {
      setRows(res.rows);
    }).catch(error => {}).finally(() => {
      setLoading(false);
      if (props.onLoad) {
        props.onLoad();
      }
    })
  }

  useLayoutEffect(() => {
    loadData();
  }, []);

  return (
    <BaseTable
      addBtnLabel={props.addBtnLabel}
      addBtnClicked={props.onAddBtnClick}
      columns={props.columns}
      data={rows}
      title={props.title}
      multiSelect={props.multiSelect}
      onSelectedChange={props.onSelectedChange}
      orderBy={props.orderBy}
      order={props.order}
      toolbarActions={props.toolbarActions}
      loading={loading}
      rowActions={props.rowActions}
      disablePadding
      filters={props.filters || []}
      onFiltersChange={props.onFiltersChange}

    />

  );
});
DynamicTable.displayName = 'DynamicTable';
export default DynamicTable;

interface DynamicTableProps {
  onAddBtnClick?: () => void;
  addBtnLabel?: string;
  columns: TableColumn[];
  collection: Collection<any, any>;
  rowActions?: RowAction<any>[];
  disablePadding?: boolean;
  title: string;
  toolbarActions?: ToolbarAction<any>[];
  multiSelect?: boolean;
  onLoad?: () => void;
  order?: 'asc' | 'desc';
  orderBy?: string;
  filters?: ResultFilter<any>[];
  onFiltersChange?: (filters: ResultFilter<any>[]) => void;
  onSelectedChange?: (selected: Model<any, any>[]) => void;
}

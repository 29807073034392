import React from 'react';
import {makeStyles, Typography} from '@material-ui/core';
import Img from '../components/snippets/Img';

const useStyles = makeStyles(theme => ({
  root: {}
}));

export const Profile = (props: ProfileProps) => {
  const classes = useStyles(props);

  return (
    <div className={classes.root}>
      <Typography variant={'h6'}>Profile</Typography>
    </div>
  );
};

export default Profile;

interface ProfileProps {

}

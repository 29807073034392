import React from 'react';
import {makeStyles, Typography} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    '&:hover': {
      cursor: 'pointer',
      opacity: 0.7
    }
  }
}));

export const NavLink = (props) => {
  const classes = useStyles(props);
  return <Typography {...props} className={classes.root}/>;
};

export default NavLink;

interface NavLinkProps {

}